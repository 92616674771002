import React, { useEffect, useRef, useState } from 'react'
// import AllReportsComponent from './Reports'
import MainHeading from '../Settings/Component/MainHeading'
import getListUserStreak from '../../api/Streak/getListUserStreak'
import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'
import { useMyContext } from '../../context/context'
import { useQueryState } from 'react-router-use-location-state'
import qs from 'query-string'
import Heading from '../Settings/Component/Heading'
import UserStreakList from './UserStreakList'

function AllUserStreaks (props) {
  const content = useRef()
  const adminPermission = useSelector(state => state?.auth?.adminPermission)
  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)

  const location = useLocation()
  const { state: { isFullList = false } } = useMyContext()
  // const isFullList = useRef(false)
  const [searchText, setSearchText] = useState('')
  const [initialFlag] = useState(false)
  // const [search, setSearch] = useQueryState('search', '')
  const [sort] = useQueryState('sortBy', 'sTitle')
  const [start, setStart] = useState(0)
  const [offset, setOffset] = useQueryState('pageSize', 10)
  // const page = JSON?.parse(localStorage?.getItem('queryParams'))

  const { data: userStreaks ,isLoading ,refetch, isSuccess } = useQuery({
    queryKey: ['getListUserStreak',start,offset],
    queryFn: () => getListUserStreak(start,offset,'','desc',false),
    select: (response) => response?.data?.data,
    enabled: !!(adminPermission?.STREAK !== 'N' || (Auth && Auth === 'SUPER'))
  })

  useEffect(() => {
    const obj = qs?.parse(location?.search)
    if (obj?.search) {
      setSearchText(obj?.search)
    }
  }, [])

  // function onHandleSearch (e) {
  //   setSearchText(e?.target?.value)
  //   setInitialFlag(true)
  // }

  function onExport () {
    content?.current?.onExport()
  }
  return (
    <main className="main-content">
      <section className="management-section">
        <MainHeading
          UserStreaks
          export='Export'
          heading="Streaks"
          onExport={onExport}
        />
        <div className={userStreaks?.total === 0 ? 'without-pagination' : 'setting-component'}>
          <Heading
            // SearchPlaceholder="Search Setting"
            // handleSearch={onHandleSearch}
            permission={(Auth && Auth === 'SUPER') || (adminPermission?.STREAK !== 'R')}
            // search={searchText}
            setUrl="/user-streak"

          /> 
          <UserStreakList
            {...props}
            ref={content}
            flag={initialFlag}
            search={searchText}
            userStreaks={userStreaks}
            // setSearch={setSearch}
            setStart={setStart}
            start={start}
            offset={offset}
            setOffset={setOffset}
            isLoading={isLoading}
            sort={sort}
            refetch={refetch}
            isFullList={isFullList}
            isSuccess={isSuccess}
          />
        </div>
      </section>
    </main>
  )
}

export default AllUserStreaks
