import React, { useEffect,  useState } from 'react';
import { FormGroup, Input } from 'reactstrap';
import { changeStatus } from '../../actions/chat';
import { useDispatch, useSelector } from 'react-redux';
import AlertMessage from '../../components/AlertMessage';
import { modalMessageFunc } from '../../helpers/helper';

const ChatHeader = ({ selectedThread,thread }) => {
  const [status,setStatus] = useState(false)
  const [chatStatus,setChatStatus] = useState('A')
  const [close, setClose] = useState(false)
  const [modalMessage, setModalMessage] = useState(false)
  const [message, setMessage] = useState('')
  // const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const resStatus = useSelector(state => state.chat.resStatus)
  const resMessage = useSelector(state => state.chat.resMessage)
  const data = useSelector(state => state.chat.data)
  // const previousProps = useRef({resStatus, resMessage})?.current

  useEffect(()=>{
    if(thread?.eThreadStatus){
      setChatStatus(thread?.eThreadStatus)
    }
  },[thread])
  useEffect(() => {
    modalMessageFunc(modalMessage, setModalMessage, setClose)
}, [modalMessage])
useEffect(() => {
      if (resMessage) {
          if (resStatus) {
            setMessage(resMessage)
            setStatus(resStatus)
            setModalMessage(true)
            setChatStatus(data?.eThreadStatus)
          } else {
              setMessage(resMessage)
              setStatus(resStatus)
              setModalMessage(true)
              // setLoading(false)
          }
      }
  // return () => {
  //     previousProps.resMessage = resMessage
  //     previousProps.resStatus = resStatus
  // }
}, [resStatus, resMessage])
  const handleChangeStatus = (e) =>{
    setStatus(e?.target?.value)
    dispatch(changeStatus(e?.target?.value,thread?._id))
  }
  return (
    <div className="chat-header">
      <AlertMessage
        close={close}
        message={message}
        modalMessage={modalMessage}
        status={status}
      />
      <h3>{selectedThread}</h3>
      <div className="chat-header-controls">
      <FormGroup className='px-6 mt-6 form-group  '>
                  {/* <Label className='edit-label-setting' for='typeSelect'>
                    Status
                    {' '}
                  </Label> */}
                  <Input
                    className={'form-control ps-5 pe-5'}
                    // disabled={adminPermission?.CHAT === 'R'}
                    id='typeSelect'
                    name='typeSelect'
                    onChange={event => handleChangeStatus(event)}
                    type='select'
                    value={chatStatus}
                  >
                    
                      <option key='C' value='C'>Close</option>
                      <option key='A' value='A'>Active</option>
                    
                  </Input>
                </FormGroup>
      </div>
    </div>
  );
};

export default ChatHeader;
