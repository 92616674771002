import React, { useEffect, useRef, useState, Fragment, forwardRef, useImperativeHandle } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Input, Row } from 'reactstrap'
import { ExcelExport, ExcelExportColumn, ExcelExportColumnGroup } from '@progress/kendo-react-excel-export'
import { useParams } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import PropTypes from 'prop-types'
import calendarIcon from '../../../assets/images/calendar.svg'
import AlertMessage from '../../../components/AlertMessage'
import { modalMessageFunc } from '../../../helpers/helper'
import EventReportDetails from '../../Reports/AllReports/EventReportDetails'
import { getEventDetails } from '../../../actions/events'
import MainHeading from '../../Settings/Component/MainHeading'

const EventReport = forwardRef((props, ref) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const exporter = useRef(null)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState(false)
  const [close, setClose] = useState(false)
  const [modalMessage, setModalMessage] = useState(false)
  const eventDetails = useSelector((state) => state?.events?.eventDetails)
  const resMessage = useSelector((state) => state?.events?.resMessage)
  const resStatus = useSelector((state) => state?.events?.resStatus)
  const previousProps = useRef({resStatus, resMessage })?.current
  useEffect(() => {
    if (id) {
      dispatch(getEventDetails({ Id: id }))
    }
  }, [])

  useEffect(() => {
    modalMessageFunc(modalMessage, setModalMessage, setClose)
  }, [modalMessage])

  useEffect(() => {
    if (previousProps?.resMessage !== resMessage) {
      if (resMessage) {
        setMessage(resMessage)
        setStatus(resStatus)
        if (resStatus) {
          setModalMessage(true)
        }
      }
    }
    return () => {
      previousProps.resMessage = resMessage
    }
  }, [resStatus, resMessage])
  useImperativeHandle(ref, () => ({
    onExport
  }))

  // for
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className='form-control date-range' onClick={onClick}>
      <img alt='calendar' src={calendarIcon} />
      <Input ref={ref} className='range' placeholder='Select Date Range' readOnly value={value} />
    </div>
  ))
  ExampleCustomInput.displayName = ExampleCustomInput
  const processExcelExportData = (data, index) => {
    const pendingOrder = eventDetails?.aAdminOrders?.find(order => order.eOrderStatus === 'PENDING');
    const matchedOrder = eventDetails?.aAdminOrders?.find(order => order.eOrderStatus === 'MATCHED');
    const adminWin = eventDetails?.aAdminTransaction?.find(order => order.eTransactionType === 'Win-OT');
    const adminPlay = eventDetails?.aAdminTransaction?.find(order => order.eTransactionType === 'Play-OT');
    const userPendingOrders = eventDetails?.aUserOrders?.find(order => order.eOrderStatus === 'PENDING');
    const userMatchedOrders = eventDetails?.aUserOrders?.find(order => order.eOrderStatus === 'MATCHED');
    const userPartiallyMatchedOrders = eventDetails?.aUserOrders?.find(order => order.eOrderStatus === 'PARTIALLY_FILLED');
    const userWinTransactions = eventDetails?.aUserTransaction?.find(order => order.eTransactionType === 'Win-OT');
    const userPlayTransactions = eventDetails?.aUserTransaction?.find(order => order.eTransactionType === 'Play-OT');

    const eventData = {
      //pending
      nAdminPendingOrders: pendingOrder?.nTotalOrder,
      nAdminPendingQty: pendingOrder?.nTotalNoQty,
      nAdminPendingLimit: pendingOrder?.nTotalLimitOrder,
      nAdminPendingMarket: pendingOrder?.nTotalMarketOrder,
      // nAdminPendingMarket: pendingOrder?.nTotalMarketOrder,
      //matched
      nAdminMatchedOrders: matchedOrder?.nTotalOrder,
      nAdminMatchedQty: matchedOrder?.nTotalNoQty,
      nAdminMatchedLimit: matchedOrder?.nTotalLimitOrder,
      nAdminMatchedMarket: matchedOrder?.nTotalMarketOrder,
      // nAdminMatchedMarket: matchedOrder?.nTotalMarketOrder,
      //win
      nAdminWinAmount: adminWin?.nTotalAmount,
      nAdminWinBonus: adminWin?.nTotalBonus,
      nAdminWinBuyCommission: adminWin?.nTotalBuyCommission,
      nAdminWinSellCommission: adminWin?.nTotalBuyCommission,
      nAdminWinTotalCash: adminWin?.nTotalCash,
      nAdminWinPlayReturn: adminWin?.nTotalPlayReturn,
      nAdminWinWinning: adminWin?.nTotalWinning,
      //Play
      nAdminPlayAmount: adminPlay?.nTotalAmount,
      nAdminPlayBonus: adminPlay?.nTotalBonus,
      nAdminPlayBuyCommission: adminPlay?.nTotalBuyCommission,
      nAdminPlaySellCommission: adminPlay?.nTotalBuyCommission,
      nAdminPlayTotalCash: adminPlay?.nTotalCash,
      nAdminPlayPlayReturn: adminPlay?.nTotalPlayReturn,
      nAdminPlayWinning: adminPlay?.nTotalWinning,
      //user pending
      nUserPendingTotalOrder: userPendingOrders?.nTotalOrder,
      nUserPendingQty: userPendingOrders?.nTotalQty,
      nUserPendingSell: userPendingOrders?.nTotalSellOrder,
      nUserPendingSellQty: userPendingOrders?.nTotalSellQty,
      nUserPendingBuy: userPendingOrders?.nTotalBuyOrder,
      nUserPendingBuyQty: userPendingOrders?.nTotalBuyQty,
      nUserPendingLimitOrder: userPendingOrders?.nTotalLimitOrder,
      nUserPendingMarketOrder: userPendingOrders?.nTotalMarketOrder,
      //User Matched
      nUserMatchedTotalOrder: userMatchedOrders?.nTotalOrder,
      nUserMatchedQty: userMatchedOrders?.nTotalQty,
      nUserMatchedSell: userMatchedOrders?.nTotalSellOrder,
      nUserMatchedSellQty: userMatchedOrders?.nTotalSellQty,
      nUserMatchedBuy: userMatchedOrders?.nTotalBuyOrder,
      nUserMatchedBuyQty: userMatchedOrders?.nTotalBuyQty,
      nUserMatchedLimitOrder: userMatchedOrders?.nTotalLimitOrder,
      nUserMatchedMarketOrder: userMatchedOrders?.nTotalMarketOrder,
      //User Partial Matched
      nUserPartialTotalOrder: userPartiallyMatchedOrders?.nTotalOrder,
      nUserPartialQty: userPartiallyMatchedOrders?.nTotalQty,
      nUserPartialSell: userPartiallyMatchedOrders?.nTotalSellOrder,
      nUserPartialSellQty: userPartiallyMatchedOrders?.nTotalSellQty,
      nUserPartialBuy: userPartiallyMatchedOrders?.nTotalBuyOrder,
      nUserPartialBuyQty: userPartiallyMatchedOrders?.nTotalBuyQty,
      nUserPartialLimitOrder: userPartiallyMatchedOrders?.nTotalLimitOrder,
      nUserPartialMarketOrder: userPartiallyMatchedOrders?.nTotalMarketOrder,
      //User win transaction
      nUserWinAmount: userWinTransactions?.nTotalAmount,
      nUserWinBonus: userWinTransactions?.nTotalBonus,
      nUserWinBuyCommission: userWinTransactions?.nTotalBuyCommission,
      nUserWinSellCommission: userWinTransactions?.nTotalBuyCommission,
      nUserWinTotalCash: userWinTransactions?.nTotalCash,
      nUserWinPlayReturn: userWinTransactions?.nTotalPlayReturn,
      nUserWinWinning: userWinTransactions?.nTotalWinning,
      //User Play transaction
      nUserPlayAmount: userPlayTransactions?.nTotalAmount,
      nUserPlayBonus: userPlayTransactions?.nTotalBonus,
      nUserPlayBuyCommission: userPlayTransactions?.nTotalBuyCommission,
      nUserPlaySellCommission: userPlayTransactions?.nTotalBuyCommission,
      nUserPlayTotalCash: userPlayTransactions?.nTotalCash,
      nUserPlayPlayReturn: userPlayTransactions?.nTotalPlayReturn,
      nUserPlayWinning: userPlayTransactions?.nTotalWinning,
    }
    return [eventData]

  }

  function onExport() {
    exporter.current.props = { ...exporter?.current?.props, data: processExcelExportData(eventDetails), fileName: `EventReport_${id}.xlsx` }
    exporter.current.save()
  }
  return (
    <main className="main-content">
      <section className="management-section">
        <MainHeading
          AllReports
          export='Export'
          heading="Reports"
          onExport={onExport}
          eventReport
        />
        <div className='without-pagination'>
          <Fragment>
            <AlertMessage
              close={close}
              message={message}
              modalMessage={modalMessage}
              status={status}
            />
            <ExcelExport ref={exporter} fileName={`eventReport_${id}.xlsx`}>
              <ExcelExportColumnGroup headerCellOptions={{ textAlign: 'center' }} title="Admin Pending Orders">
                <ExcelExportColumn field='nAdminPendingOrders' title='Total Orders' />
                <ExcelExportColumn field='nAdminPendingQty' title='Total Quantity' />
                <ExcelExportColumn field='nAdminPendingLimit' title='Limit Orders' />
                <ExcelExportColumn field='nAdminPendingMarket' title='Market Orders' />
              </ExcelExportColumnGroup>
              <ExcelExportColumnGroup headerCellOptions={{ textAlign: 'center' }} title="Admin Matched Orders">
                <ExcelExportColumn field='nAdminMatchedOrders' title='Total Orders' />
                <ExcelExportColumn field='nAdminMatchedQty' title='Total Quantity' />
                <ExcelExportColumn field='nAdminMatchedLimit' title='Limit Orders' />
                <ExcelExportColumn field='nAdminMatchedQty' title='Market Orders' />
              </ExcelExportColumnGroup>
              <ExcelExportColumnGroup headerCellOptions={{ textAlign: 'center' }} title="Admin Play Transactions">
                <ExcelExportColumn field='nAdminPlayAmount' title='Amount' />
                <ExcelExportColumn field='nAdminPlayBonus' title='Bonus' />
                <ExcelExportColumn field='nAdminPlayBuyCommission' title='Buy Commission' />
                <ExcelExportColumn field='nAdminPlaySellCommission' title='Sell Commission' />
                <ExcelExportColumn field='nAdminPlayPlayReturn' title='Play Return' />
                <ExcelExportColumn field='nAdminPlayTotalCash' title='Total Cash' />
                <ExcelExportColumn field='nAdminPlayWinning' title='Play Winning' />
              </ExcelExportColumnGroup>
              <ExcelExportColumnGroup headerCellOptions={{ textAlign: 'center' }} title="Admin Win Transactions">
                <ExcelExportColumn field='nAdminWinAmount' title='Amount' />
                <ExcelExportColumn field='nAdminWinBonus' title='Bonus' />
                <ExcelExportColumn field='nAdminWinBuyCommission' title='Buy Commission' />
                <ExcelExportColumn field='nAdminWinSellCommission' title='Sell Commission' />
                <ExcelExportColumn field='nAdminWinPlayReturn' title='Play Return' />
                <ExcelExportColumn field='nAdminWinTotalCash' title='Total Cash' />
                <ExcelExportColumn field='nAdminWinWinning' title='Play Winning' />
              </ExcelExportColumnGroup>
              <ExcelExportColumnGroup headerCellOptions={{ textAlign: 'center' }} title="User Matched Orders">
                <ExcelExportColumn field='nUserMatchedBuy' title='Buy Orders' />
                <ExcelExportColumn field='nUserMatchedBuyQty' title='Buy QTY' />
                <ExcelExportColumn field='nUserMatchedLimitOrder' title='Limit Orders' />
                <ExcelExportColumn field='nUserMatchedMarketOrder' title='Market Orders' />
                <ExcelExportColumn field='nUserMatchedQty' title='Matched QTY' />
                <ExcelExportColumn field='nUserMatchedSell' title='Sell Orders' />
                <ExcelExportColumn field='nUserMatchedSellQty' title='Sell QTY' />
                <ExcelExportColumn field='nUserMatchedTotalOrder' title='Total Orders' />
              </ExcelExportColumnGroup>
              <ExcelExportColumnGroup headerCellOptions={{ textAlign: 'center' }} title="User Partial Orders">
                <ExcelExportColumn field='nUserPartialBuy' title='Buy Orders' />
                <ExcelExportColumn field='nUserPartialBuyQty' title='Buy QTY' />
                <ExcelExportColumn field='nUserPartialLimitOrder' title='Limit Orders' />
                <ExcelExportColumn field='nUserPartialMarketOrder' title='Market Orders' />
                <ExcelExportColumn field='nUserPartialQty' title='Partial QTY' />
                <ExcelExportColumn field='nUserPartialSell' title='Sell Orders' />
                <ExcelExportColumn field='nUserPartialSellQty' title='Sell QTY' />
                <ExcelExportColumn field='nUserPartialTotalOrder' title='Total Orders' />
              </ExcelExportColumnGroup>
              <ExcelExportColumnGroup headerCellOptions={{ textAlign: 'center' }} title="User Pending Orders">
                <ExcelExportColumn field='nUserPendingBuy' title='Buy Orders' />
                <ExcelExportColumn field='nUserPendingBuyQty' title='Buy QTY' />
                <ExcelExportColumn field='nUserPendingLimitOrder' title='Limit Orders' />
                <ExcelExportColumn field='nUserPendingMarketOrder' title='Market Orders' />
                <ExcelExportColumn field='nUserPendingQty' title='Pending QTY' />
                <ExcelExportColumn field='nUserPendingSell' title='Sell Orders' />
                <ExcelExportColumn field='nUserPendingSellQty' title='Sell QTY' />
                <ExcelExportColumn field='nUserPendingTotalOrder' title='Total Orders' />
              </ExcelExportColumnGroup>
              <ExcelExportColumnGroup headerCellOptions={{ textAlign: 'center' }} title="User Play Transactions">
                <ExcelExportColumn field='nUserPlayAmount' title='Amount' />
                <ExcelExportColumn field='nUserPlayBonus' title='Bonus' />
                <ExcelExportColumn field='nUserPlayBuyCommission' title='Buy Commission' />
                <ExcelExportColumn field='nUserPlaySellCommission' title='Sell Commission' />
                <ExcelExportColumn field='nUserPlayPlayReturn' title='Play Return' />
                <ExcelExportColumn field='nUserPlayTotalCash' title='Total Cash' />
                <ExcelExportColumn field='nUserPlayWinning' title='Winning' />
              </ExcelExportColumnGroup>
              <ExcelExportColumnGroup headerCellOptions={{ textAlign: 'center' }} title="User Play Transactions">
                <ExcelExportColumn field='nUserWinAmount' title='Amount' />
                <ExcelExportColumn field='nUserWinBonus' title='Bonus' />
                <ExcelExportColumn field='nUserWinBuyCommission' title='Buy Commission' />
                <ExcelExportColumn field='nUserWinSellCommission' title='Sell Commission' />
                <ExcelExportColumn field='nUserWinPlayReturn' title='Play Return' />
                <ExcelExportColumn field='nUserWinTotalCash' title='Total Cash' />
                <ExcelExportColumn field='nUserWinWinning' title='Winning' />
              </ExcelExportColumnGroup>
            </ExcelExport>
            <div className='d-flex justify-content-between flex-wrap pe-4 ps-4 mt-4 report-sticky'>
              <Row className='reports-heading fs-4'>
                Event Report
              </Row>
            </div>
            <div className='report-container'>
              <main className='main-content-report d-flex'>
                <EventReportDetails
                  adminOrders={eventDetails?.aAdminOrders}
                  adminTransactions={eventDetails?.aAdminTransaction}
                  userOrders={eventDetails?.aUserOrders}
                  userTransactions={eventDetails?.aUserTransaction}
                />
              </main>
            </div>
          </Fragment>
        </div> 
      </section>
    </main>

  )
})

EventReport.propTypes = {
  location: PropTypes?.object,
  onClick: PropTypes?.func,
  value: PropTypes?.string
}

EventReport.displayName = EventReport
export default connect(null, null, null, { forwardRef: true })(EventReport)
