import React from 'react';
import DataNotFound from '../../components/DataNotFound';
import { dateFormate } from '../../helpers/helper';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string'

const ChatSidebar = (props) => {
  const { threadList, onSelectThread, isLoading } = props
  const navigate = useNavigate();
  const location = useLocation()
  const obj = qs?.parse(location?.search) 

  return (
    <div className="sidebar">
      {!isLoading && threadList?.length === 0
        ? (<DataNotFound message="Support Chat" obj={obj} />) :
        <>
          <div className="contact-list">
            {threadList?.map((thread, index) => (
              <div key={index} className="contact" onClick={() => {
                navigate(`/chat-support?threadId=${thread?._id}`, { replace: true });
                onSelectThread(thread)

                }}>
                <div className="contact-info">
                  <h4>{thread?.oUser?.sUsername}</h4>
                </div>
                <span className="time">{dateFormate(thread?.dLastMsgAt)}</span>
              </div>
            ))}
          </div>
        </>}
    </div>
  );
};

export default ChatSidebar;
