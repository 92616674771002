import React, { forwardRef, Fragment, useEffect, useRef, useState } from 'react'
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import moment from 'moment'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'

import calendarIcon from '../../../../../assets/images/calendar.svg'

import Loading from '../../../../../components/Loading'
import { getTransactionTypeFilter } from '../../../../../actions/passbook'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import getSubCategoryList from '../../../../../api/category/getSubCategoryList'
import getCategoryList from '../../../../../api/category/getCategoryList'
import getEventsList from '../../../../../api/EventManagement/getEventsList'

function GenerateReport (props) {
  const { generateReportModal, setGenerateReportModal, getMatchListFunc, getMatchesTotalCountFunc, getMatchLeagueListFunc, generateTransactionReportFunc } = props

  const toggleModal = () => setGenerateReportModal(false)
  const [transactionType, setTransactionType] = useState('')
  const [type, setType] = useState('')
  const [transactionStatus, setTransactionStatus] = useState('')
  const [transactionEvent, setTransactionEvent] = useState('')
  const [transactionCategory, setTransactionCategory] = useState('')
  const [transactionSubCategory, setTransactionSubCategory] = useState('')
  const [sportsType, setSportsType] = useState('')
  const [match] = useState([])
  const [matchLeague] = useState([])
  const [matchOptions, setMatchOptions] = useState([])
  const [matchLeagueOptions, setMatchLeagueOptions] = useState([])
  const [searchValue] = useState([])
  const [searchType] = useState([])
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [limit] = useState(20)
  const [dateRangeErr, setDateRangeErr] = useState('')
  const [loading, setLoading] = useState(false)
  // const [matchActivePage, setMatchActivePage] = useState(1)
  // const [matchLeagueActivePage, setMatchLeagueActivePage] = useState(1)
  const [matchTotal, setMatchTotal] = useState(0)
  const [matchLeagueTotal, setMatchLeagueTotal] = useState(0)
  const [transactionTypeList, setTransactionTypeList] = useState([])

  const dispatch = useDispatch()
  const token = useSelector(state => state?.auth?.token)
  const Auth = useSelector(state => state.auth.adminData && state.auth.adminData.eType)
  const adminPermission = useSelector(state => state.auth.adminPermission)
  const matchList = useSelector(state => state.match.matchList)
  const matchesTotalCount = useSelector(state => state.match.matchesTotalCount)
  const matchLeagueList = useSelector(state => state.matchleague.matchLeagueList)
  const transactionTypes = useSelector(state => state?.passbook?.transactionTypeList)
  const previousProps = useRef({ sportsType, match, searchValue, matchList, matchLeagueList }).current

  const { data: categoryList } = useQuery({
    queryKey: ['getCategoryList', 0,50,'','Y'],
    queryFn: () => getCategoryList(0,50,'','Y'),
    select: (res) => res?.data?.data?.results
  })

  const { 
    data:subCategoryList, 
    isFetching, 
    fetchNextPage, 
    hasNextPage 
  } = useInfiniteQuery({
    queryKey: ['getSubCategoryList', 0, 10],
    queryFn: ({ pageParam = 0 }) => getSubCategoryList(pageParam, 10),
    getNextPageParam: (lastPage, allPages) => {
      const total = lastPage?.data?.data?.total;
      const nextPage = allPages.flatMap(page => page?.data?.data?.results).length;
      return nextPage < total ? nextPage : undefined;
    },
    select: (res) => res?.pages?.flatMap(page => page?.data?.data?.results) || [],
    keepPreviousData: true,
  });
  const { 
    data:eventList, 
    isFetching:isFetchingEvent, 
    fetchNextPage:fetchNextPageEvent, 
    hasNextPage:hasNextPageEvent 
  } = useInfiniteQuery({
    queryKey: ['getEventsList', 0, 10],
    queryFn: ({ pageParam = 0 }) => getEventsList(pageParam, 10),
    getNextPageParam: (lastPage, allPages) => {
      const total = lastPage?.data?.data?.total;
      const nextPage = allPages.flatMap(page => page?.data?.data?.results).length;
      return nextPage < total ? nextPage : undefined;
    },
    select: (res) => res?.pages?.flatMap(page => page?.data?.data?.results) || [],
    keepPreviousData: true,
  });
  const options = subCategoryList?.map(data => ({
    value: data._id,
    label: `${data?.sName}`,
  })) || []; 
  const optionsEvent = eventList?.map(data => ({
    value: data._id,
    label: `${data?.sName}`,
  })) || [];
  useEffect(() => {
    if (previousProps?.sportsType !== sportsType) {
      if (sportsType) {
        const start = 0
        getMatchListFunc(start, limit, searchValue, sportsType)
        getMatchesTotalCountFunc(searchValue, sportsType)
      }
    }
    return () => {
      previousProps.sportsType = sportsType
    }
  }, [sportsType])

  useEffect(() => {
    if (previousProps?.match !== match) {
      if (match) {
        const start = 0
        getMatchLeagueListFunc(start, limit, '', searchValue, match.value, sportsType)
      }
    }
    return () => {
      previousProps.match = match
    }
  }, [match])

  useEffect(() => {
    if (transactionTypes) {
      setTransactionTypeList(transactionTypes)
    }
  }, [transactionTypes])

  useEffect(() => {
    dispatch(getTransactionTypeFilter(token))
  }, [transactionType])

  useEffect(() => {
    if (previousProps?.matchList !== matchList) {
      if (matchList?.results?.length > 0) {
        const arr = [...matchOptions]
        matchList.results.map((match) => {
          const obj = {
            value: match?._id,
            label: match?.sTitle + '(' + moment(match?.dStartDate).format('DD/MM/YYYY hh:mm:ss A') + ')'
          }
          arr.push(obj)
          return arr
        })
        setMatchOptions(arr)
      }
      setLoading(false)
    }
    if (previousProps.matchesTotalCount !== matchesTotalCount) {
      setMatchTotal(matchesTotalCount?.count ? matchesTotalCount.count : 0)
      setLoading(false)
    }
    return () => {
      previousProps.matchList = matchList
      previousProps.matchesTotalCount = matchesTotalCount
    }
  }, [matchList, matchesTotalCount])

  useEffect(() => {
    if (previousProps.matchLeagueList !== matchLeagueList) {
      if (matchLeagueList?.results?.length > 0) {
        const arr = [...matchLeagueOptions]
        matchLeagueList.results.map((match) => {
          const obj = {
            value: match._id,
            label: match.sName
          }
          arr.push(obj)
          return arr
        })
        setMatchLeagueOptions(arr)
      }
      setLoading(false)
    }
    if (matchLeagueList && matchLeagueList.nTotal) {
      setMatchLeagueTotal(matchLeagueList.nTotal)
    }
    return () => {
      previousProps.matchLeagueList = matchLeagueList
    }
  }, [matchLeagueList])

  function onFiltering (event, type) {
    if (type === 'TransactionType') {
      setTransactionType(event.target.value)
    } else if (type === 'eType') {
      setType(event.target.value)
    } else if (type === 'TransactionStatus') {
      setTransactionStatus(event.target.value)
    } else if (type === 'SportsType') {
      setSportsType(event.target.value)
    }else if (type === 'transactionEvent') {
      setTransactionEvent(event.target.value)
    }else if (type === 'transactionCategory') {
      setTransactionCategory(event.target.value)
    }else if (type === 'transactionSubCategory') {
      setTransactionSubCategory(event.target.value)
    }
  }

  // function to put custom input in date-picker
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className='form-control date-range-notify' onClick={onClick}>
      <img alt='calendar' src={calendarIcon} />
      <Input ref={ref} className='range' placeholder='Select Date Range' readOnly value={value} />
      {/* {((!startDate) && (!endDate)) && <img src={calendarIcon} alt="calendar" />} */}
    </div>
  ))
  ExampleCustomInput.displayName = ExampleCustomInput

 

  useEffect(() => {
    const callSearchService = () => {
      if (searchType === 'Match') {
        const isMatchTotalValid = (matchTotal !== matchOptions.length)
        const isValueNotInList = !(matchList?.results?.some(match => match?.sName?.toUpperCase().includes(searchValue) || match?.sName?.toLowerCase().includes(searchValue)))
        if (isMatchTotalValid && isValueNotInList) {
          const start = 0
          getMatchListFunc(start, limit, searchValue, sportsType)
          getMatchesTotalCountFunc(searchValue, sportsType)
          setLoading(true)
        }
      } else if (searchType === 'MatchLeague') {
        const start = 0
        const isMatchLeagueTotalValid = (matchLeagueTotal !== matchLeagueOptions.length)
        const isMatchLeagueNotInList = !(matchLeagueList.results.some(data => data.sName.toUpperCase().includes(searchValue) || data.sName.toLowerCase().includes(searchValue)))
        if (isMatchLeagueTotalValid && isMatchLeagueNotInList) {
          getMatchLeagueListFunc(start, limit, 'LEAGUE', searchValue, match.value, sportsType)
          setLoading(true)
        }
      }
    }
    if (previousProps.searchValue !== searchValue) {
      const debouncer = setTimeout(() => {
        callSearchService()
      }, 1000)
      return () => {
        clearTimeout(debouncer)
        previousProps.searchValue = searchValue
      }
    }
    return () => {
      previousProps.searchValue = searchValue
    }
  }, [searchValue])
  function onSubmit (e) {
    e.preventDefault()
    let validate = false
    if (match?.value) {
      validate = match?.value
    } else {
      validate = startDate && endDate
    }
    if (validate) {
      generateTransactionReportFunc(startDate, endDate, transactionType, type, transactionStatus, sportsType, match?.value || '', matchLeague?.value || '',transactionCategory,transactionSubCategory,transactionEvent)
    } else {
      if (!(match?.value) && (!startDate || !endDate)) {
        setDateRangeErr('Required field')
      }
    }
  }

  return (
    <Modal className='modal-league-analytics' isOpen={generateReportModal}>
      <ModalHeader className='popup-modal-header modal-title-head w-100' toggle={toggleModal}>Generate Report</ModalHeader>
      <ModalBody className="modal-prize-popup p-4">
        {loading && <Loading />}

        <Form className='form-group'>
          <Row>
            <Col className='mt-2' md={6} xl={6}>
              <FormGroup>
                <Label for='dateRange'>Date Range</Label>
                <DatePicker
                  customInput={<ExampleCustomInput />}
                  dropdownMode="select"
                  endDate={endDate}
                  isClearable={true}
                  maxDate={new Date()}
                  onChange={(update) => {
                    setDateRange(update)
                    setDateRangeErr('')
                  }}
                  peekNextMonth
                  placeholderText='Select Date Range'
                  selectsRange={true}
                  showMonthDropdown
                  showYearDropdown
                  startDate={startDate}
                  value={dateRange}
                />
                <p className='error-text'>{dateRangeErr}</p>
              </FormGroup>
            </Col>
            <Col className='mt-2' md={6} xl={6}>
              <FormGroup>
                <Label for='transactionEvent'>Event</Label>
                <Select
                  isClearable
                  options={optionsEvent}
                  onMenuScrollToBottom={() => {
                    if (hasNextPageEvent && !isFetchingEvent) {
                      fetchNextPageEvent();
                    }
                  }}
                  onChange={(selectedOption) => setTransactionEvent(selectedOption?.value)}
                  placeholder='Select Event'
                  value={transactionEvent}
                />

              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className='' md={6} xl={6}>
            <FormGroup>
                <Label for='transactionCategory'>Category</Label>
                <Input
                  // className={ 'form-control'}
                  disabled={adminPermission?.OT_CATEGORY === 'R'}
                  name="category"
                  onChange={event => onFiltering(event, 'transactionCategory')}
                  type="select"
                  value={transactionCategory}
                >
                  <option value=''>Select Category</option>
                  {categoryList?.map((category)=>{
                    return <option value={category?._id} data-key={category?._id}>{category?.sName}</option>
                  })}
                </Input>

                {/* <Input
                  className="custom-input-transaction"
                  id="transactionCategory"
                  name="transactionCategory"
                  onChange={(event) => onFiltering(event, 'transactionCategory')}
                  type="select"
                  value={transactionCategory}
                >
                  <option value="">All</option>
                  <option value="CMP">Completed</option>
                  <option value="R">Refunded</option>
                  <option value="CNCL">Cancelled</option>
                </Input> */}
              </FormGroup>
            </Col>
            <Col className='' md={6} xl={6}>
            {/* <FormGroup>
                <Label for='transactionSubCategory'>Sub Category</Label>
                <Input
                  className="custom-input-transaction"
                  id="transactionSubCategory"
                  name="transactionSubCategory"
                  onChange={(event) => onFiltering(event, 'transactionSubCategory')}
                  type="select"
                  value={transactionSubCategory}
                >
                  <option value="">All</option>
                  <option value="CMP">Completed</option>
                  <option value="R">Refunded</option>
                  <option value="CNCL">Cancelled</option>
                </Input>
              </FormGroup> */}
              <FormGroup className='form-group'>
              <Label for='transactionSubCategory'>Sub Category</Label>
                <Select
                  isClearable
                  options={options}
                  onMenuScrollToBottom={() => {
                    if (hasNextPage && !isFetching) {
                      fetchNextPage();
                    }
                  }}
                  onChange={(selectedOption) => setTransactionSubCategory(selectedOption?.value)}
                  placeholder='Select Subcategory'
                  value={transactionSubCategory}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row className='mt-2'>
            <Col className='mt-2' md={6} xl={6}>
              <FormGroup>
                <Label for='transactionStatus'>Transaction Status</Label>
                <Input
                  className="custom-input-transaction"
                  id="transactionStatus"
                  name="transactionStatus"
                  onChange={(event) => onFiltering(event, 'TransactionStatus')}
                  type="select"
                  value={transactionStatus}
                >
                  <option value="">All</option>
                  <option value="CMP">Completed</option>
                  <option value="R">Refunded</option>
                  <option value="CNCL">Cancelled</option>
                </Input>
              </FormGroup>
            </Col>
            <Col className='mt-2' md={6} xl={6}>
              <FormGroup>
                <Label for='eType'>Type</Label>
                <Input
                  className="custom-input-transaction"
                  id="eType"
                  name="eType"
                  onChange={(event) => onFiltering(event, 'eType')}
                  type="select"
                  value={type}
                >
                  <option value="">All</option>
                  <option value="Cr">Credited</option>
                  <option value="Dr">Debited</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>

          <Row className='mb-3 mt-2'>
            <Col className='mt-2' md={6} xl={6}>
              <FormGroup>
                <Label for='TransactionType'>Transaction Type</Label>
                <Input
                  id="TransactionType"
                  name="TransactionType"
                  onChange={(event) => onFiltering(event, 'TransactionType')}
                  type="select"
                  value={transactionType}
                >
                  <option key='' value=''>Transaction Type</option>
                  {transactionTypeList && transactionTypeList?.length !== 0 && transactionTypeList?.eTransactionTypes?.map((data, i) => {
                    return (
                      <>
                        <option key={i} value={data}>{data}</option>
                      </>
                    )
                  })
                  }
                </Input>
              </FormGroup>
            </Col>
          </Row>

          <div>
            {
            ((Auth && Auth === 'SUPER') || (adminPermission?.PASSBOOK !== 'R')) &&
            (
              <Fragment>
                <Button className="theme-btn full-btn" onClick={onSubmit} type="submit">Generate</Button>
              </Fragment>
            )
          }
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

GenerateReport.propTypes = {
  generateReportModal: PropTypes.bool,
  setGenerateReportModal: PropTypes.func,
  value: PropTypes.string,
  onClick: PropTypes.func,
  token: PropTypes.string,
  getMatchListFunc: PropTypes.func,
  getMatchesTotalCountFunc: PropTypes.func,
  getMatchLeagueListFunc: PropTypes.func,
  generateTransactionReportFunc: PropTypes.func
}

export default GenerateReport
