import { UPDATE_STREAK } from '../actions/constants'
import { commonReducer } from '../helpers/helper'

const StreakReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_STREAK:
      return commonReducer(state, action)
    default:
      return state
  }
}
export default StreakReducer