import React, { useState } from 'react';

const MessageInput = ({ onSendMessage }) => {
  const [message, setMessage] = useState('');

  const handleSend = () => {
    if (message) {
      onSendMessage(message);
      setMessage('');
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };
  return (
    <div className="message-input">
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type a message..."
        onKeyPress={handleKeyPress}
      />
      <button onClick={handleSend} className='theme-btn icon-btn-export  btn btn-secondary' disabled={message?false:true} style={{height:'43px',width:'65px'}}>Send</button>
    </div>
  );
};

export default MessageInput;
