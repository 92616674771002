import React, { forwardRef, Fragment, useState } from "react"
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, PopoverBody, UncontrolledDropdown, UncontrolledPopover } from "reactstrap"
import infoIcon from '../../../assets/images/info2.svg'
import backIcon from '../../../assets/images/back-icon-1.svg'
import excelIcon from '../../../assets/images/excel-icon.svg'
import addlIcon from '../../../assets/images/add-white-icon.svg'
import closeIcon from '../../../assets/images/close-icon.svg'
import calendarIcon from '../../../assets/images/calendar.svg'

import { Link, useLocation, useNavigate } from "react-router-dom"
import DatePicker from "react-datepicker"

const Heading = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const { heading,  permission, automatedNotification, recommendedList, FormatsList, version, list,onFiltering,settingManagement,eCategory,
        feedback,promocode,notificationFilter,sliderStatistics, startDate, endDate,setDateRange, dateFlag,dateRange,pushNotification,sPlatform,
        notification,setModalOpen,subCategory,categoryList
     } = props
    const [show, setShow] = useState(false)
    const page = JSON.parse(localStorage.getItem('queryParams'))
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className='form-control date-range form-group' onClick={onClick}>
          <img alt="calendar" src={calendarIcon} />
          <Input ref={ref} className='range' placeholder='Select Date Range' readOnly value={value} />
        </div>
      ))
      ExampleCustomInput.displayName = ExampleCustomInput
    return (
        <div className='setting-header-block'>
            <div className='d-flex justify-content-between'>
                <div className='d-flex inline-input'>
                    {automatedNotification ? <img className='custom-go-back' alt="back" height='24' onClick={() => navigate(`/content-management/push-notification${page?.PushNotificationManagement || ''}`)} src={backIcon} width='24' /> : ''}
                    <h2 className='ml-2'>
                        {heading}
                        {props?.info && (
                            <Fragment>
                                <img className='custom-info' id='info' src={infoIcon} alt="info" />
                                <UncontrolledPopover placement="bottom" target='info' trigger="legacy">
                                    <PopoverBody>
                                        <p>After updating anything from here, It will take some time to reflect on the app.</p>
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </Fragment>
                        )}

                    </h2>
                </div>
                {props?.onExport && FormatsList && (FormatsList?.total > 0 || FormatsList?.length !== 0)
                    ? (
                        <img
                            alt='excel'
                            className='header-button'
                            onClick={props?.onExport}
                            src={excelIcon}
                            style={{ cursor: 'pointer' }}
                            title='Export'
                        />
                    )
                    : ''}
            </div>
            <div className='d-flex justify-content-between align-items-center fdc-480'>
                <Form className={`d-flex fdc-480 align-items-center setting-filter ${location?.search?.includes('SEASON_WISE') ? '' : 'flex-wrap'}`}>
                    {props.handleSearch
                        ? (
                            <FormGroup className="form-group">
                                <Input
                                    autoComplete='off'
                                    className='search-box'
                                    name='search'
                                    onChange={props?.handleSearch}
                                    placeholder='Search'
                                    type='search'
                                    value={props?.search}
                                    style={{ '-webkit-appearance': 'none' }} 
                                />
                            </FormGroup>
                        )
                        : ''}
                    {props?.handleRecommendedSearch && (
                        <FormGroup>
                            <UncontrolledDropdown>
                                <DropdownToggle caret className='searchList w-100' nav>
                                    <Input
                                        autoComplete="off"
                                        className='search-box'
                                        name='search'
                                        onChange={(e) => {
                                            props?.handleRecommendedSearch(e, e?.target?.value)
                                            props?.handleChangeSearch(e, '')
                                            setShow(true)
                                        }}
                                        onKeyPress={(e) => {
                                            props?.handleRecommendedSearch(e, e?.target?.value)
                                            props?.handleChangeSearch(e, '')
                                        }}
                                        placeholder='Search'
                                        type='text'
                                        value={props?.search || props?.complaintSearch}
                                    />
                                </DropdownToggle>
                                {(props?.search || props?.complaintSearch)
                                    ? (
                                        <img alt="close"
                                            className='custom-close-img'
                                            onClick={(e) => {
                                                props?.handleRecommendedSearch(e, '')
                                                props?.handleChangeSearch(e, '')
                                            }
                                            }
                                            src={closeIcon}
                                        />
                                    )
                                    : ''}
                                {(list?.nTotal >= 1 || list?.aData?.length >= 1)
                                    ? (
                                        <DropdownMenu className={recommendedList?.length >= 1 ? 'recommended-search-dropdown' : ''} open={show}>
                                            {(recommendedList?.length >= 1)
                                                ? ((typeof (props?.complaintSearch) === 'number')
                                                    ? (
                                                        <Fragment>
                                                            {
                                                                recommendedList?.length > 0 && recommendedList?.map((recommendedData, index) => {
                                                                    return (
                                                                        <DropdownItem key={index} onClick={(e) => { props?.handleChangeSearch(e, recommendedData.sMobNum) }}>
                                                                            {recommendedData.sMobNum}
                                                                        </DropdownItem>
                                                                    )
                                                                })}
                                                        </Fragment>
                                                    )
                                                    : (
                                                        <Fragment>
                                                            {
                                                                recommendedList?.length > 0 && recommendedList?.map((recommendedData, index) => {
                                                                    return (
                                                                        <DropdownItem key={index} onClick={(e) => { props?.handleChangeSearch(e, recommendedData?.sUsername) }}>
                                                                            {recommendedData?.sUsername}
                                                                        </DropdownItem>
                                                                    )
                                                                })}
                                                        </Fragment>
                                                    ))
                                                : (
                                                    <DropdownItem>
                                                        User not found
                                                    </DropdownItem>
                                                )}
                                        </DropdownMenu>
                                    )
                                    : ''}
                            </UncontrolledDropdown>
                        </FormGroup>
                    )}
                    {settingManagement && (
                        <FormGroup className="form-group">
                            <Input
                                className='format-f'
                                id="ePlatform"
                                name="ePlatform"
                                onChange={(e) => onFiltering(e, 'eCategory')}
                                type="select"
                                value={eCategory}
                            >
                                <option value="" key="category">Category</option>
                                <option value="APP" key="APP">APP</option>
                                <option value="ADMIN" key="ADMIN">ADMIN</option>
                                <option value="PAYMENT" key="PAYMENT">PAYMENT</option>
                                {/* <option value="MATCH" key="MATCH">MATCH</option> */}
                            </Input>
                        </FormGroup>
                    )}
                    {subCategory && (
                        <FormGroup className="form-group">
                            <Input
                                className='format-f'
                                id="iCategoryId"
                                name="iCategoryId"
                                onChange={(e) => onFiltering(e, 'eCategory')}
                                type="select"
                                value={eCategory}
                            >
                                <option value="" key="category" >Category</option>
                                {categoryList?.results?.length && categoryList?.results?.map((i) => {
                                    return <option value={i?._id} key={i?._id}>{i?.sName}</option>
                                })}
                            </Input>
                        </FormGroup>
                    )}
                    {(feedback || promocode || notificationFilter || sliderStatistics) && (
                        <>
                            <FormGroup className="form-group">
                                <DatePicker
                                    customInput={<ExampleCustomInput />}
                                    dropdownMode="select"
                                    endDate={endDate}
                                    isClearable={true}
                                    onChange={(update) => {
                                        setDateRange(update)
                                        dateFlag && (dateFlag.current = true)
                                    }}
                                    peekNextMonth
                                    placeholderText='Select Date Range'
                                    selectsRange={true}
                                    showMonthDropdown
                                    showYearDropdown
                                    startDate={startDate}
                                    value={dateRange}
                                    maxDate={new Date()}
                                />
                            </FormGroup>
                        </>
                    )}

                    {pushNotification && (
                        <Input
                            className='format-f'
                            id="sPlatform"
                            name="sPlatform"
                            onChange={(event) => onFiltering(event)}
                            type="select"
                            value={sPlatform}
                        >
                            <option value="">All</option>
                            <option value="Web">Web</option>
                            <option value="IOS">IOS</option>
                            <option value="Android">Android</option>
                        </Input>
                    )}
                </Form>
                <FormGroup className='d-flex'>
                    {(props?.buttonText && permission) && (
                        <Button className={`theme-btn icon-btn ${version} `} tag={Link} to={props?.setUrl}>
                            <img alt="add" src={addlIcon}  />
                            {props?.buttonText}
                        </Button>
                    )}
                    {(permission && props?.aNotification) && (
                        <Button className={`theme-btn mr-3 ${version} icon-btn`} tag={Link} to='/content-management/push-notification/automated-notification'>
                            {props?.aNotification}
                        </Button>
                    )}
                    {(notificationFilter && permission && props?.autoMatedNotification) && (
                        <Button className={`theme-btn mr-3 ${version} icon-btn`} tag={Link} to='/content-management/notification-management/automated-notification'>
                            {props?.autoMatedNotification}
                        </Button>
                    )}
                    {(permission && notification) && (
                        <Button className={`theme-btn icon-btn ${version} && ms-2 `} tag={Link} onClick={() => {
                            setModalOpen(true)
                        }}>
                            <img alt="add" src={addlIcon} />
                            {notification}
                        </Button>
                    )}
                    
                </FormGroup>
            </div>
        </div>
    )
}
export default Heading