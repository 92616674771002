import { CHANGE_CHAT_STATUS, SEND_MESSAGE } from "../actions/constants"
import { commonReducer } from "../helpers/helper"

const ChatReducer = (state = { }, action = {}) => {
    switch (action.type) {
      case SEND_MESSAGE:
        return commonReducer(state, action)
      case CHANGE_CHAT_STATUS:
        return {
          ...state,
          resStatus: action.payload.resStatus,
          resMessage: action.payload.resMessage,
          type: action.payload.type,
          data:action?.payload?.data
        }
        default:
            return state
    }
}
export default ChatReducer
