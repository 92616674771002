import React, { useEffect, useState } from 'react';
import { Table, Row, Col } from 'reactstrap';
// import './OrderBook.css'; // Add the custom CSS for styling

const OrderBook = (props) => {
    const { orderBookList } = props
    // const { id } = useParams()
    const [yesData, setYesData] = useState({})
    const [noData, setNoData] = useState({})
    useEffect(() => {
        if (orderBookList?.length) {
            setYesData(orderBookList?.find(item => item.sSymbol === 'YES')?.oOrderBook || {})
            setNoData(orderBookList?.find(item => item.sSymbol === 'NO')?.oOrderBook || {})
        }
    }, [orderBookList])
    const renderTableRows = (data, symbol) => {
        const sortedEntries = Object.entries(data)?.sort(([a], [b]) => parseFloat(a) - parseFloat(b));
        return sortedEntries.map(([price, quantity]) => (
            <tr key={price}>
                <td>{price}</td>
                <td>
                    <div className="bar-container">
                        <span className="qty-value">{quantity}</span>
                        <div className={`bar ${symbol}-bar`} style={{ width: `${(quantity / 100000) * 100}%` }}></div>
                    </div>
                </td>
            </tr>
        ));
    };
    return (
        <div className="order-book">
            {/* <Row className="tab-container">
                <Col>
                    <div
                        className={`tab fw-bold ${activeTab === 'BUY' ? 'active' : ''}`}
                        onClick={() => setActiveTab('BUY')}
                    >
                        BUY
                    </div>
                </Col>
                <Col>
                    <div
                        className={`tab fw-bold ${activeTab === 'SELL' ? 'active' : ''}`}
                        onClick={() => setActiveTab('SELL')}
                    >SELL</div>
                </Col>
            </Row> */}
            <Row className="table-container">
                <Col md={6}>
                    <Table borderless className="order-table">
                        <thead>
                            <tr>
                                <th className='fw-bold'>PRICE</th>
                                <th className='fw-bold'>QTY AT <span className="yes">YES</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderTableRows(yesData, 'yes')}
                        </tbody>
                    </Table>
                </Col>

                <Col md={6}>
                    <Table borderless className="order-table">
                        <thead>
                            <tr>
                                <th className='fw-bold'>PRICE</th>
                                <th className='fw-bold'>QTY AT <span className="no">NO</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderTableRows(noData, 'no')}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
    );
};

export default OrderBook;
