import React, { useEffect, useRef, useState } from 'react';
import { dateFormate } from '../../../helpers/helper';
import MessageInput from './MessageInput';
import { useDispatch, useSelector } from 'react-redux';
import { sendMessage } from '../../../actions/chat';
import { useQueryClient } from '@tanstack/react-query';

const ChatMessages = (props) => {
  const { threadMessages, thread,isFetching,chatWindowRef } = props;
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const messagesEndRef = useRef(null); 
  const [, setShowInputBox] = useState(false); 
  const data = useSelector(state => state.chat.data)
  // const scrollToBottom = () => {
  //   messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  // };

  // useEffect(() => { 
  //   scrollToBottom();
  // }, [threadMessages]); 
  useEffect(() => {
    if(data===undefined && thread?.eThreadStatus==='A'){
      setShowInputBox(true)
    }else if(data?.eThreadStatus==='A'){
      setShowInputBox(true)
    }else{
      setShowInputBox(false)
    }
  },[data?.eThreadStatus,thread?.eThreadStatus])

  const handleSendMessage = (message) => {
    const sendMsgData = { message, threadId : thread?._id };
    dispatch(sendMessage(sendMsgData));

    setTimeout(() => {
      queryClient?.invalidateQueries('getThreadMessages');
    }, 100); 

  };
  return (
    <div className="messages" ref={chatWindowRef} style={{ overflowY: 'auto', height: '500px' }}>
      {threadMessages?.map((message, index) => (
        <div key={index} className={`message ${message?.eUserType === 'A' ? 'sent' : 'received'}`}>
          <p>{message?.sMessage}</p>
          <span className="message-time position-static">{dateFormate(message?.dSentAt)}</span>
        </div>
      ))}
      {isFetching && <p>Loading more messages...</p>}
      <div ref={messagesEndRef} />
      <MessageInput onSendMessage={handleSendMessage} />
    </div>
  );
};

export default ChatMessages;
