import axios from '../axios'
import { catchFunc } from '../helpers/helper'
import { ADD_ORDER, CANCEL_ORDER, CLEAR_ORDERS_MESSAGE, ORDER_LIST, ORDERS_TOTAL_COUNT ,SELL_ORDER,ORDER_DETAILS} from './constants'
// const errMsg = 'Server is unavailable.'

export const getOrdersTotalCount = (data) => async (dispatch) => {
    const {bIsAdminOrders, searchvalue, filterBy, startDate, endDate, token,id ,eventOrderStatus,eventBidType,eventOrderType,userId} = data
    let params = {
      searchBy:filterBy,
      iEventId:id,
      eOrderStatus:eventOrderStatus,
      eBidType:eventBidType,
      eOrderType:eventOrderType,
    }
    if(bIsAdminOrders==='admin'){
      params.eUserType='B'
    }else if(bIsAdminOrders==='user'){
      params.eUserType='U'
    }
    if(startDate && endDate){
      params.dFrom = startDate
      params.dTo = endDate
    }
    if(searchvalue){
      params.search = searchvalue
    }
    if(userId){
      params.iUserId=userId
    }else{
      params.iEventId=id
    }
    await axios.get('/ot/admin/order/count/v1', {
      params: params,
      headers: { Authorization: token }
    }).then((response) => {
      dispatch({
        type: ORDERS_TOTAL_COUNT,
        payload: {
          data: response.data.data ? response.data.data : {},
          resStatus: true
        }
      })
    }).catch(() => {
      dispatch({
        type: ORDERS_TOTAL_COUNT,
        payload: {
          data: {},
          resStatus: false
        }
      })
    })
  }
  
  export const getOrderList = (orderData) => async (dispatch) => {
    dispatch({ type: CLEAR_ORDERS_MESSAGE })
    const {bIsAdminOrders, start, limit, sort, order, searchvalue, filterBy, startDate, endDate,  eventOrderType, isFullResponse, token,id,eventOrderStatus,eventBidType,userId } = orderData
  //   let filterValues = []
  //   if (typeof filterBy === 'object') {
  //   filterValues = filterBy?.map((item) => item?.value)
  // }
  const paramsD={
    start,
    limit,
    sorting: sort,
    order,
    search: searchvalue,
    dFrom: startDate,
    dTo: endDate,
    isFullResponse,
    searchType:filterBy,
    eOrderStatus:eventOrderStatus,
    eBidType:eventBidType,
    eOrderType:eventOrderType,
  }
  if(bIsAdminOrders==='admin'){
    paramsD.eUserType='B'
  }else if(bIsAdminOrders==='user'){
    paramsD.eUserType='U'
  }
  if(userId){
    paramsD.iUserId=userId
  }else{
    paramsD.iEventId=id
  }
    await axios.get('/ot/admin/order/list/v1', {
      params: paramsD,
      headers: { Authorization: token }
    }).then((response) => {
      dispatch({
        type: ORDER_LIST,
        payload: {
          data: response?.data?.data ? response.data.data : [],
          resStatus: true,
          resMessage: response?.data?.message || '',
          isFullResponse
        }
      })
    }).catch(() => {
      dispatch({
        type: ORDER_LIST,
        payload: {
          data: [],
          resStatus: false
        }
      })
    })
  }

  export const cancelOrder = (orderData) => async (dispatch) => {
    const { orderIds,id } = orderData
    dispatch({ type: CLEAR_ORDERS_MESSAGE })
    await axios?.put('/ot/admin/order/v1', {
      iEventId:id,
      aOrderIds:orderIds
    })?.then((response) => {
      dispatch({
        type: CANCEL_ORDER,
        payload: {
          resStatus: true,
          resMessage: response?.data?.message
        }
      })
    }).catch((error) => {
      dispatch(catchFunc(CANCEL_ORDER, error))
    })
  }
  export const sellOrder = (orderData) => async (dispatch) => {
    const { bIsSellOrder,eventId,orders } = orderData
    dispatch({ type: CLEAR_ORDERS_MESSAGE })
    await axios?.post('/ot/admin/sell-order/v1', {
      iEventId:eventId,
      bIsSellOrder,
      aOrders:orders
    })?.then((response) => {
      dispatch({
        type: SELL_ORDER,
        payload: {
          resStatus: true,
          resMessage: response?.data?.message
        }
      })
    }).catch((error) => {
      dispatch(catchFunc(SELL_ORDER, error))
    })
  }
  export const addOrder = (addOrderData) => async (dispatch) => {
    const { priceMarket,selectOption,priceRange,quantity,stopLossPrice,bookProfitPrice,eventId,isOpenMarketInput,marketPrice } = addOrderData
    dispatch({ type: CLEAR_ORDERS_MESSAGE })
    const params = {
      iEventId:eventId,
      eOrderType:priceMarket,
      sSymbol:selectOption,
      // nPrice:priceRange,
    
      nStopLoss:stopLossPrice,
      nBookProfit:bookProfitPrice
    }
    if(isOpenMarketInput){
      params.nPrice=marketPrice
    }else{
      params.nPrice=priceRange
      params.nQty=quantity
    }
    await axios?.post('/ot/admin/buy-order/v1', params)?.then((response) => {
      dispatch({
        type: ADD_ORDER,
        payload: {
          resStatus: true,
          resMessage: response?.data?.message
        }
      })
    }).catch((error) => {
      dispatch(catchFunc(ADD_ORDER, error))
    })
  }
  export const getOrderDetails = (orderId) => async (dispatch) =>{
    await axios.get(`/ot/admin/order/${orderId}/v1` ).then((response) => {
      dispatch({
        type: ORDER_DETAILS,
        payload: {
          data: response.data.data ? response.data.data : {},
          resStatus: true
        }
      })
    }).catch(() => {
      dispatch({
        type: ORDER_DETAILS,
        payload: {
          data: {},
          resStatus: false
        }
      })
    })
  }