import axios from '../../axios'
const getThreadList = async (start, limit, search, status) => {
    let url = `/ot/admin/thread/list/v1?threadStatus=${status}`
    if (start) {
        url += `&start=${start}`
    }
    if (limit) {
        url += `&limit=${limit}`
    }
    if (search) {
        url += `&search=${search}`
    }
    const data = await axios.get(url)
    return data
}

export default getThreadList
