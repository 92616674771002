import React from 'react'
import DashboardContent from './dashboard'
import MainHeading from '../Settings/Component/MainHeading'

function index (props) {
  return (
    <>
      <MainHeading
        heading="Dashboard"
      />
      <DashboardContent {...props} />
    </>
  )
}

export default index
