import React, { useState } from 'react'

// import SubAdminMainHeader from '../../components/SubAdminMainHeader'
import EventDetails from './EventDetails'
import SubAdminMainHeader from '../../SubAdmin/components/SubAdminMainHeader'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Input, Label, Modal, ModalBody, Row } from 'reactstrap'
import { declareOutComeEvent } from '../../../actions/events'

function EventDetailsIndex (props) {
  const eventDetails = useSelector(state => state?.events?.eventDetails)
  const [modalWarningEvent, setModalWarningEvent] = useState(false)
  const [declareOutCome,setDeclareOutCome] = useState('')
  const [declareOutComeErr,setDeclareOutComeErr] = useState('')
  const dispatch = useDispatch()
  const adminPermission = useSelector(state => state?.auth?.adminPermission)

  const toggleWarningEvent = () => setModalWarningEvent(!modalWarningEvent)

  function onCancelPopup(){
    toggleWarningEvent()
    setDeclareOutCome('')
  }

  function handleSubmitOutCome(){
    if(declareOutCome){
      const yesOption = eventDetails?.aAnswerOptions?.find(option => option?.sValue ===declareOutCome);
      const outComeId = yesOption?._id;
      const eventId = eventDetails?._id
      setDeclareOutComeErr('')
      dispatch(declareOutComeEvent({eventId,outComeId}))
      toggleWarningEvent()
      setDeclareOutCome('')
    }else{
      setDeclareOutComeErr('Required Field')
    }
  }
  return (
    <>
      <SubAdminMainHeader
        {...props}
        EventDetails
        header="Event Details"
        onDeclareOutcome={toggleWarningEvent}
        isDisable={eventDetails?.eStatus==='po' ? false:true}
      />
      <div className='without-pagination'>
        <EventDetails
          {...props}
        />
      </div>
      <Modal className="modal-confirm" isOpen={modalWarningEvent} toggle={toggleWarningEvent}>
        <ModalBody className='text-center'>
          <h2 className='popup-modal-message mb-3'>Change Status</h2>
          <div className="d-flex inline-input  text-center mb-2 ms-4">
            <Label className='me-2'>Declare Outcome : </Label>
            <div className='custom-radio custom-control'>
              <Input
                type="radio"
                defaultChecked={declareOutCome === 'YES'}
                className='custom-control-input me-2'
                disabled={adminPermission?.OT_EVENT === 'R'}
                id="themeRadio1"
                label="Yes"
                name="themeRadio"
                value={'YES'}
                onChange={event => setDeclareOutCome(event.target?.value)}
              />
              <Label>Yes</Label>
            </div>
            <div className='custom-radio custom-control'>
              <Input
                type="radio"
                defaultChecked={declareOutCome === 'NO'}
                className='custom-control-input me-2'
                disabled={adminPermission?.OT_EVENT === 'R'}
                id="themeRadio2"
                label="No"
                name="themeRadio"
                value={'NO'}
                onChange={event => setDeclareOutCome(event.target?.value)}
              />
              <Label>No</Label>
            </div>
            <p className="error-text">{declareOutComeErr}</p>
          </div>
          <Row className='row-12'>
            <Col>
              <Button className='theme-btn outline-btn-cancel full-btn-cancel' onClick={onCancelPopup} type='submit'>Cancel</Button>
            </Col>
            <Col>
              <Button className='theme-btn danger-btn full-btn' onClick={handleSubmitOutCome} type='submit'>{`Save`}</Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default EventDetailsIndex
