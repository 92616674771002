import { combineReducers } from 'redux'
import auth from './auth'
import role from './role'
import permission from './permission'
import subadmin from './subadmin'
import url from './url'
import users from './users'
import match from './match'
import banner from './banner'
import sports from './sports'
import matchleague from './matchleague'
import version from './version'
import notification from './notification'
import kyc from './kyc'
import category from './category'
import subCategory from './subCategory'
import setting from './setting'
import events from './events'
import userXp from './userXP'
import orders from './orders'
import pushNotification from './pushNotification'
import apilogs from './apilogs'
import passbook from './passbook'
import league from './league'
import withdraw from './withdraw'
import deposit from './deposit'
import reports from './reports'
import dashboard from './dashboard'
import streak from './streak'
import chat from './chat'

export default combineReducers({
    auth,
    role,
    permission,
    url,
    users,
    subadmin,
    match,
    banner,
    sports,
    matchleague,
    version,
    notification,
    kyc,
    category,
    subCategory,
    setting,
    events,
    userXp,
    orders,
    pushNotification,
    apilogs,
    passbook,
    league,
    withdraw,
    deposit,
    reports,
    dashboard,
    streak,
    chat
})