import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useQueryState } from 'react-router-use-location-state'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import moment from 'moment'
import qs from 'query-string'
import { DndContext, KeyboardSensor, MouseSensor, TouchSensor, closestCorners, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useMutation, useQuery } from '@tanstack/react-query'

import UserListHeader from '../Component/UsersListHeader'
import TDSManagement from './TDSManagement'
import UsersListMainHeader from '../Component/UsersListMainHeader'
import SortableColumn from '../PassbookManagement/sortableColumn'

import { getTDSList, tdsCount, updateTds, getLeagueTdsList, tdsLeagueCount } from '../../../actions/users'
import { getDynamicColumns } from '../../../query/dynamicColumns/query'
import { sendColumnsData } from '../../../query/dynamicColumns/mutation'

const TDS = props => {
  const { id } = useParams()
  const location = useLocation()

  const [search, setSearch] = useState('')
  const [initialFlag, setInitialFlag] = useState(false)
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [leagueToTds, setLeagueToTds] = useState(false)
  const [leagueToTdsId, setLeagueToTdsId] = useState('')
  const [leagueToTdsMatch, setLeagueToTdsMatch] = useState('')
  const [leagueToTdsLeague, setLeagueToTdsLeague] = useState('')
  const [filter, setFilter] = useQueryState('status', 'P')
  const [userType, setUserType] = useQueryState('eUserType', '')
  const [columns, setColumns] = useState()
  const [isManageColumn, setIsManageColumn] = useState(false)
  const [columnNames, setColumnNames] = useState()

  const dispatch = useDispatch()
  const token = useSelector((state) => state?.auth?.token)
  const tdsList = useSelector((state) => state?.users?.tdsList)
  const content = useRef()

  useEffect(() => {
    const obj = qs?.parse(location?.search)
    if (obj?.search) {
      setSearch(obj?.search)
    }
    if (obj?.datefrom && obj?.dateto) {
      setDateRange([new Date(obj?.datefrom), new Date(obj?.dateto)])
    }
    // when navigate to the matchLeague to Tds page , ulr matchLeague sport/match-management/match-contest-management/id
    // this state are set
    setLeagueToTds(location?.state?.leagueTotds) // set flag
    setLeagueToTdsId(location?.state?.leagueTdsId)// set id
    setLeagueToTdsMatch(location?.state?.matchNameToTds) // set matchName
    setLeagueToTdsLeague(location?.state?.leagueNameToTds) // set league id
  }, [])

  function onHandleSearch (e) {
    if (e?.key === 'Enter') {
      e?.preventDefault()
    }
    setSearch(e?.target?.value)
    setInitialFlag(true)
  }

  function getTDSTotalCountFunc (searchValue, status, userType, startDate, endDate) {
    const dateFrom = startDate ? new Date(moment(startDate)?.startOf('day')?.format()) : ''
    const dateTo = endDate ? new Date(moment(endDate)?.endOf('day')?.format()) : ''
    // const id = Id
    const data = {
      searchValue, status, userType, startDate: dateFrom ? new Date(dateFrom)?.toISOString() : '', endDate: dateTo ? new Date(dateTo)?.toISOString() : '', token
    }
    // dispatch action set to tds Count api
    dispatch(tdsCount(data))
  }

  function getLeagueTdsCount (searchValue, status, userType, startDate, endDate) {
    const dateFrom = startDate ? new Date(moment(startDate)?.startOf('day')?.format()) : ''
    const dateTo = endDate ? new Date(moment(endDate)?.endOf('day')?.format()) : ''
    // const id = Id
    const data = {
      id, searchValue, status, userType, startDate: dateFrom ? new Date(dateFrom)?.toISOString() : '', endDate: dateTo ? new Date(dateTo)?.toISOString() : '', token
    }
    // dispatch action set to tds-league count
    dispatch(tdsLeagueCount(data))
  }

  function getList (start, limit, sort, order, userType, searchValue, status, startDate, endDate, isFullResponse) {
    const dateFrom = startDate ? new Date(moment(startDate)?.startOf('day')?.format()) : ''
    const dateTo = endDate ? new Date(moment(endDate)?.endOf('day')?.format()) : ''
    const data = {
      start, limit, sort, order, userType, searchValue: searchValue?.trim(), status, startDate: dateFrom ? new Date(dateFrom)?.toISOString() : '', endDate: dateTo ? new Date(dateTo)?.toISOString() : '', isFullResponse, token
    }
    // dispatch action set to tds list
    dispatch(getTDSList(data))
  }

  function getLeagueTds (start, limit, sort, order, userType, searchValue, status, startDate, endDate, isFullResponse) {
    const dateFrom = startDate ? new Date(moment(startDate)?.startOf('day')?.format()) : ''
    const dateTo = endDate ? new Date(moment(endDate)?.endOf('day')?.format()) : ''
    const data = {
      id, start, limit, sort, order, userType, searchValue: searchValue?.trim(), status, startDate: dateFrom ? new Date(dateFrom)?.toISOString() : '', endDate: dateTo ? new Date(dateTo)?.toISOString() : '', isFullResponse, token
    }
    // dispatch action set to league-tds list
    dispatch(getLeagueTdsList(data))
  }

  function updateTdsFunc (status, id) {
    dispatch(updateTds(status, id, token))
  }

  function onExport () {
    content.current.onExport()
  }

  function onRefresh () {
    content?.current?.onRefresh()
  }

  function onFilter (e) {
    setFilter(e?.target?.value)
  }

  function handleUserType (e) {
    setUserType(e?.target?.value)
  }

  // Default Columns
  function getDefaultColumn () {
    const data = [
      { sColumnName: 'ID', bShow: true },
      { sColumnName: 'Username', bShow: true },
      { sColumnName: 'Status', bShow: true },
      { sColumnName: 'Original Amount', bShow: true },
      { sColumnName: 'Entry Fees', bShow: true },
      { sColumnName: 'Transaction Id', bShow: true },
      { sColumnName: 'TDS Amount', bShow: true },
      { sColumnName: 'Actual Amount Paid', bShow: true },
      { sColumnName: 'Percentage', bShow: true },
      { sColumnName: 'Passbook ID', bShow: true },
      { sColumnName: 'Match', bShow: true },
      { sColumnName: 'User Type', bShow: true },
      { sColumnName: 'Date & Time', bShow: true },
      { sColumnName: 'PAN Details', bShow: true },
      { sColumnName: 'Actions', bShow: true }
    ]
    setColumnNames(data)
    setColumns(data)
  }

  const changeColumnStatus = (value, name) => {
    const newData = columns?.map((c) => {
      if (c?.sColumnName === name) {
        return ({
          ...c,
          bShow: !value
        })
      } else {
        return c
      }
    })
    setColumns(newData)
    setColumnNames(newData)
  }

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor)
  )

  function handleDragEnd (event) {
    const active = event.active
    const over = event.over
    if (active?.id !== over?.id) {
      const oldIndex = columnNames.findIndex((f) => f?.sColumnName === active?.id)
      const newIndex = columnNames.findIndex((f) => f?.sColumnName === over?.id)
      const columnsData = arrayMove(columnNames, oldIndex, newIndex)
      setColumnNames(columnsData)
    }
  }

  const mutation = useMutation({
    mutationFn: sendColumnsData,
    onSuccess: (data) => {
      setColumnNames(data?.data?.data?.aColumnOrder)
      setColumns(data?.data?.data?.aColumnOrder)
      setIsManageColumn(false)
    }
  })

  function handleSubmit () {
    mutation.mutate({ sModuleName: 'TDS-MANAGEMENT', aColumnOrder: columnNames })
  }

  const { data: columnData } = useQuery({
    queryKey: ['TDS-MANAGEMENT'],
    queryFn: () => getDynamicColumns('TDS-MANAGEMENT'),
    select: (data) => data?.data?.data
  })

  useEffect(() => {
    if (columnData) {
      setColumnNames(columnData?.aColumnOrder)
      setColumns(columnData?.aColumnOrder)
    } else getDefaultColumn()
  }, [columnData])

  return (
    <>
      <main className='main-content'>
        <section className='management-section common-box'>
          <UsersListMainHeader
            heading='TDS Management'
            idLeagueLog={id}
            isLeagueToTds={leagueToTds}
            leagueToTdsId={leagueToTdsId}
            leagueToTdsLeague={leagueToTdsLeague}
            leagueToTdsMatch={leagueToTdsMatch}
            list={tdsList}
            onExport={onExport}
            onRefresh={onRefresh}
            refresh = 'Refresh TDS Data'
          />
          <div className= {tdsList?.length === 0 ? 'without-pagination' : 'setting-component'}>
            <UserListHeader
              dateRange={dateRange}
              endDate={endDate}
              filter={filter}
              handleSearch={onHandleSearch}
              handleUserType={handleUserType}
              heading='TDS Management'
              idLeagueLog={id}
              isLeagueToTds={leagueToTds}
              leagueToTdsId={leagueToTdsId}
              leagueToTdsLeague={leagueToTdsLeague}
              leagueToTdsMatch={leagueToTdsMatch}
              list={tdsList}
              onFilter={onFilter}
              search={search}
              setDateRange={setDateRange}
              startDate={startDate}
              userType={userType}
              tdsManagement
              setIsManageColumn={setIsManageColumn}
            />
            <TDSManagement
              {...props}
              ref={content}
              List={tdsList}
              endDate={endDate}
              flag={initialFlag}
              getLeagueTds={getLeagueTds}
              getLeagueTdsCount={getLeagueTdsCount}
              getList={getList}
              getTDSTotalCountFunc={getTDSTotalCountFunc}
              isLeagueLog={id}
              search={search}
              startDate={startDate}
              updateTdsFunc={updateTdsFunc}
              columnNames={columnNames}
            />
          </div>
        </section>
      </main>

      <Modal className="modal-league-analytics" isOpen={isManageColumn} toggle={() => setIsManageColumn(!isManageColumn)} >
        <ModalHeader toggle={() => setIsManageColumn(!isManageColumn)}>
          Manage Your Columns
        </ModalHeader>
        <ModalBody>
          <Row className='p-4'>
            <Col lg={6}>
              <div className="columns-container">
                <p className='arrange'>Columns</p>
                <div className="columns">
                  {columns?.map((c, i) => (
                    <div
                      key={i}
                      className={`column-item ${c.bShow ? 'selected' : ''}`}
                      onClick={() => changeColumnStatus(c.bShow, c.sColumnName)}
                    >
                      {c.sColumnName}
                    </div>
                  ))}
                </div>
              </div>
            </Col>

            <Col lg={6}>
              <p className='arrange'>Arrange Columns</p>
              <p className='arrange-1'> Set the viewing order sequence </p>
              <DndContext sensors={sensors}
                onDragEnd={handleDragEnd}
                collisionDetection={closestCorners}
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
              >
                <SortableContext items={columns?.map((c) => c?.sColumnName)} strategy={verticalListSortingStrategy}>
                  {columnNames?.map((c, i) => {
                    if (c?.bShow) {
                      return (
                        <SortableColumn data={c} key={i} />
                      )
                    }
                    return null
                  })}
                </SortableContext>
              </DndContext>
            </Col >

          </Row>
          <div className='d-flex justify-content-center align-items-center m-4'>
            <button className='column-btn' onClick={getDefaultColumn}>Reset To Default</button>
            <button className='column-btn ms-2' onClick={handleSubmit}>Save</button>
          </div>
        </ModalBody>
      </Modal>
    </>

  )
}

TDS.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object
}

export default TDS
