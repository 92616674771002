import axios from '../../axios'
const getThreadMessages = async (threadId,pageParam,limit, isFullResponse) => {
    let url = `/ot/admin/message/list/v1?iThreadId=${threadId}&order=desc&start=${pageParam}`
    if (limit) {
        url += `&limit=${limit}` 
    }
    if (isFullResponse) {
        url += `&isFullResponse=${isFullResponse}`
    }
    const data = await axios.get(url)
    return data
}

export default getThreadMessages
