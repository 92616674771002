import React, { useState } from 'react'
import { Row, Col, Input } from 'reactstrap'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
function DepositDetails ({ dashBoardData }) {
  const [dayFilter, setDayFilter] = useState('month')
  const navigate = useNavigate()

  function onFiltering (event) {
    setDayFilter(event.target.value)
  }
  const handleOnClickEvent = (tab) =>{
    const currentDate = new Date();
    let firstDay=''
    let lastDay=''
    if(dayFilter==='month'){
      firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      firstDay.setHours(0, 0, 0, 0);
      lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      lastDay.setHours(23, 59, 59, 999);
    }else if(dayFilter==='week'){
      const dayOfWeek = currentDate.getDay(); 
      firstDay = new Date(currentDate);
      firstDay.setDate(currentDate.getDate() - dayOfWeek); 
      firstDay.setHours(0, 0, 0, 0); 
      lastDay = new Date(currentDate);
      lastDay.setDate(currentDate.getDate() + (6 - dayOfWeek)); 
      lastDay.setHours(23, 59, 59, 999); 
    }else{
      firstDay = new Date(currentDate);
      firstDay.setHours(0, 0, 0, 0);
      lastDay = new Date(currentDate);
      lastDay.setHours(23, 59, 59, 999);
    }
    if(tab==='WITHDRAW'){
    navigate(`/transaction/withdraw-management?datefrom=${firstDay}&dateto=${lastDay}`)
    }else if(tab==='DEPOSIT')
    navigate(`/transaction/deposit-management?datefrom=${firstDay}&dateto=${lastDay}`)
  }
  return (
    <>
      <div className='dashboard-deposit'>
        <div className='deposit-heading'>
          <h3 className='mb-0 '>Deposits Details</h3>
          <Input
            className='day-filter'
            id="dayFilter"
            name="dayFilter"
            onChange={(event) => onFiltering(event)}
            type="select"
            value={dayFilter}
          >
            <option value="today">Today</option>
            <option value="week">Week</option>
            <option value="month">Month</option>
          </Input>
        </div>
        <Row className='dashboard-row'>
          <Col className='deposit-col' md={6} xl={3}>
            <div className='common-box-dashboard'>
              <div className='common-box-dashboard-div'>
                <p className='text-label-deposit'>
                  {' '}
                  <span> Total Deposits </span>
                </p>
              <button className='w-100 ' onClick={ ()=>handleOnClickEvent('DEPOSIT')} style={{border:"none",textAlign:'left',background:'transparent'}}>
                <h2 className='dashboard-heading mb-0'>
                  {' '}
                  {(dayFilter === 'today' ? dashBoardData?.oDeposit?.nToday.toString().includes('.') ? dashBoardData?.oDeposit?.nToday.toFixed(2) : dashBoardData?.oDeposit?.nToday : dayFilter === 'week' ? dashBoardData?.oDeposit?.nWeek.toString().includes('.') ? dashBoardData?.oDeposit?.nWeek.toFixed(2) : dashBoardData?.oDeposit?.nWeek : dashBoardData?.oDeposit?.nMonth.toString().includes('.') ? dashBoardData?.oDeposit?.nMonth.toFixed(2) : dashBoardData?.oDeposit?.nMonth) || 0}
                  {' '}
                </h2>
                </button>
              </div>
            </div>
          </Col>
          <Col className='deposit-col' md={6} xl={3}>
            <div className='common-box-dashboard'>
              <div className='common-box-dashboard-div'>
                <p className='text-label-deposit'>
                  {' '}
                  <span> Total Withdraw </span>
                </p>
              <button className='w-100 ' onClick={ ()=>handleOnClickEvent('WITHDRAW')} style={{border:"none",textAlign:'left',background:'transparent'}}>
                <h2 className='dashboard-heading mb-0'>
                  {' '}
                  {(dayFilter === 'today' ? dashBoardData?.oWithdraw?.nToday.toString().includes('.') ? dashBoardData?.oWithdraw?.nToday.toFixed(2) : dashBoardData?.oWithdraw?.nToday : dayFilter === 'week' ? dashBoardData?.oWithdraw?.nWeek.toString().includes('.') ? dashBoardData?.oWithdraw?.nWeek.toFixed(2) : dashBoardData?.oWithdraw?.nWeek : dashBoardData?.oWithdraw?.nMonth.toString().includes('.') ? dashBoardData?.oWithdraw?.nMonth.toFixed(2) : dashBoardData?.oWithdraw?.nMonth) || 0}
                  {' '}
                </h2>
                </button>
              </div>
            </div>
          </Col>
          <Col className='deposit-col' md={6} xl={3}>
            <div className='common-box-dashboard'>
              <div className='common-box-dashboard-div'>
                <p className='text-label-deposit'>
                  {' '}
                  <span> Total Commission </span>
                </p>
              <button className='w-100 ' onClick={ ()=>handleOnClickEvent('COMMISSION')} style={{border:"none",textAlign:'left',background:'transparent'}}>
                <h2 className='dashboard-heading mb-0'>
                  {' '}
                  {(dayFilter === 'today' ? dashBoardData?.oAdminCommission?.nToday.toString().includes('.') ? dashBoardData?.oAdminCommission?.nToday.toFixed(2) : dashBoardData?.oAdminCommission?.nToday : dayFilter === 'week' ? dashBoardData?.oAdminCommission?.nWeek.toString().includes('.') ? dashBoardData?.oAdminCommission?.nWeek.toFixed(2) : dashBoardData?.oAdminCommission?.nWeek : dashBoardData?.oAdminCommission?.nMonth.toString().includes('.') ? dashBoardData?.oAdminCommission?.nMonth.toFixed(2) : dashBoardData?.oAdminCommission?.nMonth) || 0}
                  {' '}
                </h2>
                </button>
              </div>
            </div>
          </Col>
          <Col className='deposit-col' md={6} xl={3}>
            <div className='common-box-dashboard'>
              <div className='common-box-dashboard-div'>
                <p className='text-label-deposit'>
                  {' '}
                  <span> Total Fees </span>
                </p>
              <button className='w-100 ' onClick={ ()=>handleOnClickEvent('FEES')} style={{border:"none",textAlign:'left',background:'transparent'}}>
                <h2 className='dashboard-heading mb-0'>
                  {' '}
                  {(dayFilter === 'today' ? dashBoardData?.oAdminPlatformFees?.nToday.toString().includes('.') ? dashBoardData?.oAdminPlatformFees?.nToday.toFixed(2) : dashBoardData?.oAdminPlatformFees?.nToday : dayFilter === 'week' ? dashBoardData?.oAdminPlatformFees?.nWeek.toString().includes('.') ? dashBoardData?.oAdminPlatformFees?.nWeek.toFixed(2) : dashBoardData?.oAdminPlatformFees?.nWeek : dashBoardData?.oAdminPlatformFees?.nMonth.toString().includes('.') ? dashBoardData?.oAdminPlatformFees?.nMonth.toFixed(2) : dashBoardData?.oAdminPlatformFees?.nMonth) || 0}
                  {' '}
                </h2>
                </button>
              </div>
            </div>
          </Col>
          {/* <Col className='free-contest-col' md={6} xl={3}>
            <div className='common-box-dashboard'>
              <div className='common-box-dashboard-div'>
                <p className='text-label-free-content'>
                  {' '}
                  <span> Free Contest </span>
                </p>
                <h2 className='dashboard-heading mb-0'>
                  {' '}
                  {dayFilter === 'today' ? dashBoardData?.oContest?.oFree?.nToday : dayFilter === 'week' ? dashBoardData?.oContest?.oFree?.nWeek : dashBoardData?.oContest?.oFree?.nMonth}
                  {' '}
                </h2>
              </div>
            </div>
          </Col>
          <Col className='paid-contest-col' md={6} xl={3}>
            <div className='common-box-dashboard '>
              <div className='common-box-dashboard-div'>
                <p className='text-label-paid-content'>
                  {' '}
                  <span> Paid Contest </span>
                </p>
                <h2 className='dashboard-heading mb-0'>
                  {' '}
                  {dayFilter === 'today' ? dashBoardData?.oContest?.oPaid?.nToday : dayFilter === 'week' ? dashBoardData?.oContest?.oPaid?.nWeek : dashBoardData?.oContest?.oPaid?.nMonth}
                  {' '}
                </h2>
              </div>
            </div>
          </Col> */}
        </Row>
      </div>
    </>
  )
}

DepositDetails.propTypes = {
  dashBoardData: PropTypes.object
}
export default DepositDetails
