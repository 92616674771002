import React, { useState, useEffect, useRef, Fragment, forwardRef, useImperativeHandle } from 'react'
import { FormGroup, Label, Input,  Row, Col, Card, CardTitle, UncontrolledTooltip, Collapse } from 'reactstrap'
import { useDispatch, useSelector, connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import caretIcon from '../../../../assets/images/caret-top.svg'
import caretBottom from '../../../../assets/images/caret-bottom.svg'
import infoIcon from '../../../../assets/images/info-icon.svg'

import Loading from '../../../../components/Loading'
import AlertMessage from '../../../../components/AlertMessage'
import RequiredField from '../../../../components/RequiredField'

import { modalMessageFunc, verifyLength } from '../../../../helpers/helper'
import { getYStatusPermissionList } from '../../../../actions/permission'

const AddRole = forwardRef((props, ref) => {
  const {
    addRoleFunc, updateRoleFunc, roleDetails, setIsEdit, name, setName
  } = props
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [roleStatus, setRoleStatus] = useState('Y')
  const [ErrName, setErrName] = useState('')
  const [isCreate, setIsCreate] = useState(true)
  const [message, setMessage] = useState('')
  const [close, setClose] = useState(false)
  const [status, setStatus] = useState(false)
  const [roleId, setRoleId] = useState('')
  const [permissions, setPermissions] = useState({})
  const [permissionKey, setPermissionKey] = useState('')
  const [permissionOpen, setPermissionOpen] = useState('')
  const [searchInput, setSearchInput] = useState('')

  const permissionStatusList = useSelector(state => state?.permission?.permissionStatusList)
  const token = useSelector(state => state?.auth?.token)
  const resStatus = useSelector(state => state?.role?.resStatus)
  const resMessage = useSelector(state => state?.role?.resMessage)
  const adminPermission = useSelector(state => state?.auth?.adminPermission)
  const previousProps = useRef({
    resStatus, resMessage, roleDetails
  })?.current
  const [modalMessage, setModalMessage] = useState(false) 
  useEffect(() => {
    dispatch(getYStatusPermissionList(token))
    if (id) {
      setRoleId(id)
      setIsCreate(false)
      setLoading(true)
    } else {
      setIsEdit(true)
    }
  }, [])

  const togglePermission = (e, module) => {
    if (permissionKey === module) {
      setPermissionKey(0)
    } else {
      setPermissionKey(module)
      setPermissionOpen(true)
    }
  }

  // set timeout to remove pop up success/error message after given interval
  useEffect(() => {
    modalMessageFunc(modalMessage, setModalMessage, setClose)
  }, [modalMessage])

  // to handle response
  useEffect(() => {
    if (previousProps?.resMessage !== resMessage) {
      if (resMessage) {
        setMessage(resMessage)
        setStatus(resStatus)
        if (resStatus && isCreate) {
          navigate('/admin-management/roles', { state: { message: resMessage } })
        } else {
          if (resStatus) {
            setIsEdit(false)
          }
          setModalMessage(true)
        }
        setLoading(false)
      }
    }
    return () => {
      previousProps.resMessage = resMessage
    }
  }, [resStatus, resMessage])

  //  set roleDetails
  useEffect(() => {
    if (previousProps?.roleDetails !== roleDetails) {
      if (roleDetails) {
        setName(roleDetails?.sName)
        setRoleStatus(roleDetails?.eStatus)
        const permissionArr = []
        if (roleDetails?.aPermissions?.length > 0) {
          roleDetails?.aPermissions?.forEach(permission => {
            const obj = {
              sKey: permission?.sKey,
              eType: permission?.eType,
              sModuleName: permission?.sModuleName
            }
            const title = permission?.sModuleName || ''
            permissionArr[title] = permissionArr[title] || []
            permissionArr[title].push(obj)
          })
          setPermissions(permissionArr)
        }
        setLoading(false)
      }
    }
    return () => {
      previousProps.roleDetails = roleDetails
    }
  }, [roleDetails])

  //  set permission status list
  useEffect(() => {
    if (permissionStatusList) {
      const permissions = []
      if (permissionStatusList?.length > 0 && isCreate) {
        permissionStatusList?.forEach(permission => {
          const title = permission?.sModuleName || ''
          permissions[title] = permissions[title] || []
          const obj = {
            sKey: permission?.sKey,
            eType: 'N',
            sModuleName: permission?.sModuleName
          }
          permissions[title].push(obj)
        })
        setPermissions(permissions)
      }
    }
    return () => {
      previousProps.permissionStatusList = permissionStatusList
    }
  }, [permissionStatusList])

  // for change permission
  function onChangePermission (event, ID, type) {
    const allPermissionArr = { ...permissions }
    const arr = [...allPermissionArr[type]]
    const index = permissions[type]?.findIndex((data) => data?.sKey === ID)
    const newEType = event.target.value || 'N'
    arr[index] = { ...arr[index], eType: newEType }
    allPermissionArr[type] = [...arr]
    setPermissions({ ...allPermissionArr })
  }

  // Function to filter permissions based on search input
  const searchPermissions = (permissions) => {
    return Object?.entries(permissions)?.reduce((search, [key, value]) => {
      const searchPermissionValue = value?.filter(permission =>
        permission?.sKey?.toLowerCase()?.includes(searchInput?.toLowerCase())
      )
      if (searchPermissionValue?.length > 0) {
        search[key] = searchPermissionValue
      }
      return search
    }, {})
  }

  // for handle onChange event
  function handleChange (event, type) {
    switch (type) {
      case 'Name':
        if (verifyLength(event?.target?.value, 1)) {
          setErrName('')
        } else {
          setErrName('Required field')
        }
        setName(event?.target?.value)
        break
      case 'Status':
        setRoleStatus(event?.target?.value)
        break
      default:
        break
    }
  }

  // for validate the field and dispatch action
  function onSubmit (e) {
    const permissionsArr = Object.entries(permissions || {})
      .flatMap(([key, value]) =>
        value.map(data => ({
          sKey: data?.sKey,
          eType: data?.eType,
          sModuleName: data?.sModuleName
        }))
      )
    const verify = name && permissionsArr?.length > 0
    if (verify) {
      if (isCreate) {
        addRoleFunc(name, permissionsArr, roleStatus)
      } else {
        updateRoleFunc(name, permissionsArr, roleStatus, roleId)
      }
      setLoading(true)
    } else {
      if (!verifyLength(name, 1)) {
        setErrName('Required field')
      }
    }
  }

  useImperativeHandle(ref, () => ({
    onSubmit
  }))

  return (
    <div>
      <main className="main-content">
        <AlertMessage
          close={close}
          message={message}
          modalMessage={modalMessage}
          status={status}
        />

        {loading && <Loading />}
        <section className="common-box-subadmin-details">
          <Fragment>
            <div className="common-box-subadmin">
              <Row>
                <Col className='p-0' md={12}>
                  <FormGroup className='form-group'>
                    <Label className='lable-league' for="Name">
                      Role
                      <RequiredField/>
                    </Label>
                    <Input disabled={adminPermission?.ADMIN_ROLE === 'R'} id="Name" onChange={event => handleChange(event, 'Name')} placeholder="Enter Name" type="text" value={name} />
                    <p className="error-text">{ErrName}</p>
                  </FormGroup>
                </Col>
                <Col className='match-details-radio p-0' md={12}>
                  <FormGroup className='radio-div mb-0 form-group'>
                    <Label className='lable-league' for="status">Status</Label>
                    <div className="d-flex inline-input mt-2 ">
                      <div className="custom-radio custom-control">
                        <Input
                          checked={roleStatus === 'Y'}
                          disabled={adminPermission?.ADMIN_ROLE === 'R'}
                          id="contentRadio1"
                          name="contentRadio"
                          onChange={event => handleChange(event, 'Status')}
                          type="radio"
                          defaultValue="Y"
                          className='custom-control-input me-2'
                        />
                        <Label for="contentRadio1">Active</Label>
                      </div>
                      <div className="custom-radio custom-control">
                        <Input
                          checked={roleStatus !== 'Y'}
                          disabled={adminPermission?.ADMIN_ROLE === 'R'}
                          id="contentRadio2"
                          name="contentRadio"
                          onChange={event => handleChange(event, 'Status')}
                          type="radio"
                          value="N"
                          className='custom-control-input me-2'
                        />
                        <Label for="contentRadio2">In Active</Label>
                      </div>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Fragment>

          <Fragment>
            <div className="common-box-subadmin p-0">
              <div className='subadmin-details'>
                <div className="common-item">
                  <div className='search d-flex justify-content-between align-items-center'>
                    <h2 className='common-box-header-main p-4'>Permissions</h2>
                    <FormGroup className='me-4 form-group'>
                      <Input className="search-box"
                        type="search"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        placeholder="Search permission"
                      />
                    </FormGroup>
                  </div>
                  {Object.entries(searchPermissions(permissions)).map(([key, value], index) => {
                    return (
                      <>
                        <div className='sub-heading px-4' onClick={(e) => togglePermission(e, key)} key={key}>
                          <h3 className='common-box-header-second '>
                            {key}
                            {' '}
                            Permissions
                            <img className='custom-info' alt='custom-info' height='20' id={key} src={infoIcon} width='20' />
                            <UncontrolledTooltip className="bg-default-s" delay={0} placement="right" target={key}>
                              <p className='p-head'>
                                {key}
                                {' '}
                                Permissions
                              </p>
                              <p className='p-body'>
                                To access
                                {' '}
                                {key}
                                {' '}
                                tab need to give permissions of
                                {' '}
                                {value && value?.length > 0 && value?.map(data => data?.sKey).join(',  ').toString()}
                              </p>
                            </UncontrolledTooltip>
                          </h3>
                          <span className='carer-Icons'>
                            <img alt="caret-icon" src={permissionKey === key && permissionOpen ? caretIcon : caretBottom} />
                          </span>
                        </div>
                        <Collapse isOpen={permissionKey === key && permissionOpen}>
                          <Row className='sub-admin-row p-4'>
                            {value?.map(data => {
                              return (
                                <Col key={data?.sKey} className='subadmin-switch' md={12} xl={6}>
                                  <Card body className='mt-2 card-body-subadmin'>
                                    <CardTitle className='card-title-subadmin' tag="h5">{data?.sKey ? data?.sKey : ''}</CardTitle>
                                    <div className="d-flex inline-input">
                                      <div className="d-flex inline-input">
                                        {['N', 'R', 'W'].map((type) => {
                                          return <div className='custom-radio custom-control'> <Input
                                            key={type}
                                            checked={data?.eType === type}
                                            disabled={adminPermission?.ADMIN_ROLE === 'R'}
                                            id={data?.sKey + type}
                                            label={type === 'N' ? 'None' : type === 'R' ? 'Read' : 'Write'}
                                            name={data?.sKey}
                                            onChange={(event) => onChangePermission(event, data?.sKey, key)}
                                            type="radio"
                                            value={type}
                                            className='custom-control-input me-2'
                                          /><Label className='custom-control-label' for="roleStatus1">{type === 'N' ? 'None' : type === 'R' ? 'Read' : 'Write'}</Label></div>

                                        } )}
                                      </div>
                                    </div>
                                  </Card>
                                </Col>
                              )
                            })}
                          </Row>
                        </Collapse>
                      </>
                    )
                  })}
                </div>
              </div>
            </div>
          </Fragment>
        </section>
      </main>
    </div>
  )
})

AddRole.propTypes = {
  addRoleFunc: PropTypes.func,
  updateRoleFunc: PropTypes.func,
  roleDetails: PropTypes.object,
  setIsEdit: PropTypes.func,
  name: PropTypes.string,
  setName: PropTypes.func
}

AddRole.displayName = AddRole
export default connect(null, null, null, { forwardRef: true })(AddRole)
