import axios from '../axios'
import { catchFunc } from '../helpers/helper'
import { ADD_BANNER, BANNER_DETAILS, BANNER_LIST, BANNER_STATISTICS, CLEAR_BANNER_MESSAGE, DELETE_BANNER, UPDATE_BANNER } from './constants'

const errMsg = 'Server is unavailable.'

export const getBannerList = (start, limit, sort, order, search, token) => async (dispatch) => {
  await axios.get(`/statics/admin/banner/list/v1?start=${start}&limit=${limit}&sort=${sort}&order=${order}&search=${search}`).then((response) => {
    dispatch({
      type: BANNER_LIST,
      payload: {
        data: response.data.data[0] ? response.data.data[0] : {},
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: BANNER_LIST,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const addBanner = (addBannerData) => async (dispatch) => {
  const { place, Link, bannerType, Description, bannerStatus, screen, bannerImage, sportsType, Match, League, position, sliderSubCategory, sliderCategory, sliderEvent } = addBannerData
  dispatch({ type: CLEAR_BANNER_MESSAGE })
  try {
    const response = await axios.post('/statics/admin/banner/pre-signed-url/v1', { sFileName: bannerImage.file.name, sContentType: bannerImage.file.type, sportsType })
    const url = response.data.data.sUrl
    const sImage = response.data.data.sPath
    await axios.put(url, bannerImage.file, { headers: { 'Content-Type': bannerImage.file.type, noAuth: true } })
    let data = {
      ePlace: place,
      sLink: Link,
      eType: bannerType,
      sDescription: Description,
      eStatus: bannerStatus,
      eScreen: screen,
      sImage,
      eCategory: sportsType,
      iMatchId: Match,
      iMatchLeagueId: League,
      nPosition: position,
    }
    if (screen === 'C') {
      data.iCategoryId = sliderCategory
      if(sliderSubCategory?.value){
        data.iSubCategoryId = sliderSubCategory?.value
      }
      if(sliderEvent?.value){
        data.iEventId = sliderEvent?.value
      }
    }

    await axios.post('/statics/admin/banner/add/v1', data ).then((response2) => {
      dispatch({
        type: ADD_BANNER,
        payload: {
          resMessage: response2.data.message,
          resStatus: true
        }
      })
    })
  } catch (error) {
    dispatch({
      type: ADD_BANNER,
      payload: {
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  }
}

export const getBannerDetails = ({ Id, sportsType }) => async (dispatch) => {
  await axios.get(`/statics/admin/banner/${Id}/v1`, { params: { sportsType } }).then((response) => {
    dispatch({
      type: BANNER_DETAILS,
      payload: {
        data: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: BANNER_DETAILS,
      payload: {
        data: {},
        resStatus: false
      }
    })
  })
}

export const updateBanner = (updateBannerData) => async (dispatch) => {
  const { place, bannerId, Link, bannerType, Description, bannerStatus, screen, bannerImage, sportsType, Match, League, position, sliderSubCategory, sliderCategory, sliderEvent } = updateBannerData
  dispatch({ type: CLEAR_BANNER_MESSAGE })
  try {
    if (bannerImage && bannerImage.file) {
      const response = await axios.post('/statics/admin/banner/pre-signed-url/v1', { sFileName: bannerImage.file.name, sContentType: bannerImage.file.type, sportsType })
      const url = response.data.data.sUrl
      const sImage = response.data.data.sPath
      await axios.put(url, bannerImage.file, { headers: { 'Content-Type': bannerImage.file.type, noAuth: true } })
      let data = {
        ePlace: place,
        sLink: Link,
        eType: bannerType,
        sDescription: Description,
        eStatus: bannerStatus,
        eScreen: screen,
        sImage,
        eCategory: sportsType,
        iMatchId: Match,
        iMatchLeagueId: League,
        nPosition: position,
      }
      // if(screen === 'SUB'){
      //   data.iSubCategoryId = eventSubCategory?.value
      // }else if(screen === 'C'){
      //   data.iCategoryId = categoryId
      // }else{
      //   data.iEventId = sliderEvent?.value
      // }
      if (screen === 'C') {
        data.iCategoryId = sliderCategory
        if(sliderSubCategory?.value){
          data.iSubCategoryId = sliderSubCategory?.value
        }
        if(sliderEvent?.value){
          data.iEventId = sliderEvent?.value
        }
      }
      await axios.put(`/statics/admin/banner/${bannerId}/v1`, data).then((response2) => {
        dispatch({
          type: UPDATE_BANNER,
          payload: {
            resMessage: response2.data.message,
            resStatus: true
          }
        })
      })
    } else {
      let data = {
        ePlace: place,
        sLink: Link,
        eType: bannerType,
        sDescription: Description,
        eStatus: bannerStatus,
        eScreen: screen,
        sImage:bannerImage,
        eCategory: sportsType,
        iMatchId: Match,
        iMatchLeagueId: League,
        nPosition: position,
      }
      // if(screen === 'SUB'){
      //   data.iSubCategoryId = eventSubCategory?.value
      // }else if(screen === 'C'){
      //   data.iCategoryId = categoryId
      // }else{
      //   data.iEventId = sliderEvent?.value
      // }
      if (screen === 'C') {
        data.iCategoryId = sliderCategory
        if(sliderSubCategory?.value){
          data.iSubCategoryId = sliderSubCategory?.value
        }
        if(sliderEvent?.value){
          data.iEventId = sliderEvent?.value
        }
      }
      await axios.put(`/statics/admin/banner/${bannerId}/v1`, data ).then((response) => {
        dispatch({
          type: UPDATE_BANNER,
          payload: {
            resMessage: response.data.message,
            resStatus: true
          }
        })
      }) 
    }
  } catch (error) {
    dispatch(catchFunc(UPDATE_BANNER, error))
  }
}

export const deleteBanner = ({ Id, sportsType }) => async (dispatch) => {
  dispatch({ type: CLEAR_BANNER_MESSAGE })
  await axios.delete(`/statics/admin/banner/${Id}/v1`, { params: { sportsType } }).then((response) => {
    dispatch({
      type: DELETE_BANNER,
      payload: {
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: DELETE_BANNER,
      payload: {
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const getBannerStatisticsList = (data) => async (dispatch) => {
  const { start, limit, startDate, endDate, bannerId, platform } = data
  await axios.get(`/statics/admin/banner/stats/${bannerId}/v1?start=${start}&limit=${limit}&datefrom=${startDate}&dateto=${endDate}&ePlatform=${platform}`).then((response) => {
    dispatch({
      type: BANNER_STATISTICS,
      payload: {
        data: response.data.data ? response.data.data : {},
        resStatus: true
      }
    })
  }).catch((err) => {
    dispatch({
      type: BANNER_STATISTICS,
      payload: {
        data: [],
        resStatus: false,
        resMessage: err?.response?.data?.message
      }
    })
  })
}
