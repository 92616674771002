import axios from '../axios'
import { ADD_MATCH, CLEAR_MATCH_MESSAGE, FETCH_MATCH, GENERATE_PDF, LOAD_LIVE_LEADER_BOARD, MATCH_DETAILS, MATCH_LIST, MATCH_DATA_REFRESH, MERGE_MATCH, POST_PREVIEW, PRIZE_DISTRIBUTION, UPCOMING_MATCH_LIST, UPDATE_MATCH, WIN_PRIZE_MATCH_DISTRIBUTION, SCORE_CARD, LIVE_INNINGS, EXTRA_WIN_LIST, GENERATE_DREAM_TEAM, GET_BASE_TEAMS_LISTING, MATCH_LIST_INREVIEW, MATCH_LIST_LIVE, UPCOMING_MATCHES_TOTAL_COUNT, LIVE_MATCHES_TOTAL_COUNT, INREVIEW_MATCHES_TOTAL_COUNT, MATCHES_TOTAL_COUNT, GET_EXPERT_TEAMS_LISTING } from './constants'
const errMsg = 'Server is unavailable.'

export const getMatchList = (matchListData) => async (dispatch) => {
  const { start, limit, sort, order, search, sportsType, filter, startDate, endDate, provider, season, format, tournamentFlag } = matchListData
  const eType = 'MATCH'
  await axios.get(`/ot/admin/match/list/v1?start=${start}&limit=${limit}&sort=${sort}&order=${order}&search=${search}&sportsType=${sportsType}&filter=${filter}&datefrom=${startDate}&dateto=${endDate}&eProvider=${provider}&iSeasonId=${season}&eFormat=${format}&eType=${eType}&bInTournament=${tournamentFlag}`).then((response) => {
    dispatch({
      type: MATCH_LIST,
      payload: {
        data: response.data.data ? response.data.data : [],
        resStatus: true,
        status: filter
      }
    })
  }).catch(() => {
    dispatch({ 
      type: MATCH_LIST,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const getMatchListLive = (matchListData) => async (dispatch) => {
  const { start, limit, sort, order, search, sportsType, filter, startDate, endDate, provider, season, format } = matchListData
  const eType = 'MATCH'
  await axios.get(`/ot/admin/match/list/v1?start=${start}&limit=${limit}&sort=${sort}&order=${order}&search=${search}&sportsType=${sportsType}&filter=L&datefrom=${startDate}&dateto=${endDate}&eProvider=${provider}&iSeasonId=${season}&eFormat=${format}&eType=${eType}`).then((response) => {
    dispatch({
      type: MATCH_LIST_LIVE,
      payload: {
        data: response.data.data[0] ? response.data.data[0] : [],
        resStatus: true,
        status: filter
      }
    })
  }).catch(() => {
    dispatch({
      type: MATCH_LIST_LIVE,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}
export const getMatchListInReview = (matchListData) => async (dispatch) => {
  const { start, limit, sort, order, search, sportsType, filter, startDate, endDate, provider, season, format } = matchListData
  const eType = 'MATCH'
  await axios.get(`/ot/admin/match/list/v1?start=${start}&limit=${limit}&sort=${sort}&order=${order}&search=${search}&sportsType=${sportsType}&filter=I&datefrom=${startDate}&dateto=${endDate}&eProvider=${provider}&iSeasonId=${season}&eFormat=${format}&eType=${eType}`).then((response) => {
    dispatch({
      type: MATCH_LIST_INREVIEW,
      payload: {
        data: response.data.data[0] ? response.data.data[0] : [],
        resStatus: true,
        status: filter
      }
    })
  }).catch(() => {
    dispatch({
      type: MATCH_LIST_INREVIEW,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}
export const fetchMatch = (date, provider, token, sportsType) => async (dispatch) => {
  dispatch({ type: CLEAR_MATCH_MESSAGE })
  await axios.post(`/gaming/admin/match/${sportsType}/v1`, {
    dDate: date,
    eProvider: provider
  }).then((response) => {
    dispatch({
      type: FETCH_MATCH,
      payload: {
        data: response.data.data,
        resStatus: true,
        resMessage: response.data.message
      }
    })
  }).catch((error) => {
    dispatch({
      type: FETCH_MATCH,
      payload: {
        data: {},
        resStatus: false,
        resMessage: error.response ? error.response.data.message : errMsg
      }
    })
  })
}

export const addMatch = (addMatchData) => async (dispatch) => {
  const { Series, seasonName, seasonId, SeasonKey, MatchName, MatchFormat, StartDate, TeamAName, TeamBName, TeamAScore, TeamBScore, Venue, eCategory, matchOnTop, TossWinner, ChooseTossWinner, bDisabled, MaxTeamLimit, sSponsoredText, FantasyPostID, StreamURL, StreamType, matchKey, info, winningText, scoreCardFlag, grandLeague, dreamTeamFlag, pitchDetails, avgVenueScore } = addMatchData
  dispatch({ type: CLEAR_MATCH_MESSAGE })
  try {
    if (Series) {
      await axios.post('/gaming/admin/match/v1', {
        iSeasonId: seasonId,
        iSeriesId: Series,
        sSeasonName: seasonName,
        sSeasonKey: SeasonKey,
        sName: MatchName,
        eFormat: MatchFormat,
        dStartDate: StartDate,
        iHomeTeamId: TeamAName,
        iAwayTeamId: TeamBName,
        nHomeTeamScore: TeamAScore,
        nAwayTeamScore: TeamBScore,
        sVenue: Venue,
        eCategory: eCategory,
        iTossWinnerId: TossWinner,
        eTossWinnerAction: ChooseTossWinner,
        nMaxTeamLimit: MaxTeamLimit,
        bMatchOnTop: matchOnTop === 'Y',
        bDisabled: bDisabled === 'Y',
        sSponsoredText: sSponsoredText,
        sFantasyPost: FantasyPostID,
        sStreamUrl: StreamURL,
        eStreamType: StreamType,
        sKey: matchKey,
        sInfo: info,
        sWinning: winningText,
        iGrandLeagueId: grandLeague,
        bScorecardShow: scoreCardFlag === 'Y',
        bDreamTeam: dreamTeamFlag === 'Y',
        sPitchDetails: pitchDetails,
        nAvgVenueScore: avgVenueScore
      }).then((response) => {
        dispatch({
          type: ADD_MATCH,
          payload: {
            data: response.data.data,
            resStatus: true,
            resMessage: response.data.message
          }
        })
      })
    } else {
      await axios.post('/gaming/admin/match/v1', {
        iSeasonId: seasonId,
        sSeasonName: seasonName,
        sSeasonKey: SeasonKey,
        sName: MatchName,
        eFormat: MatchFormat,
        dStartDate: StartDate,
        iHomeTeamId: TeamAName,
        iAwayTeamId: TeamBName,
        nHomeTeamScore: TeamAScore,
        nAwayTeamScore: TeamBScore,
        sVenue: Venue,
        eCategory: eCategory,
        iTossWinnerId: TossWinner,
        eTossWinnerAction: ChooseTossWinner,
        nMaxTeamLimit: MaxTeamLimit,
        bMatchOnTop: matchOnTop === 'Y',
        bDisabled: bDisabled === 'Y',
        sSponsoredText: sSponsoredText,
        sFantasyPost: FantasyPostID,
        sStreamUrl: StreamURL,
        eStreamType: StreamType,
        sKey: matchKey,
        sInfo: info,
        sWinning: winningText,
        iGrandLeagueId: grandLeague,
        bScorecardShow: scoreCardFlag === 'Y',
        bDreamTeam: dreamTeamFlag === 'Y',
        sPitchDetails: pitchDetails,
        nAvgVenueScore: avgVenueScore
      }).then((response2) => {
        dispatch({
          type: ADD_MATCH,
          payload: {
            data: response2.data.data,
            resStatus: true,
            resMessage: response2.data.message
          }
        })
      })
    }
  } catch (error) {
    dispatch({
      type: ADD_MATCH,
      payload: {
        data: {},
        resStatus: false,
        resMessage: error.response ? error.response.data.message || error.response.data.errors[0].msg + ' of ' + error.response.data.errors[0].param : errMsg
      }
    })
  }
}

export const updateMatch = (updateMatchData) => async (dispatch) => {
  const { Series, seasonName, seasonId, SeasonKey, MatchName, MatchFormat, StartDate, TeamAName, TeamBName, TeamAScore, TeamBScore, Venue, MatchStatus, TossWinner, ChooseTossWinner, matchOnTop, eCategory, ID, bDisabled, MaxTeamLimit, sSponsoredText, FantasyPostID, StreamURL, StreamType, matchKey, info, winningText, scoreCardFlag, grandLeague, dreamTeamFlag, pitchDetails, avgVenueScore } = updateMatchData
  dispatch({ type: CLEAR_MATCH_MESSAGE })
  try {
    if (Series) {
      await axios.put(`/gaming/admin/match/${ID}/v1`, {
        iSeasonId: seasonId,
        sSeasonName: seasonName,
        iSeriesId: Series,
        sSeasonKey: SeasonKey,
        sName: MatchName,
        eFormat: MatchFormat,
        dStartDate: StartDate,
        iHomeTeamId: TeamAName,
        iAwayTeamId: TeamBName,
        nHomeTeamScore: TeamAScore,
        nAwayTeamScore: TeamBScore,
        sVenue: Venue,
        eStatus: MatchStatus,
        eCategory: eCategory,
        iTossWinnerId: TossWinner,
        eTossWinnerAction: ChooseTossWinner,
        nMaxTeamLimit: MaxTeamLimit,
        bMatchOnTop: matchOnTop === 'Y',
        bDisabled: bDisabled === 'Y',
        sSponsoredText,
        sFantasyPost: FantasyPostID,
        sStreamUrl: StreamURL,
        eStreamType: StreamType,
        sKey: matchKey,
        sInfo: info,
        sWinning: winningText,
        iGrandLeagueId: grandLeague,
        bScorecardShow: scoreCardFlag === 'Y',
        bDreamTeam: dreamTeamFlag === 'Y',
        sPitchDetails: pitchDetails,
        nAvgVenueScore: avgVenueScore
      }).then((response) => {
        dispatch({
          type: UPDATE_MATCH,
          payload: {
            resStatus: true,
            data: response.data.data,
            resMessage: response.data.message
          }
        })
      })
    } else {
      await axios.put(`/gaming/admin/match/${ID}/v1`, {
        iSeasonId: seasonId,
        sSeasonName: seasonName,
        sSeasonKey: SeasonKey,
        sName: MatchName,
        eFormat: MatchFormat,
        dStartDate: StartDate,
        iHomeTeamId: TeamAName,
        iAwayTeamId: TeamBName,
        nHomeTeamScore: TeamAScore,
        nAwayTeamScore: TeamBScore,
        sVenue: Venue,
        eStatus: MatchStatus,
        eCategory: eCategory,
        iTossWinnerId: TossWinner,
        eTossWinnerAction: ChooseTossWinner,
        nMaxTeamLimit: MaxTeamLimit,
        bMatchOnTop: matchOnTop === 'Y',
        bDisabled: bDisabled === 'Y',
        sSponsoredText,
        sFantasyPost: FantasyPostID,
        sStreamUrl: StreamURL,
        eStreamType: StreamType,
        sKey: matchKey,
        sInfo: info,
        sWinning: winningText,
        iGrandLeagueId: grandLeague,
        bScorecardShow: scoreCardFlag === 'Y',
        bDreamTeam: dreamTeamFlag === 'Y',
        sPitchDetails: pitchDetails,
        nAvgVenueScore: avgVenueScore
      }).then((response2) => {
        dispatch({
          type: UPDATE_MATCH,
          payload: {
            resStatus: true,
            data: response2.data.data,
            resMessage: response2.data.message
          }
        })
      })
    }
  } catch (error) {
    dispatch({
      type: UPDATE_MATCH,
      payload: {
        data: {},
        resStatus: false,
        resMessage: error.response ? error.response.data.message || error.response.data.errors[0].msg + ' of ' + error.response.data.errors[0].param : errMsg
      }
    })
  }
}

export const getMatchDetails = (Id, sportsType, token) => async (dispatch) => {
  dispatch({ type: CLEAR_MATCH_MESSAGE })
  await axios.get(`/gaming/admin/match/${Id}/v1?sportsType=${sportsType}`).then((response) => {
    dispatch({
      type: MATCH_DETAILS,
      payload: {
        data: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: MATCH_DETAILS,
      payload: {
        data: {},
        resStatus: false
      }
    })
  })
}

export const priceDistriBution = (iMatchId, sportsType, token) => async (dispatch) => {
  dispatch({ type: CLEAR_MATCH_MESSAGE })
  await axios.get(`/gaming/admin/user-team/price-distribution/${iMatchId}/v1?sportsType=${sportsType}`).then((response) => {
    dispatch({
      type: PRIZE_DISTRIBUTION,
      payload: {
        resMessage: response.data.message,
        resStatus: true,
        prizeFlag: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: PRIZE_DISTRIBUTION,
      payload: {
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false,
        prizeFlag: false
      }
    })
  })
}

export const winPrizeDistribution = (iMatchId, sportsType, token) => async (dispatch) => {
  dispatch({ type: CLEAR_MATCH_MESSAGE })
  await axios.get(`/gaming/admin/user-team/win-price-distribution/${iMatchId}/v1?sportsType=${sportsType}`).then((response) => {
    dispatch({
      type: WIN_PRIZE_MATCH_DISTRIBUTION,
      payload: {
        resMessage: response.data.message,
        resStatus: true,
        winFlag: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: WIN_PRIZE_MATCH_DISTRIBUTION,
      payload: {
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false,
        winFlag: false
      }
    })
  })
}

export const generatePdf = (type, id, sportsType) => async (dispatch) => {
  dispatch({ type: CLEAR_MATCH_MESSAGE })
  await axios.get(`/gaming/admin/check-fair-play/${id}/v1?sType=${type}&sportsType=${sportsType}`).then((response) => {
    dispatch({
      type: GENERATE_PDF,
      payload: {
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: GENERATE_PDF,
      payload: {
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const getUpcomingMatchList = (start, sportsType, token) => async (dispatch) => {
  await axios.get(`/gaming/admin/match/full-list/v1?start=${start}&limit=10&sportsType=${sportsType}`).then((response) => {
    dispatch({
      type: UPCOMING_MATCH_LIST,
      payload: {
        data: response.data.data[0] ? response.data.data[0] : [],
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: UPCOMING_MATCH_LIST,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const getPost = (id, token) => async (dispatch) => {
  await axios.get(`/gaming/admin/predictions/${id}/v1`).then((response) => {
    dispatch({
      type: POST_PREVIEW,
      payload: {
        data: response.data.data ? response.data.data : '',
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: POST_PREVIEW,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}
export const getMatchesTotalCount = (data) => async (dispatch) => {
  const { filter, search, startDate, endDate, provider, season, format, sportsType } = data
  const eType = 'MATCH'
  await axios.get(`/ot/admin/match/counts/v1?search=${search}&sportsType=${sportsType}&filter=${filter}&datefrom=${startDate}&dateto=${endDate}&eProvider=${provider}&iSeasonId=${season}&eFormat=${format}&eType=${eType}`).then((response) => {
    dispatch({
      type: MATCHES_TOTAL_COUNT,
      payload: {
        data: response.data.data ? response.data.data : {},
        resStatus: true,
        status: filter
      }
    })
  }).catch(() => {
    dispatch({
      type: MATCHES_TOTAL_COUNT,
      payload: {
        data: {},
        resStatus: false
      }
    })
  })
}
export const getUpcomingMatchesTotalCount = (data) => async (dispatch) => {
  const { filter, search, startDate, endDate, provider, season, format, sportsType } = data
  const eType = 'MATCH'
  await axios.get(`/ot/admin/match/counts/v1?search=${search}&sportsType=${sportsType}&filter=${filter}&datefrom=${startDate}&dateto=${endDate}&eProvider=${provider}&iSeasonId=${season}&eFormat=${format}&eType=${eType}`).then((response) => {
    dispatch({
      type: UPCOMING_MATCHES_TOTAL_COUNT,
      payload: {
        data: response.data.data ? response.data.data : {},
        resStatus: true,
        status: filter
      }
    })
  }).catch(() => {
    dispatch({
      type: UPCOMING_MATCHES_TOTAL_COUNT,
      payload: {
        data: {},
        resStatus: false
      }
    })
  })
}
export const getLiveMatchesTotalCount = (data) => async (dispatch) => {
  const { filter, search, startDate, endDate, provider, season, format, sportsType } = data
  const eType = 'MATCH'
  await axios.get(`/ot/admin/match/counts/v1?search=${search}&sportsType=${sportsType}&filter=${filter}&datefrom=${startDate}&dateto=${endDate}&eProvider=${provider}&iSeasonId=${season}&eFormat=${format}&eType=${eType}`).then((response) => {
    dispatch({
      type: LIVE_MATCHES_TOTAL_COUNT,
      payload: {
        data: response.data.data ? response.data.data : {},
        resStatus: true,
        status: filter
      }
    })
  }).catch(() => {
    dispatch({
      type: LIVE_MATCHES_TOTAL_COUNT,
      payload: {
        data: {},
        resStatus: false
      }
    })
  })
}
export const getInReviewMatchesTotalCount = (data) => async (dispatch) => {
  const { filter, search, startDate, endDate, provider, season, format, sportsType } = data
  const eType = 'MATCH'
  await axios.get(`/ot/admin/match/counts/v1?search=${search}&sportsType=${sportsType}&filter=${filter}&datefrom=${startDate}&dateto=${endDate}&eProvider=${provider}&iSeasonId=${season}&eFormat=${format}&eType=${eType}`).then((response) => {
    dispatch({
      type: INREVIEW_MATCHES_TOTAL_COUNT,
      payload: {
        data: response.data.data ? response.data.data : {},
        resStatus: true,
        status: filter
      }
    })
  }).catch(() => {
    dispatch({
      type: INREVIEW_MATCHES_TOTAL_COUNT,
      payload: {
        data: {},
        resStatus: false
      }
    })
  })
}

export const loadLiveLeaderBoard = (matchId, token) => async (dispatch) => {
  await axios.get(`/gaming/admin/cron/load-leaderboard/v1?matchId=${matchId}`).then((response) => {
    dispatch({
      type: LOAD_LIVE_LEADER_BOARD,
      payload: {
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: LOAD_LIVE_LEADER_BOARD,
      payload: {
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const mergeMatch = (data) => async (dispatch) => {
  const { oldMatchId, apiGeneratedMatchId, availablePlayers, sportsType } = data
  await axios.post('/gaming/admin/match/merge/v1', {
    id: oldMatchId,
    apiMatchId: apiGeneratedMatchId,
    aPlayers: availablePlayers,
    sportsType
  }).then((response) => {
    dispatch({
      type: MERGE_MATCH,
      payload: {
        resStatus: true,
        resMessage: response.data.message
      }
    })
  }).catch((error) => {
    dispatch({
      type: MERGE_MATCH,
      payload: {
        resStatus: false,
        resMessage: error.response ? error.response.data.message || error.response.data.errors[0].msg + ' of ' + error.response.data.errors[0].param : errMsg
      }
    })
  })
}

export const matchRefresh = (matchId, sportsType, token) => async (dispatch) => {
  await axios.post(`/gaming/admin/match/refresh/${matchId}/v1`, { sportsType }).then((response) => {
    dispatch({
      type: MATCH_DATA_REFRESH,
      payload: {
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: MATCH_DATA_REFRESH,
      payload: {
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const liveInnings = (matchId, token) => async (dispatch) => {
  await axios.get(`/gaming/admin/live-innings/${matchId}/v1`).then((response) => {
    dispatch({
      type: LIVE_INNINGS,
      payload: {
        data: response.data.data,
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: LIVE_INNINGS,
      payload: {
        data: [],
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const fullScoreCard = (matchId, token) => async (dispatch) => {
  await axios.get(`/gaming/admin/scorecard/${matchId}/v1`).then((response) => {
    dispatch({
      type: SCORE_CARD,
      payload: {
        data: response.data.data,
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: SCORE_CARD,
      payload: {
        data: [],
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const getExtraWinList = (matchId, start, limit, sportsType, token) => async (dispatch) => {
  await axios.get(`/gaming/admin/user-league/extra-win-list/${matchId}/v1?start=${start}&limit=${limit}&sportsType=${sportsType}`).then((response) => {
    dispatch({
      type: EXTRA_WIN_LIST,
      payload: {
        data: response.data.data,
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: EXTRA_WIN_LIST,
      payload: {
        data: [],
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const generateDreamTeam = (matchId, sportsType, token) => async (dispatch) => {
  await axios.post(`/gaming/admin/dream-team/${matchId}/v1`, { sportsType }).then((response) => {
    dispatch({
      type: GENERATE_DREAM_TEAM,
      payload: {
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: GENERATE_DREAM_TEAM,
      payload: {
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const getBaseTeams = (start, limit, matchId, search, sSearchBy, sportsType, token) => async (dispatch) => {
  await axios.get(`/gaming/admin/base-team/${matchId}/v1?nSkip=${start}&nLimit=${limit}&sportsType=${sportsType}&sSearch=${search}&sSearchBy=${sSearchBy}`).then((response) => {
    dispatch({
      type: GET_BASE_TEAMS_LISTING,
      payload: {
        data: response.data.data,
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: GET_BASE_TEAMS_LISTING,
      payload: {
        data: [],
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const clearMatchMsg = () => (dispatch) => {
  dispatch({ type: CLEAR_MATCH_MESSAGE })
}

export const getExpertTeamList = (data, id) => async (dispatch) => {
  // await axios.get(`/gaming/admin/expert-team/list/${id}/v1?nSkip=${data.nSkip}&nLimit=${data.nLimit}&sportsType=${data.sportsType}&sSearch=${data.sSearch}${data.sSearchBy ? `&sSearchBy=${data.sSearchBy}` : ''}`).then((response) => {
  await axios.get(`/gaming/admin/expert-team/list/${id}/v1`, {
    params: data
  }).then((response) => {
    dispatch({
      type: GET_EXPERT_TEAMS_LISTING,
      payload: {
        data: response.data.data,
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: GET_EXPERT_TEAMS_LISTING,
      payload: {
        data: [],
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}
