import React, { Fragment, useRef, useState } from "react"
import { useSelector } from "react-redux"

import MainHeading from "../../Settings/Component/MainHeading"
import Header from "../../../components/Header"
import AddUserProfileLevel from "./AddUserProfileLevel"
import { useQuery } from "@tanstack/react-query"
import getUserXPDetails from "../../../api/UserProfileManagement/getUserXPDetails"
import { useParams } from "react-router-dom"

const AddUserXP = (props) => {
  const content = useRef()
  const { id } = useParams()

  const [isCreate, setIsCreate] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [Name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [minXP, setMinXP] = useState(0)
  const [dailyWithdrawalLimit, setDailyWithdrawalLimit] = useState('')
  const [dailyWithdrawalCount, setDailyWithdrawalCount] = useState('')
  const [commission, setCommission] = useState('')
  const [commissionFeeType, setCommissionFeeType] = useState('')
  const [hexColor, setHexColor] = useState('#000000')
  const [Category, setCategory] = useState('')
  const [Title, setTitle] = useState('')
  const [priority, setPriority] = useState(1)
  const [Details, setDetails] = useState('')

  const [submitDisableButton, setSubmitDisableButton] = useState('')

  // const page = JSON.parse(localStorage?.getItem('queryParams'))
  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)
  const adminPermission = useSelector(state => state?.auth?.adminPermission)

  const { data: userXPDetails, isLoading } = useQuery({
    queryKey: ['getUserXPDetails', id],
    queryFn: () => getUserXPDetails(id),
    select: (response) => response?.data?.data,
    enabled: !!id
  })

  function onSubmit () {
    content?.current?.onSubmit()
  }
  return (
    <Fragment>
      <Header />
      <MainHeading
        AddUserProfile
        Auth={Auth}
        button={isCreate ? 'Add Profile' : !isEdit ? 'Save Changes' : 'Edit Profile'}
        cancelLink={`/user-profile-level`}
        heading={isCreate ? 'Add User Profile' : !isEdit ? 'Edit User Profile' : 'Edit User Profile'}
        onSubmit={onSubmit}
        // Slug={Slug}
        Category={Category}
        Title={Title}
        priority={priority}
        Details={Details}
        submitDisableButton={submitDisableButton}
      />
      <div className='without-pagination'>
        <AddUserProfileLevel
          {...props}
          ref={content}
          Auth={Auth}
          adminPermission={adminPermission}
          isCreate={isCreate}
          isEdit={isEdit}
          setIsCreate={setIsCreate}
          setIsEdit={setIsEdit}
          setSubmitDisableButton={setSubmitDisableButton}
          Name={Name}
          setName={setName}
          Category={Category}
          setCategory={setCategory}
          Title={Title}
          setTitle={setTitle}
          priority={priority}
          setPriority={setPriority}
          Details={Details}
          setDetails={setDetails}
          Description={description}
          setDescription={setDescription}
          minXP={minXP}
          setMinXP={setMinXP}
          dailyWithdrawalLimit={dailyWithdrawalLimit}
          setDailyWithdrawalLimit={setDailyWithdrawalLimit}
          dailyWithdrawalCount={dailyWithdrawalCount}
          setDailyWithdrawalCount={setDailyWithdrawalCount}
          commission={commission}
          setCommission={setCommission}
          commissionFeeType={commissionFeeType}
          setCommissionFeeType={setCommissionFeeType}
          setHexColor={setHexColor}
          hexColor={hexColor}
          userXPDetails={userXPDetails}
          isLoading={isLoading}
          id={id}
        />
      </div>
    </Fragment>
  )
}
export default AddUserXP