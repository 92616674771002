import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import MainHeading from '../../../Settings/Component/MainHeading'
import SellOrderForm from './SellIOrderForm'

const SellOrder = (props) => {
  const { id } = useParams()
  const content = useRef()
  const [searchParams] = useSearchParams()
  const eventID = searchParams.get('eventId')
  const [isCreate, setIsCreate] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [submitDisableButton, setSubmitDisableButton] = useState(true)
  const [place, setPlace] = useState('')
  const [link, setLink] = useState('')
  const [Description, setDescription] = useState('')
  const [bannerType, setBannerType] = useState('')
  const [screen, setScreen] = useState('')
  const [bannerImage, setbannerImage] = useState('')
  const [Match, setMatch] = useState('')
  const [position, setPosition] = useState(0)
  const [bannerStatus, setBannerStatus] = useState('N')

  // const page = JSON?.parse(localStorage?.getItem('queryParams'))
  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)
  // const adminDetails = useSelector(state => state?.auth?.adminData)
  const adminPermission = useSelector(state => state?.auth?.adminPermission)
  useEffect(() => {
    if (id) {
        setIsEdit(true)
    } else {
        setIsCreate(true)
    }
  }, [])

  function heading () {
    if (isCreate) {
      return 'Sell Order'
    }
    return isEdit ? 'Edit Order' : 'View Order Details'
  }

  function onSubmit () {
    content?.current?.onSubmit()
  }
  return (
    <Fragment>
      <MainHeading
        Auth={Auth}
        adminPermission={adminPermission}
        button={isCreate ? 'Sell Order' : 'Save Changes'}
        heading={heading()}
        onSubmit={onSubmit}
        submitDisableButton={submitDisableButton}
        addOrder
        cancelLink ={`/event-management/event-details/${eventID}`}
      />
      <div className='without-pagination'>
        <SellOrderForm
          {...props}
          ref={content}
          Auth={Auth}
          adminPermission={adminPermission}
          isCreate={isCreate}
          setIsCreate={setIsCreate}
          setIsEdit={setIsEdit}
          setSubmitDisableButton={setSubmitDisableButton}
          place={place}
          setPlace={setPlace}
          link={link}
          setLink={setLink}
          Description={Description}
          setDescription={setDescription}
          bannerType={bannerType}
          setBannerType={setBannerType}
          screen={screen}
          setScreen={setScreen}
          bannerImage={bannerImage}
          setbannerImage={setbannerImage}
          Match={Match}
          setMatch={setMatch}
          position={position}
          setPosition={setPosition}
          bannerStatus={bannerStatus}
          setBannerStatus={setBannerStatus}
        />
      </div>
    </Fragment>
  )
}

export default SellOrder
