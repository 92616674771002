import React, { useEffect } from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import infoIcon from '../../../assets/images/info-icon.svg'
import refreshIcon from '../../../assets/images/refresh-icon-1.svg'

import DataNotFound from '../../../components/DataNotFound'
import Info from '../../../helpers/info'
import { CategoryLabels, dateFormate, fixDigit } from '../../../helpers/helper'
import { updatePlayReturnReport } from '../../../actions/reports'

function PlayReturnReport (props) {
  const { dateWiseReports, isOpen, permission, playReturn, token, userType, setLoading, previousProps, setPlayReturn } = props
  const dispatch = useDispatch()

  const updatedPlayReturnData = useSelector(state => state?.reports?.updatedPlayReturnData)

  // to set updated play return data
  useEffect(() => {
    if (updatedPlayReturnData) {
      if (previousProps?.updatedPlayReturnData !== updatedPlayReturnData) {
        const categoryIndex = playReturn?.findIndex(
          (playReturnData) => playReturnData?.eCategory === updatedPlayReturnData?.eCategory
        )
        const newArray = [...playReturn]
        newArray[categoryIndex] = {
          ...newArray[categoryIndex],
          nTotalCash: updatedPlayReturnData?.nTotalCash,
          nTotalBonus: updatedPlayReturnData?.nTotalBonus,
          nTodayCash: updatedPlayReturnData?.nTodayCash,
          nTodayBonus: updatedPlayReturnData?.nTodayBonus,
          nYesterCash: updatedPlayReturnData?.nYesterCash,
          nYesterBonus: updatedPlayReturnData?.nYesterBonus,
          nWeekCash: updatedPlayReturnData?.nWeekCash,
          nWeekBonus: updatedPlayReturnData?.nWeekBonus,
          nMonthCash: updatedPlayReturnData?.nMonthCash,
          nMonthBonus: updatedPlayReturnData?.nMonthBonus,
          nYearCash: updatedPlayReturnData?.nYearCash,
          nYearBonus: updatedPlayReturnData?.nYearBonus,
          dUpdatedAt: updatedPlayReturnData?.dUpdatedAt
        }
        setPlayReturn(newArray)
      }
    }
    return () => {
      previousProps.updatedPlayReturnData = updatedPlayReturnData
    }
  }, [updatedPlayReturnData])

  // dispatch action to update the play return data
  function updatePlayReturnFunc (id, key, sportsType) {
    if (token) {
      const updatePlayReturnData = {
        id, key, sportsType, userType, token
      }
      dispatch(updatePlayReturnReport(updatePlayReturnData))
      setLoading(true)
    }
  }
  return (
    <>
      {isOpen === 'PLAY_RETURN_REPORT' && dateWiseReports?.length !== 0 && (
      <section className='user-section'>
        <div className='table-represent-two'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr align='center'>
                  <th rowSpan='2'>
                    Category
                    <img className='custom-info' id='PLAYRETURN' src={infoIcon} alt='custom-info' />
                    <UncontrolledTooltip className='bg-default-s' delay={0} placement='right' target='PLAYRETURN'>
                      <p>{Info?.playReturn}</p>
                    </UncontrolledTooltip>
                  </th>
                  <th className='report-th text-center' colSpan={userType === 'B' ? '1' : '2'}>{userType === 'B' ? 'Total (Cash)' : 'Total'}</th>
                  <th rowSpan='2'>Last Update</th>
                </tr>
                {userType !== 'B' && (
                    <tr align='center'>
                      <th className='report-th-1 text-center'>Cash</th>
                      <th className='report-th-2 text-center'>Bonus</th>
                    </tr>
                  )}
              </thead>
              <tbody>
                {dateWiseReports?.aPlayReturn && dateWiseReports?.aPlayReturn?.map((data, index) => (
                  <tr key={index} align='center'>
                    {/* <td><b>{data?.eCategory}</b></td> */}
                    <td><b>{data?.eCategory === 'C' ? CategoryLabels?.C : data?.eCategory === 'S' ? CategoryLabels?.S : data?.eCategory === 'CR' ? CategoryLabels?.CR : data?.eCategory === 'GT' ? CategoryLabels?.GT : data?.eCategory === 'IPO' ? CategoryLabels?.IPO : CategoryLabels?.N}</b></td>
                    <td>{fixDigit(data?.nTotalCash)}</td>
                    {userType !== 'B' && <td>{fixDigit(data?.nTotalBonus)}</td>}
                    <td>{dateFormate(data?.dUpdatedAt)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      )}
      {isOpen === 'PLAY_RETURN_REPORT' && dateWiseReports?.length === 0
        ? (playReturn && playReturn?.length > 0)
            ? (
              <section className='user-section'>
                <div className='table-represent-two'>
                  <div className='table-responsive'>
                    <table className='table'>
                      <thead>
                        <tr align='center'>
                          <th align='center' rowSpan='2'>Category</th>
                          <th align='center' className='report-th text-center' colSpan={userType === 'B' ? '1' : '2'}>{userType === 'B' ? 'Total (Cash)' : 'Total'} </th>
                            <th align='center' className='report-th text-center' colSpan={userType === 'B' ? '1' : '2'}>{userType === 'B' ? 'Today (Cash)' : 'Today'}</th>
                            <th align='center' className='report-th text-center' colSpan={userType === 'B' ? '1' : '2'}>{userType === 'B' ? 'Yesterday (Cash)' : 'Yesterday'}</th>
                            <th align='center' className='report-th text-center' colSpan={userType === 'B' ? '1' : '2'}>{userType === 'B' ? 'Week (Cash)' : 'Week'}</th>
                            <th align='center' className='report-th text-center' colSpan={userType === 'B' ? '1' : '2'}>{userType === 'B' ? 'Month (Cash)' : 'Month'}</th>
                            <th align='center' className='report-th text-center' colSpan={userType === 'B' ? '1' : '2'}>{userType === 'B' ? 'Year (Cash)' : 'Year'}</th>
                          <th align='center' rowSpan='2'>Last Update</th>
                          <th align='center' rowSpan='2'>
                            <img className='custom-info' id='PLAYRETURN' src={infoIcon} alt='custom-info' />
                            <UncontrolledTooltip className='bg-default-s' delay={0} placement='right' target='PLAYRETURN'>
                              <p>{Info?.playReturn}</p>
                            </UncontrolledTooltip>
                          </th>
                        </tr>
                      {userType !== 'B' &&
                        <tr align='center'>
                          <th className='report-th-1 text-center'>Cash</th>
                          <th className='report-th-2 text-center'>Bonus</th>
                          <th className='report-th-1 text-center'>Cash</th>
                          <th className='report-th-2 text-center'>Bonus</th>
                          <th className='report-th-1 text-center'>Cash</th>
                          <th className='report-th-2 text-center'>Bonus</th>
                          <th className='report-th-1 text-center'>Cash</th>
                          <th className='report-th-2 text-center'>Bonus</th>
                          <th className='report-th-1 text-center'>Cash</th>
                          <th className='report-th-2 text-center'>Bonus</th>
                          <th className='report-th-1 text-center'>Cash</th>
                          <th className='report-th-2 text-center'>Bonus</th>
                        </tr>
                      }
                      </thead>
                      <tbody>
                        {playReturn?.map((data, index) => (
                          <tr key={index} align='center'>
                          <td><b>{data?.eCategory === 'C' ? CategoryLabels?.C : data?.eCategory === 'S' ? CategoryLabels?.S : data?.eCategory === 'CR' ? CategoryLabels?.CR : data?.eCategory === 'GT' ? CategoryLabels?.GT : data?.eCategory === 'IPO' ? CategoryLabels?.IPO : CategoryLabels?.N}</b></td>
                          <td>{fixDigit(data?.nTotalCash)}</td>
                            {userType !== 'B' && <td>{fixDigit(data?.nTotalBonus)}</td>}
                            <td>{fixDigit(data?.nTodayCash)}</td>
                            {userType !== 'B' && <td>{fixDigit(data?.nTodayBonus)}</td>}
                            <td>{fixDigit(data?.nYesterCash)}</td>
                            {userType !== 'B' && <td>{fixDigit(data?.nYesterBonus)}</td>}
                            <td>{fixDigit(data?.nWeekCash)}</td>
                            {userType !== 'B' && <td>{fixDigit(data?.nWeekBonus)}</td>}
                            <td>{fixDigit(data?.nMonthCash)}</td>
                            {userType !== 'B' && <td>{fixDigit(data?.nMonthBonus)}</td>}
                            <td>{fixDigit(data?.nYearCash)}</td>
                            {userType !== 'B' && <td>{fixDigit(data?.nYearBonus)}</td>}
                            <td>{dateFormate(data?.dUpdatedAt)}</td>
                            <td>
                              {permission && (
                              <Button color='link' onClick={() => updatePlayReturnFunc(data?._id, 'PR', data?.eCategory)}>
                                <img alt='Participants' height='20px' src={refreshIcon} width='20px'/>
                              </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
              )
            : <DataNotFound message="Data" obj=""/>
        : ''}
    </>
  )
}
PlayReturnReport.propTypes = {
  dateWiseReports: PropTypes?.string,
  isOpen: PropTypes?.bool,
  sports: PropTypes?.array,
  permission: PropTypes?.string,
  playReturn: PropTypes?.array,
  userType: PropTypes?.string,
  token: PropTypes?.string,
  setLoading: PropTypes?.bool,
  setPlayReturn: PropTypes?.func,
  previousProps: PropTypes?.object
}
export default PlayReturnReport
