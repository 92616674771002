import { Col, Row, UncontrolledTooltip } from "reactstrap"
import DataNotFound from "../../../components/DataNotFound"
import infoIcon from '../../../assets/images/info-icon.svg'
import { Fragment, useEffect, useState } from "react"

const SubCategoryReportDetails = (props) => {
    const {
        adminOrders,
        adminTransactions,
        userOrders,
        userTransactions
    } = props

    const [adminPendingOrders, setAdminPendingOrders] = useState({})
    const [adminMatchedOrders, setAdminMatchedOrders] = useState({})
    const [adminWinTransactions, setAdminWinTransactions] = useState({})
    const [adminPlayTransactions, setAdminPlayTransactions] = useState({})
    const [userPendingOrders, setUserPendingOrders] = useState({})
    const [userMatchedOrders, setUserMatchedOrders] = useState({})
    const [userPartiallyMatchedOrders, setUserPartiallyMatchedOrders] = useState({})
    const [userWinTransactions, setUserWinTransactions] = useState({})
    const [userPlayTransactions, setUserPlayTransactions] = useState({})

    useEffect(() => {
        if (adminOrders?.length) {
            const pendingOrder = adminOrders.find(order => order.eOrderStatus === 'PENDING');
            pendingOrder && setAdminPendingOrders(pendingOrder)
            const matchedOrder = adminOrders.find(order => order.eOrderStatus === 'MATCHED');
            matchedOrder && setAdminMatchedOrders(matchedOrder)
            const adminWin = adminTransactions.find(order => order.eTransactionType === 'Win-OT');
            adminWin && setAdminWinTransactions(adminWin)
            // const adminPlay = adminTransactions.find(order => order.eTransactionType === 'Play-OT');
            adminWin && setAdminPlayTransactions(adminWin)
            const userPendingOrders = userOrders.find(order => order.eOrderStatus === 'PENDING');
            userPendingOrders && setUserPendingOrders(userPendingOrders)
            const userMatchedOrders = userOrders.find(order => order.eOrderStatus === 'MATCHED');
            userMatchedOrders && setUserMatchedOrders(userMatchedOrders)
            const userPartiallyMatchedOrders = userOrders.find(order => order.eOrderStatus === 'PARTIALLY_FILLED');
            userPartiallyMatchedOrders && setUserPartiallyMatchedOrders(userPartiallyMatchedOrders)
            const userWinTransactions = userTransactions.find(order => order.eTransactionType === 'Win-OT');
            userWinTransactions && setUserWinTransactions(userWinTransactions)
            const userPlayTransactions = userTransactions.find(order => order.eTransactionType === 'Play-OT');
            userPlayTransactions && setUserPlayTransactions(userPlayTransactions)

        }
    }, [adminOrders])
    // dispatch action to update the generalize data
    //   function updateGeneralizeReportFunc (key) {
    //     if (token) {
    //       const generalizeReportData = {
    //         key, userType, token
    //       }
    //       dispatch(updateGeneralizeReport(generalizeReportData))
    //       setLoading(true)
    //     }
    //   }
    return (
        <>
            <section className='user-section'>
                <Row>
                    <Col className='mb-4' lg='4' md='6'>
                        <Fragment>
                            <div className='allReports-table'>
                                <div className='allReports-table-responsive'>
                                    <div className='total-user d-flex justify-content-between'>
                                        <h3>
                                            Admin Pending Orders
                                            <img className='custom-info' id='TU' src={infoIcon} alt='custom-info' />
                                            <UncontrolledTooltip className='bg-default-s' delay={0} placement='right' target='TU'>
                                                <p>Admin</p>
                                            </UncontrolledTooltip>
                                        </h3>
                                    </div>
                                    {adminPendingOrders
                                        ? (
                                            <>
                                                <table className='table-user'>
                                                    <tbody className='reports-login-users'>
                                                        <tr>
                                                            <td>Total Order</td>
                                                            <td>{adminPendingOrders?.nTotalOrder || '0'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total Quantity</td>
                                                            <td>{adminPendingOrders?.nTotalQty || '0'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total Buy Order</td>
                                                            <td>{adminPendingOrders?.nTotalBuyOrder || '0'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total Buy Quantity</td>
                                                            <td>{adminPendingOrders?.nTotalBuyQty || '0'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total Sell Order</td>
                                                            <td>{adminPendingOrders?.nTotalSellOrder || '0'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total Sell Quantity</td>
                                                            <td>{adminPendingOrders?.nTotalSellQty || '0'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total Filled Quantity</td>
                                                            <td>{adminPendingOrders?.nTotalFilledQty || '0'}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </>
                                        )
                                        : (<Col className='notFoundImage'><DataNotFound message="Data" obj="" /></Col>)}
                                </div>
                            </div>
                        </Fragment>
                    </Col>

                    <Col className='mb-4' lg='4' md='6'>
                        <Fragment>
                            <div className='allReports-table'>
                                <div className='allReports-table-responsive'>
                                    <div className='total-user d-flex justify-content-between'>
                                        <h3>
                                            Admin Matched Orders
                                            <img className='custom-info' id='LU' src={infoIcon} alt='custom-info' />
                                            <UncontrolledTooltip className='bg-default-s' delay={0} placement='right' target='LU'>
                                                <p>Admin Matched Orders</p>
                                            </UncontrolledTooltip>
                                        </h3>
                                    </div>
                                    {adminMatchedOrders
                                        ? (
                                            <table className='table-user'>
                                                <tbody className='reports-login-users'>
                                                    <tr>
                                                        <td>Total Order</td>
                                                        <td>{adminMatchedOrders?.nTotalOrder || '0'}</td>
                                                    </tr>
                                                    <tr> 
                                                        <td>Total Quantity</td>
                                                        <td>{adminMatchedOrders?.nTotalQty || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Buy Order</td>
                                                        <td>{adminMatchedOrders?.nTotalBuyOrder || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Buy Quantity</td>
                                                        <td>{adminMatchedOrders?.nTotalBuyQty || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Sell Order</td>
                                                        <td>{adminMatchedOrders?.nTotalSellOrder || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Sell Quantity</td>
                                                        <td>{adminMatchedOrders?.nTotalSellQty || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Filled Quantity</td>
                                                        <td>{adminMatchedOrders?.nTotalFilledQty || '0'}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )
                                        : (<Col className='notFoundImage'><DataNotFound message="Data" obj="" /></Col>)}
                                </div>
                            </div>
                        </Fragment>
                    </Col>
                    <Col className='mb-4' lg='4' md='6'>
                        <Fragment>
                            <div className='allReports-table'>
                                <div className='allReports-table-responsive'>
                                    <div className='total-user d-flex justify-content-between'>
                                        <h3>
                                            Admin Win Transactions
                                            <img className='custom-info' id='LU' src={infoIcon} alt='custom-info' />
                                            <UncontrolledTooltip className='bg-default-s' delay={0} placement='right' target='LU'>
                                                <p>Admin Win Transactions</p>
                                            </UncontrolledTooltip>
                                        </h3>
                                    </div>
                                    {adminWinTransactions
                                        ? (
                                            <table className='table-user'>
                                                <tbody className='reports-login-users'>
                                                    <tr>
                                                        <td>Total Amount</td>
                                                        <td>{adminWinTransactions?.nTotalAmount || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Winning</td>
                                                        <td>{adminWinTransactions?.nTotalWinning || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Bonus</td>
                                                        <td>{adminWinTransactions?.nTotalBonus || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Buy Commission</td>
                                                        <td>{adminWinTransactions?.nTotalBuyCommission || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Cash</td>
                                                        <td>{adminWinTransactions?.nTotalCash || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Play Return</td>
                                                        <td>{adminWinTransactions?.nTotalPlayReturn || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Sell Commission</td>
                                                        <td>{adminWinTransactions?.nTotalSellCommission || '0'}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )
                                        : (<Col className='notFoundImage'><DataNotFound message="Data" obj="" /></Col>)}
                                </div>
                            </div>
                        </Fragment>
                    </Col>
                    <Col className='mb-4' lg='4' md='6'>
                        <Fragment>
                            <div className='allReports-table'>
                                <div className='allReports-table-responsive'>
                                    <div className='total-user d-flex justify-content-between'>
                                        <h3>
                                            Admin Play Transactions
                                            <img className='custom-info' id='LU' src={infoIcon} alt='custom-info' />
                                            <UncontrolledTooltip className='bg-default-s' delay={0} placement='right' target='LU'>
                                                <p>Admin Play Transactions</p>
                                            </UncontrolledTooltip>
                                        </h3>
                                    </div>
                                    {adminPlayTransactions
                                        ? (
                                            <table className='table-user'>
                                                <tbody className='reports-login-users'>
                                                    <tr>
                                                        <td>Total Amount</td>
                                                        <td>{adminPlayTransactions?.nTotalAmount || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Winning</td>
                                                        <td>{adminPlayTransactions?.nTotalWinning || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Bonus</td>
                                                        <td>{adminPlayTransactions?.nTotalBonus || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Buy Commission</td>
                                                        <td>{adminPlayTransactions?.nTotalBuyCommission || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Cash</td>
                                                        <td>{adminPlayTransactions?.nTotalCash || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Play Return</td>
                                                        <td>{adminPlayTransactions?.nTotalPlayReturn || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Sell Commission</td>
                                                        <td>{adminPlayTransactions?.nTotalSellCommission || '0'}</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        )
                                        : (<Col className='notFoundImage'><DataNotFound message="Data" obj="" /></Col>)}
                                </div>
                            </div>
                        </Fragment>
                    </Col>
                    <Col className='mb-4' lg='4' md='6'>
                        <Fragment>
                            <div className='allReports-table'>
                                <div className='allReports-table-responsive'>
                                    <div className='total-user d-flex justify-content-between'>
                                        <h3>
                                            User Pending Orders
                                            <img className='custom-info' id='LU' src={infoIcon} alt='custom-info' />
                                            <UncontrolledTooltip className='bg-default-s' delay={0} placement='right' target='LU'>
                                                <p>User Pending Orders</p>
                                            </UncontrolledTooltip>
                                        </h3>
                                    </div>
                                    {userPendingOrders
                                        ? (
                                            <table className='table-user'>
                                                <tbody className='reports-login-users'>
                                                    <tr>
                                                        <td>Total Market Orders</td>
                                                        <td>{userPendingOrders?.nTotalMarketOrder || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Limit Orders</td>
                                                        <td>{userPendingOrders?.nTotalLimitOrder || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Buy Orders</td>
                                                        <td>{userPendingOrders?.nTotalBuyOrder || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Buy Quantity</td>
                                                        <td>{userPendingOrders?.nTotalBuyQty || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Sell Orders</td>
                                                        <td>{userPendingOrders?.nTotalSellOrder || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Sell Quantity</td>
                                                        <td>{userPendingOrders?.nTotalSellQty || '0'}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )
                                        : (<Col className='notFoundImage'><DataNotFound message="Data" obj="" /></Col>)}
                                </div>
                            </div>
                        </Fragment>
                    </Col>
                    <Col className='mb-4' lg='4' md='6'>
                        <Fragment>
                            <div className='allReports-table'>
                                <div className='allReports-table-responsive'>
                                    <div className='total-user d-flex justify-content-between'>
                                        <h3>
                                            User Matched Orders
                                            <img className='custom-info' id='LU' src={infoIcon} alt='custom-info' />
                                            <UncontrolledTooltip className='bg-default-s' delay={0} placement='right' target='LU'>
                                                <p>User Matched Orders</p>
                                            </UncontrolledTooltip>
                                        </h3>
                                    </div>
                                    {userMatchedOrders
                                        ? (
                                            <table className='table-user'>
                                                <tbody className='reports-login-users'>
                                                    <tr>
                                                        <td>Total Market Orders</td>
                                                        <td>{userMatchedOrders?.nTotalMarketOrder || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Limit Orders</td>
                                                        <td>{userMatchedOrders?.nTotalLimitOrder || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Buy Orders</td>
                                                        <td>{userMatchedOrders?.nTotalBuyOrder || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Buy Quantity</td>
                                                        <td>{userMatchedOrders?.nTotalBuyQty || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Sell Orders</td>
                                                        <td>{userMatchedOrders?.nTotalSellOrder || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Sell Quantity</td>
                                                        <td>{userMatchedOrders?.nTotalSellQty || '0'}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )
                                        : (<Col className='notFoundImage'><DataNotFound message="Data" obj="" /></Col>)}
                                </div>
                            </div>
                        </Fragment>
                    </Col>
                    <Col className='mb-4' lg='4' md='6'>
                        <Fragment>
                            <div className='allReports-table'>
                                <div className='allReports-table-responsive'>
                                    <div className='total-user d-flex justify-content-between'>
                                        <h3>
                                            User Partially Matched Orders
                                            <img className='custom-info' id='LU' src={infoIcon} alt='custom-info' />
                                            <UncontrolledTooltip className='bg-default-s' delay={0} placement='right' target='LU'>
                                                <p>User Partially Matched Orders</p>
                                            </UncontrolledTooltip>
                                        </h3>
                                    </div>
                                    {userPartiallyMatchedOrders
                                        ? (
                                            <table className='table-user'>
                                                <tbody className='reports-login-users'>
                                                    <tr>
                                                        <td>Total Market Orders</td>
                                                        <td>{userPartiallyMatchedOrders?.nTotalMarketOrder || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Limit Orders</td>
                                                        <td>{userPartiallyMatchedOrders?.nTotalLimitOrder || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Buy Orders</td>
                                                        <td>{userPartiallyMatchedOrders?.nTotalBuyOrder || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Buy Quantity</td>
                                                        <td>{userPartiallyMatchedOrders?.nTotalBuyQty || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Sell Orders</td>
                                                        <td>{userPartiallyMatchedOrders?.nTotalSellOrder || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Sell Quantity</td>
                                                        <td>{userPartiallyMatchedOrders?.nTotalSellQty || '0'}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )
                                        : (<Col className='notFoundImage'><DataNotFound message="Data" obj="" /></Col>)}
                                </div>
                            </div>
                        </Fragment>
                    </Col>
                    <Col className='mb-4' lg='4' md='6'>
                        <Fragment>
                            <div className='allReports-table'>
                                <div className='allReports-table-responsive'>
                                    <div className='total-user d-flex justify-content-between'>
                                        <h3>
                                            User Win Transactions
                                            <img className='custom-info' id='LU' src={infoIcon} alt='custom-info' />
                                            <UncontrolledTooltip className='bg-default-s' delay={0} placement='right' target='LU'>
                                                <p>User Win Transactions</p>
                                            </UncontrolledTooltip>
                                        </h3>
                                    </div>
                                    {userWinTransactions
                                        ? (
                                            <table className='table-user'>
                                                <tbody className='reports-login-users'>
                                                    <tr>
                                                        <td>Total Amount</td>
                                                        <td>{userWinTransactions?.nTotalAmount || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Winning</td>
                                                        <td>{userWinTransactions?.nTotalWinning || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Bonus</td>
                                                        <td>{userWinTransactions?.nTotalBonus || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Cash</td>
                                                        <td>{userWinTransactions?.nTotalCash || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Play Return</td>
                                                        <td>{userWinTransactions?.nTotalPlayReturn || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Sell Commission</td>
                                                        <td>{userWinTransactions?.nTotalSellCommission || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Buy Commission</td>
                                                        <td>{userWinTransactions?.nTotalBuyCommission || '0'}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )
                                        : (<Col className='notFoundImage'><DataNotFound message="Data" obj="" /></Col>)}
                                </div>
                            </div>
                        </Fragment>
                    </Col>
                    <Col className='mb-4' lg='4' md='6'>
                        <Fragment>
                            <div className='allReports-table'>
                                <div className='allReports-table-responsive'>
                                    <div className='total-user d-flex justify-content-between'>
                                        <h3>
                                            User Play Transactions
                                            <img className='custom-info' id='LU' src={infoIcon} alt='custom-info' />
                                            <UncontrolledTooltip className='bg-default-s' delay={0} placement='right' target='LU'>
                                                <p>User Play Transactions</p>
                                            </UncontrolledTooltip>
                                        </h3>
                                    </div>
                                    {userPlayTransactions
                                        ? (
                                            <table className='table-user'>
                                                <tbody className='reports-login-users'>
                                                    <tr>
                                                        <td>Total Amount</td>
                                                        <td>{userPlayTransactions?.nTotalAmount || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Winning</td>
                                                        <td>{userPlayTransactions?.nTotalWinning || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Bonus</td>
                                                        <td>{userPlayTransactions?.nTotalBonus || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Cash</td>
                                                        <td>{userPlayTransactions?.nTotalCash || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Play Return</td>
                                                        <td>{userPlayTransactions?.nTotalPlayReturn || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Sell Commission</td>
                                                        <td>{userPlayTransactions?.nTotalSellCommission || '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Buy Commission</td>
                                                        <td>{userPlayTransactions?.nTotalBuyCommission || '0'}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )
                                        : (<Col className='notFoundImage'><DataNotFound message="Data" obj="" /></Col>)}
                                </div>
                            </div>
                        </Fragment>
                    </Col>


                </Row>
            </section>
        </>
    )
}
export default SubCategoryReportDetails