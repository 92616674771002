import axios from '../axios'
import { catchFunc, } from '../helpers/helper'
import { EVENT_LIST, CLEAR_EVENTS_MESSAGE, UPDATE_EVENT, EVENT_ADMIN_BUY_COMMISSION, ADD_EVENT, DELETE_EVENT, EVENT_DETAILS,DECLARE_OUTCOME,UPDATE_EVENT_STATUS } from './constants'
const errMsg = 'Server is unavailable.'

export const getEventList = (eventData) => async (dispatch) => {
  const { start, limit, sort, order, searchvalue,  startDate, endDate, isFullResponse, token,eventDropdown,categoryDropdown,subCategoryId ,eventStatus} = eventData
  // let filterValues = []
  // if (typeof filterBy === 'object') {
  //   filterValues = filterBy?.map((item) => item.value)
  // }
  let params={
    start,
    limit,
    sorting: sort,
    order,
    search: searchvalue,
    dFrom: startDate,
    dTo: endDate,
    isFullResponse,
  }
  if(eventDropdown){
    params.eOutcomeMethod=eventDropdown
  }
  if(eventStatus){
    params.eStatus=eventStatus
  }
  if(categoryDropdown){
    params.iCategoryId=categoryDropdown
  }
  if(subCategoryId){
    params.iSubCategoryId=subCategoryId
  }
  await axios.get('/ot/admin/event/list/v1', {
    params: params,
    headers: { Authorization: token }
  }).then((response) => {
    dispatch({
      type: EVENT_LIST,
      payload: {
        data: response.data.data ? response.data.data : [],
        resStatus: true,
        resMessage: response.data.message || '',
        isFullResponse
      }
    })
  }).catch(() => {
    dispatch({
      type: EVENT_LIST,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}
export const updateEventDetails = (editEvent) => async (dispatch) => {
  dispatch({ type: CLEAR_EVENTS_MESSAGE })
  const { eventId, eventName, eventDesc, eventCategoryId, eventSubCategory, startDate, endDate, tags, adminCommissionState, labelForYes, labelForNo, minBuyQty, maxBuyQty, quantityRange, commissionType, initialQuatityForYes, initialQuatityForNo,bonus,sentiment,currentPriceForNo,currentPriceForYes } = editEvent
  await axios.put(`/ot/admin/event/${eventId}/v1`, {
    sName: eventName,
    eType: "P",
    sDescription: eventDesc,
    dStartDate: startDate,
    dEndDate: endDate,
    iSubCategoryId: eventSubCategory?.value,
    iCategoryId: eventCategoryId,
    aTags: tags,
    oUserConfig: {
      nMinBuyQty: Number(minBuyQty),
      nMaxBuyQty: Number(maxBuyQty),
      aQtyRange: quantityRange
    },
    aAnswerOptions: [
      {
        sLabel: labelForYes, 
        sValue: "YES",
        nInitialQty: initialQuatityForYes,
        bIsSentiment:sentiment==='YES' ? true : false,
        nCurrentPrice:currentPriceForYes,
        nInitialAdminOrderPrice:currentPriceForYes
      },
      {
        sLabel: labelForNo,
        sValue: "NO",
        nInitialQty: initialQuatityForNo,
        bIsSentiment:sentiment==='NO' ? true : false,
        nCurrentPrice:currentPriceForNo,
        nInitialAdminOrderPrice:currentPriceForNo
      }
    ],
    nAdminBuyCommission: adminCommissionState,
    eAdminProfitCommissionType: commissionType,
    nBonusUtil:bonus
  }).then((response) => {
    dispatch({
      type: UPDATE_EVENT,
      payload: {
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch(catchFunc(UPDATE_EVENT, error))
  })
}
export const getAdminBuyCommission = () => async (dispatch) => {
  await axios.get('/administrator/admin/rules/get-rule/v1?eRule=BOC').then((response) => {
    dispatch({
      type: EVENT_ADMIN_BUY_COMMISSION,
      payload: {
        data: response.data.data ? response.data.data : [],
      }
    })
  })
}
export const addEvent = (addEvent) => async (dispatch) => {
  const { eventName, eventDesc, eventCategoryId, eventSubCategory, startDate, endDate, tags, adminCommissionState, labelForYes, labelForNo, minBuyQty, maxBuyQty, quantityRange, commissionType, initialQuatityForYes, initialQuatityForNo,bonus,sentiment,currentPriceForNo,currentPriceForYes } = addEvent
  dispatch({ type: CLEAR_EVENTS_MESSAGE })
  await axios?.post('/ot/admin/event/v1', {
    sName: eventName,
    eType: "P",
    sDescription: eventDesc,
    dStartDate: startDate,
    dEndDate: endDate,
    iSubCategoryId: eventSubCategory?.value,
    iCategoryId: eventCategoryId,
    aTags: tags,
    oUserConfig: {
      nMinBuyQty: Number(minBuyQty),
      nMaxBuyQty:Number(maxBuyQty),
      aQtyRange: quantityRange
    },
    aAnswerOptions: [
      {
        sLabel: labelForYes,
        sValue: "YES",
        nInitialQty: initialQuatityForYes,
        bIsSentiment:sentiment==='YES' ? true : false,
        nCurrentPrice:currentPriceForYes,
        nInitialAdminOrderPrice:currentPriceForYes
      },
      {
        sLabel: labelForNo,
        sValue: "NO",
        nInitialQty: initialQuatityForNo,
        bIsSentiment:sentiment==='NO' ? true : false,
        nCurrentPrice:currentPriceForNo,
        nInitialAdminOrderPrice:currentPriceForNo
      }
    ],
    nAdminBuyCommission: adminCommissionState,
    eAdminProfitCommissionType: commissionType,
    aPriceRange: [
      {
          "nAmount": 100
      },
      {
          "nAmount": 500
      },
      {
          "nAmount": 1000
      },
      {
          "nAmount": 2000
      },
      {
          "nAmount": 5000
      }
  ],
  nBonusUtil:bonus
  })?.then((response) => {
    dispatch({
      type: ADD_EVENT,
      payload: {
        resStatus: true,
        resMessage: response?.data?.message
      }
    })
  }).catch((error) => {
    dispatch(catchFunc(ADD_EVENT, error))
  })
}
export const deleteEvent = ({ Id }) => async (dispatch) => {
  dispatch({ type: CLEAR_EVENTS_MESSAGE })
  await axios.delete(`/ot/admin/event/${Id}/v1`).then((response) => {
    dispatch({
      type: DELETE_EVENT,
      payload: {
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: DELETE_EVENT,
      payload: {
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}
export const getEventDetails = ({ Id }) => async (dispatch) => {
  await axios.get(`/ot/admin/event/${Id}/v1`).then((response) => {
    dispatch({
      type: EVENT_DETAILS,
      payload: {
        data: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: EVENT_DETAILS,
      payload: {
        data: {},
        resStatus: false
      }
    })
  })
}
export const declareOutComeEvent = ({ eventId,outComeId }) => async (dispatch) => {
  await axios.post('/ot/admin/event/declare-outcome/v1', {
    iEventId:eventId,
    iSymbolId:outComeId
  }).then((response)=>{
    dispatch({
      type: DECLARE_OUTCOME,
      payload: {
        resStatus: true,
        resMessage: response?.data?.message
      }
    })
  }).catch((error) => {
    dispatch(catchFunc(DECLARE_OUTCOME, error))
  })
}
export const updateEventStatus = (eventId,eStatus) => async (dispatch) => {
  dispatch({ type: CLEAR_EVENTS_MESSAGE })
  await axios.put(`/ot/admin/event/update-status/${eventId}/v1`, {
    eStatus: eStatus,
    }).then((response) => {
    dispatch({
      type: UPDATE_EVENT_STATUS,
      payload: {
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch(catchFunc(UPDATE_EVENT_STATUS, error))
  })
}