// CommonRules.jsx
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useQueryState } from 'react-router-use-location-state'
import { useMutation, useQuery } from '@tanstack/react-query'

import CommonRuleList from './CommonRuleList'
import getRuleList from '../../../api/commonRule/querie/useGetRuleList'
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export'
import MainHeading from '../Component/MainHeading'

function CommonRules (props) {
  const content = useRef()
  const [start, setStart] = useState(0)
  const [csvData, setCsvData] = useState()
  const [offset, setOffset] = useQueryState('pageSize', 10)
  const exporter = useRef(null)

  const adminPermission = useSelector((state) => state?.auth?.adminPermission)
  const Auth = useSelector((state) => state?.auth?.adminData && state?.auth?.adminData?.eType)

  const { data: ruleList, isLoading } = useQuery({
    queryKey: ['getRuleList', start, offset],
    queryFn: () => getRuleList(start, offset),
    select: (res) => res?.data?.data
  })

  function handleDownload () {
    const processedData = processExcelExportData(csvData)
    exporter.current.props = {
      ...exporter?.current?.props,
      data: processedData,
      fileName: 'CommonRules.xlsx'
    }
    exporter?.current?.save()
  }

  useEffect(() => {
    if (csvData) {
      handleDownload()
    }
  }, [csvData])

  const mutation = useMutation({
    mutationFn: () => getRuleList(0, 10, true),
    onSuccess: (data) => {
      if (data?.data?.data?.data) {
        setCsvData(data?.data?.data?.data)
      } else {
        console.log('Incomplete data for export')
      }
    }
  })

  function onExport () {
    mutation.mutate()
  } 

  const processExcelExportData = (data) =>
    data?.map((commonRuleList, index) => {
      const srNo = index + 1
      let eStatus = commonRuleList?.eStatus
      let eType = commonRuleList?.eType
      eStatus = eStatus === 'Y' ? 'Active' : 'InActive'
      eType = eType === 'B' ? 'Bonus' : eType === 'C' ? 'Cash' : eType === 'W' ? 'Withdraw' : eType === 'D' ? 'Deposit' : '--'
      return {
        ...commonRuleList,
        srNo,
        eStatus,
        eType
      }
    })

  return (
    <>
      <ExcelExport ref={exporter} data={csvData} fileName='Commonrules.xlsx'>
        <ExcelExportColumn field='srNo' title='Sr No' />
        <ExcelExportColumn field='eStatus' title='Status' />
        <ExcelExportColumn field='sRuleName' title='Rule Name' />
        <ExcelExportColumn field='eRule' title='Rule Shortname' />
        <ExcelExportColumn field='nAmount' title='Amount' />
        <ExcelExportColumn field='eType' title='Type' />
      </ExcelExport>
      <main className='main-content'>
        <section className='management-section common-box'>
          <MainHeading
            export='Export'
            heading='Common Rules'
            list={ruleList}
            onExport={onExport}
            permission={(Auth && Auth === 'SUPER') || adminPermission?.RULE !== 'R'}
          />
          <div className='without-pagination'>
            <CommonRuleList
              {...props}
              ref={content}
              rulesList={ruleList}
              isLoading={isLoading}
              start={start}
              setStart={setStart}
              offset={offset}
              setOffset={setOffset}
            />
          </div>
        </section>
      </main>
    </>
  )
}

export default CommonRules
