import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Form, FormGroup, Label, Input, InputGroupText, Row, Col } from 'reactstrap'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import PropTypes from 'prop-types'
import Select from 'react-select'

import removeImg from '../../../../assets/images/ep-close.svg'
import documentPlaceholder from '../../../../assets/images/upload-icon.svg'

import Loading from '../../../../components/Loading'
import AlertMessage from '../../../../components/AlertMessage'
import RequiredField from '../../../../components/RequiredField'

import { acceptFormat, isNumber, modalMessageFunc, verifyLength, verifyUrl } from '../../../../helpers/helper'
import { getUrl } from '../../../../actions/url'
import { getMatchList } from '../../../../actions/match'
import { getSportsList } from '../../../../actions/sports'
import { getBannerMatchLeagueList } from '../../../../actions/matchleague'
import { addBanner, getBannerDetails, updateBanner } from '../../../../actions/banner'
import { Alignment, Base64UploadAdapter, BlockQuote, Bold, ClassicEditor, Essentials, Font, Heading, HtmlEmbed, Image, ImageBlock, ImageUpload, Italic, List, MediaEmbed, Mention, Paragraph, Strikethrough, Table, Underline, Undo } from 'ckeditor5'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import getSubCategoryList from '../../../../api/category/getSubCategoryList'
import getEventsList from '../../../../api/EventManagement/getEventsList'
import getCategoryList from '../../../../api/category/getCategoryList'

const AddSliderForm = forwardRef((props, ref) => {
  const { isCreate, setIsEdit, setIsCreate, Auth, adminPermission, setSubmitDisableButton } = props
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const sportsTypeParam = searchParams.get('sportsType')

  const [place, setPlace] = useState('')
  const [placeErr, setPlaceErr] = useState('')
  const [Link, setLink] = useState('')
  const [Description, setDescription] = useState('')
  const [bannerType, setBannerType] = useState('')
  const [screen, setScreen] = useState('')
  const [screenErr, setScreenErr] = useState('')
  const [bannerErr, setBannerErr] = useState('')
  const [errLink, setErrLink] = useState('')
  const [errDescription, setErrDescription] = useState('')
  const [bannerImage, setbannerImage] = useState('')
  const [errImage, setErrImage] = useState('')
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState(false)
  const [bannerId, setbannerId] = useState('')
  const [sportsType, setSportsType] = useState('')
  const [MatchList, setMatchList] = useState([])
  const [Match, setMatch] = useState('')
  const [LeagueList, setLeagueList] = useState([])
  const [League, setLeague] = useState('')
  const [position, setPosition] = useState(0)
  const [errSportsType, setErrSportsType] = useState('')
  const [errMatch, setErrMatch] = useState('')
  const [close, setClose] = useState(false)
  const [bannerStatus, setBannerStatus] = useState('N')
  const [url, setUrl] = useState('')
  // const [sliderDetails, setSliderDetails] = useState()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = useSelector(state => state?.auth?.token)
  const bannerDetails = useSelector(state => state?.banner?.bannerDetails)
  const getUrlLink = useSelector(state => state?.url?.getUrl)
  const resStatus = useSelector(state => state?.banner?.resStatus)
  const resMessage = useSelector(state => state?.banner?.resMessage)
  const sportsList = useSelector(state => state?.sports?.sportsList)
  const matchList = useSelector(state => state?.match?.matchList)
  const MatchLeagueList = useSelector(state => state?.matchleague?.bannerMatchLeagueList)
  const previousProps = useRef({ resStatus, resMessage, bannerDetails, bannerType, screen, sportsType, Match, matchList, League, LeagueList })?.current
  const [modalMessage, setModalMessage] = useState(false)
  const [sliderSubCategory, setSliderSubCategory] = useState('')
  const [sliderEvent, setSliderEvent] = useState('')
  const [sliderCategory, setSliderCategory] = useState('')
  const { data: categoryList } = useQuery({
    queryKey: ['getCategoryList', 0,50,'','Y'],
    queryFn: () => getCategoryList(0,50,'','Y'),
    select: (res) => res?.data?.data?.results
  })
  const { 
    data:subCategoryList,  
    isFetching, 
    fetchNextPage, 
    hasNextPage 
  } = useInfiniteQuery({
    queryKey: ['getSubCategoryList', 0, 10,sliderCategory],
    queryFn: ({ pageParam = 0 }) => getSubCategoryList(pageParam, 10,sliderCategory,'Y'),
    getNextPageParam: (lastPage, allPages) => {
      const total = lastPage?.data?.data?.total;
      const nextPage = allPages.flatMap(page => page?.data?.data?.results).length;
      return nextPage < total ? nextPage : undefined;
    },
    select: (res) => res?.pages?.flatMap(page => page?.data?.data?.results) || [],
    keepPreviousData: true,
  });
  const { 
    data:eventList, 
    isFetching:isFetchingEvent, 
    fetchNextPage:fetchNextPageEvent, 
    hasNextPage:hasNextPageEvent 
  } = useInfiniteQuery({
    queryKey: ['getEventsList', 0, 10,sliderSubCategory],
    queryFn: ({ pageParam = 0 }) => getEventsList(pageParam, 10,'',sliderSubCategory),
    getNextPageParam: (lastPage, allPages) => {
      const total = lastPage?.data?.data?.total;
      const nextPage = allPages.flatMap(page => page?.data?.data?.results).length;
      return nextPage < total ? nextPage : undefined;
    },
    select: (res) => res?.pages?.flatMap(page => page?.data?.data?.results) || [],
    keepPreviousData: true,
  });
const options = subCategoryList?.map(data => ({
  value: data._id,
  label: `${data?.sName}`,
})) || []; 
const optionsEvent = eventList?.map(data => ({
  value: data._id,
  label: `${data?.sName}`,
})) || [];
  // through this condition if there is no changes in at update and add time submit button will remain disable
  const submitDisable = () => {
    const isAdd = !bannerDetails

    // Conditions common to both add and edit pages
    const commonConditions =
      place === '' ||
      bannerType === '' ||
      !verifyLength(Description, 1) ||
      !bannerImage

    // Conditions specific to the add slider page
    if (isAdd) {
      return commonConditions ||
        (bannerType === 'L' && Link === '') ||
        (bannerType === 'S' && screen === '') ||
        (screen === 'CR' && sportsType === '') ||
        (screen === 'CR' && sportsType !== '' && (Match === '' || MatchList?.length === 0))
    }
    // Conditions specific to the edit slider page
    return commonConditions ||
      (bannerDetails?.sLink === Link &&
      bannerDetails?.eType === bannerType &&
      (bannerDetails?.nPosition || 0) === parseInt(position) &&
      bannerDetails?.sDescription === Description &&
      (bannerDetails?.iMatchId ? bannerDetails?.iMatchId : Match === '') &&
      (bannerDetails?.iMatchLeagueId ? bannerDetails?.iMatchLeagueId : League === '') &&
      bannerDetails?.eStatus === bannerStatus &&
      bannerDetails?.eScreen === screen &&
      bannerDetails?.sImage === bannerImage &&
      bannerDetails?.ePlace === place &&
      bannerDetails?.iCategoryId === sliderCategory && 
      bannerDetails?.iSubCategoryId === sliderSubCategory?.value &&
      bannerDetails?.iEventId === sliderEvent?.value
    )
  }

  useEffect(() => {
    if (id) {
      // dispatch action to get banner(slider) details
      dispatch(getBannerDetails({ Id: id, sportsType: sportsTypeParam }))
      setbannerId(id)
      setIsCreate(false)
      setLoading(true)
    } else {
      setIsEdit(true)
    }
    dispatch(getUrl('media'))
  }, [])

  useEffect(() => {
    setSubmitDisableButton(submitDisable)
  }, [submitDisable])

  useEffect(() => {
    if (getUrlLink) {
      setUrl(getUrlLink)
    }
  }, [getUrlLink])

  // to handle response
  useEffect(() => {
    if (previousProps?.resMessage !== resMessage) {
      if (resMessage) {
        setMessage(resMessage)
        setStatus(resStatus)
        if (resStatus && isCreate) {
          navigate('/content-management/slider-management', { state: { message: resMessage } })
        } else {
          if (resStatus) {
            setIsEdit(false)
            dispatch(getBannerDetails({ Id: id, sportsType: sportsTypeParam }))
          }
          setModalMessage(true)
        }
        setLoading(false)
      }
    }
    return () => {
      previousProps.resMessage = resMessage
    }
  }, [resStatus, resMessage])

  // set timeout to remove pop up success/error message after given interval
  useEffect(() => {
    modalMessageFunc(modalMessage, setModalMessage, setClose)
  }, [modalMessage])

  useEffect(() => {
    if (previousProps?.screen !== screen) {
      if (screen === 'CR') {
        dispatch(getSportsList(token))
        setLoading(true)
      }
    }
    if (sportsList) {
      setLoading(false)
    }
    return () => {
      previousProps.screen = screen
    }
  }, [screen, sportsList])

  useEffect(() => {
    if (sportsType && previousProps?.sportsType !== sportsType) {
      const data = { start: 0, limit: 10, sort: 'dCreatedAt', order: 'desc', search: '', filter: 'U', startDate: '', endDate: '', sportsType, provider: '', season: '', format: '', tournamentFlag: false, token }
      dispatch(getMatchList(data))
      setLoading(true)
    }
    if (matchList) {
      setLoading(false)
    }
    return () => {
      previousProps.sportsType = sportsType
      previousProps.matchList = matchList
    }
  }, [sportsType, matchList])

  useEffect(() => {
    if (Match && previousProps?.Match !== Match) {
      dispatch(getBannerMatchLeagueList(Match, sportsType, token))
      setLoading(true)
    }
    if (MatchLeagueList) {
      setLoading(false)
    }
    return () => {
      previousProps.Match = Match
    }
  }, [Match, MatchLeagueList])

  //  set match list
  useEffect(() => {
    if (previousProps?.matchList !== matchList) {
      if (matchList) {
        setMatchList(matchList?.results || [])
        !matchList.results && setErrMatch('')
        setLoading(false)
      }
    }
    return () => {
      previousProps.matchList = matchList
    }
  }, [matchList])

  //  set matchLegaueList
  useEffect(() => {
    if (previousProps?.MatchLeagueList !== MatchLeagueList) {
      if (MatchLeagueList) {
        setLeagueList(MatchLeagueList)
      }
      setLoading(false)
    }
    return () => {
      previousProps.MatchLeagueList = MatchLeagueList
    }
  }, [MatchLeagueList])

  //  set banner details
  useEffect(() => {
    if (previousProps?.bannerDetails !== bannerDetails) {
      if (bannerDetails) {
        setPlace(bannerDetails?.ePlace)
        setBannerType(bannerDetails?.eType)
        setLink(bannerDetails?.sLink)
        setScreen(bannerDetails?.eScreen)
        setbannerImage(bannerDetails?.sImage)
        setDescription(bannerDetails?.sDescription)
        setBannerStatus(bannerDetails?.eStatus)
        setSportsType(bannerDetails?.eCategory || '')
        setMatch(bannerDetails?.iMatchId || '')
        setLeague(bannerDetails?.iMatchLeagueId || '')
        setPosition(bannerDetails?.nPosition || 0)
        setSliderCategory(bannerDetails?.iCategoryId)
        if (bannerDetails?.iCategoryId && bannerDetails?.iSubCategoryId && subCategoryList?.length>0) {
          const findSubCat = subCategoryList?.find((i) => i?._id === bannerDetails?.iSubCategoryId)
          const newOpt = {
            value: findSubCat?._id,
            label: `${findSubCat?.sName}`
          }
          setSliderSubCategory(newOpt ? newOpt :'')
        }
        if (bannerDetails?.iCategoryId && bannerDetails?.iSubCategoryId && bannerDetails?.iEventId && subCategoryList?.length>0 && eventList?.length>0) {
          const findEvent = eventList?.find((i) => i?._id === bannerDetails?.iEventId)
          const newOpt = {
            value: findEvent?._id,
            label: `${findEvent?.sName}`
          }
          setSliderEvent(newOpt ? newOpt :'')
        }
        setLoading(false)
      } 
    }
    return () => {
      previousProps.bannerDetails = bannerDetails
    }
  }, [bannerDetails])
  // for handle onChange event
  function handleChange (event, type) {
    // const objjj = {
    //   ...sliderDetails, [event?.target?.name]: event?.target?.value
    // }
    // setSliderDetails(objjj)
    switch (type) {
      case 'Link':
        if (verifyLength(event?.target?.value, 1)) {
          if (!verifyUrl(event?.target?.value)) {
            setErrLink('Invalid link')
          } else {
            setErrLink('')
          }
        } else {
          setErrLink('Required field')
        }
        setLink(event?.target?.value)
        break
      case 'Description':
        if (verifyLength(event?.target?.value, 1)) {
          setErrDescription('')
        } else {
          setErrDescription('Required field')
        }
        setDescription(event?.target?.value)
        break
      case 'Image':
        if ((event?.target?.files[0]?.size / 1024 / 1024)?.toFixed(2) > 5) {
          setErrImage('Please select a file less than 5MB')
        } else if (event?.target?.files[0] && event?.target?.files[0]?.type?.includes('image')) {
          setbannerImage({ imageURL: URL?.createObjectURL(event?.target?.files[0]), file: event?.target?.files[0] })
          setErrImage('')
        }
        break
      case 'Status':
        setBannerStatus(event?.target?.value)
        break
      case 'Place':
        if (!event?.target?.value) {
          setPlaceErr('Required field')
        } else {
          setPlaceErr('')
        }
        setPlace(event?.target?.value)
        break
      case 'Type':
        if (event?.target?.value === 'S') {
          setScreen('D')
          setLink('')
          setBannerErr('')
          if (errImage || errDescription) {
            setErrImage('')
            setErrDescription('')
          }
        } else if (event?.target?.value === 'L') {
          setBannerErr('')
          setScreen('')
          if (errImage || errDescription) {
            setErrImage('')
            setErrDescription('')
            setErrLink('')
          }
        } else {
          setBannerErr('Required field')
          setLink('')
          setScreen('')
        }
        setBannerType(event?.target?.value)
        break
      case 'Screen':
        if (!event?.target?.value) {
          setScreenErr('Required field')
        } else {
          setScreenErr('')
        }
        setScreen(event?.target?.value)
        break
      case 'SportsType':
        if (verifyLength(event?.target?.value, 1)) {
          setErrSportsType('')
        } else {
          setErrSportsType('Required field')
        }
        setSportsType(event?.target?.value)
        setMatch('')
        setLeague('')
        break
      case 'Match':
        if (verifyLength(event?.target?.value, 1)) {
          setErrMatch('')
        } else {
          setErrMatch('Required field')
        }
        setMatch(event?.target?.value)
        setLeague('')
        break
      case 'League':
        setLeague(event?.target?.value)
        break
      case 'Position':
        if (isNumber(event?.target?.value) || !event?.target?.value) {
          setPosition(event?.target?.value)
        }
        break
      case 'RemoveImage' :
        setbannerImage('')
        break
      default:
        break
    }
  }

  // to handle image error occurred during add time
  function onImageError (ev) {
    ev.target.src = documentPlaceholder
  }

  // for validate the field and dispatch action
  function onSubmit (e) {
    let verify = false
    if (bannerType === 'L') {
      verify = (verifyLength(place, 1) && verifyLength(Link, 1) && verifyLength(Description, 1) && bannerImage && !errLink && !errDescription)
    } else if (bannerType === 'S' && screen === 'CR') {
      verify = verifyLength(place, 1) && verifyLength(Description, 1) && bannerImage && sportsType && Match
    } else {
      verify = (verifyLength(place, 1) && verifyLength(Description, 1) && bannerImage && !errDescription)
    }
    if (verify) {
      if (isCreate) {
        const addBannerData = {
          place, Link, bannerType, Description, bannerStatus, screen, bannerImage, sportsType: sportsType || undefined, Match, League, position, token,sliderCategory,sliderSubCategory,sliderEvent
        }
        dispatch(addBanner(addBannerData))
      } else {
        const updateBannerData = {
          place, bannerId, Link, bannerType, Description, bannerStatus, screen, bannerImage, sportsType: sportsType || undefined, Match, League, position, token,sliderCategory,sliderSubCategory,sliderEvent
        }
        dispatch(updateBanner(updateBannerData))
      }
      setLoading(true)
    } else {
      if (!bannerType) {
        setBannerErr('Required field')
      }
      if (!place) {
        setPlaceErr('Required field')
      }
      if (bannerType === 'L' && !verifyLength(Link, 1)) {
        setErrLink('Required field')
      }
      if (!verifyLength(Description, 1)) {
        setErrDescription('Required field')
      }
      if (!bannerImage) {
        setErrImage('Required field')
      }
      if (sportsType === '') {
        setErrSportsType('Required field')
      }
      if (MatchList?.length !== 0 && Match === '') {
        setErrMatch('Required field')
      }
      if (MatchList?.length === 0) {
        setErrMatch('No Upcoming Match Available')
      }
    }
  }

  useImperativeHandle(ref, () => ({
    onSubmit
  }))

  function onEditorChange (evt, editor) {
    if (verifyLength(editor?.getData(), 1)) {
      setErrDescription('')
    } else {
      setErrDescription('Required field')
    }
    setDescription(editor?.getData())
  }

  return (
    <main className="main-content">
      <AlertMessage
        close={close}
        message={message}
        modalMessage={modalMessage}
        status={status}
      />

      {loading && <Loading />}
      <section className="common-form-block">
        <Form>
          <Row>
            <Col md={12} xl={12}>
              <FormGroup className='form-group'>
                <div className="theme-image text-center">
                  <div className="d-flex theme-photo">
                    <div className={ bannerImage ? 'theme-img' : 'theme-img-default new-theme'}>
                      <img alt="PlayerImage" className={bannerImage ? 'custom-img' : 'custom-img-default'} onError={onImageError} src={bannerImage ? bannerImage?.imageURL ? bannerImage?.imageURL : url + bannerImage : documentPlaceholder}/>
                      {bannerImage && ((Auth && Auth === 'SUPER') || (adminPermission?.BANNER === 'W')) && 
                      <div className='remove-img-label'>
                        <img alt="removeImage" onClick={event => handleChange(event, 'RemoveImage')} src={removeImg} />
                        </div>}
                      {!bannerImage && ((Auth && Auth === 'SUPER') || (adminPermission?.BANNER === 'W')) && !bannerImage &&
                        (
                          <div className="custom-file">
                            <Input
                              accept={acceptFormat()}
                              id="exampleCustomFileBrowser"
                              name="customFile"
                              onChange={event => handleChange(event, 'Image')}
                              type="file"
                              className="custom-file-input"
                            />
                            <span className="upload-text">Upload Image</span>
                          </div>
                        )}
                    </div>
                  </div>
                  <p className="error-text">{errImage}</p>
                </div>
              </FormGroup>
            </Col>
            <h5 className='mx-4 required-field'>
              Note : Please upload images with dimensions of 327 × 56 pixels.

            </h5>
          </Row>
          <Row className='mt-3'>
            <Col md={12} xl={6}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="place">
                  Place
                  {' '}
                  <RequiredField/>
                </Label>
                <Input
                  className={placeErr ? 'league-placeholder-error' : 'form-control'}
                  disabled={adminPermission?.BANNER === 'R'}
                  name="place"
                  onChange={event => handleChange(event, 'Place')}
                  type="select"
                  value={place}
                >
                  <option value=''>Select place</option>
                  <option value="H">Home</option>
                  {/* <option value="D">Deposit</option> */}
                </Input>
                <p className="error-text">{placeErr}</p>
              </FormGroup>
            </Col>

            <Col md={12} xl={6}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="type">
                  Type
                  {' '}
                  <RequiredField/>
                </Label>
                <Input
                  className={bannerErr ? 'league-placeholder-error' : 'form-control'}
                  disabled={adminPermission?.BANNER === 'R'}
                  name="type"
                  onChange={event => handleChange(event, 'Type')}
                  type="select"
                  value={bannerType}
                >
                  <option value=''>Select type</option>
                  <option value="L">Link</option>
                  <option value="S">Screen</option>
                </Input>
                <p className="error-text">{bannerErr}</p>
              </FormGroup>
            </Col>
          </Row>

          <Row className='mt-2'>
            {bannerType === 'L'
              ? (
                <Col md={12} xl={12}>
                  <FormGroup className='form-group'>
                    <Label className='edit-label-setting' for="Name">
                      Link
                      <RequiredField/>
                    </Label>
                    <Input
                      className={errLink ? 'league-placeholder-error' : 'form-control'}
                      disabled={adminPermission?.BANNER === 'R'}
                      name="Link"
                      onChange={event => handleChange(event, 'Link')}
                      placeholder="Enter Link"
                      value={Link}
                    />
                    <p className="error-text">{errLink}</p>
                  </FormGroup>
                </Col>
                )
              : (
                <Col md={12} xl={12}>
                  <FormGroup className='form-group'>
                    <Label className='edit-label-setting' for="type">
                      Screen
                      <RequiredField/>
                    </Label>
                    <Input
                      className={screenErr ? 'league-placeholder-error' : 'form-control'}
                      disabled={adminPermission?.BANNER === 'R'}
                      name="screen"
                      onChange={event => handleChange(event, 'Screen')}
                      type="select"
                      value={screen}
                    >
                      <option value=''>Select Screen</option>
                      <option value="D">Deposit</option>
                      <option value="C">Catrgory</option>

                      {/* <option value="S">Share</option> */}
                      {/* <option value='CR'>Contest Redirect</option> */}
                    </Input>
                    <p className="error-text">{screenErr}</p>
                  </FormGroup>
                </Col>
                )
          }
          </Row>
          {screen==='C' ? 
          <Row>
            <Col md={4} xl={4}>
            <FormGroup className='form-group'>
                <Label for='sliderCategory'>Category</Label>
                <Input
                  name="category"
                  onChange={event => {
                    setSliderSubCategory('')
                    setSliderEvent('')
                    setSliderCategory(event?.target?.value)
                  }}
                  type="select"
                  value={sliderCategory}
                >
                  <option value=''>Select Category</option>
                  {categoryList?.map((category)=>{
                    return <option value={category?._id} data-key={category?._id}>{category?.sName}</option>
                  })}
                </Input>
              </FormGroup>
            </Col>
            {sliderCategory ?
             <Col md={4} xl={4}>
             <FormGroup className='form-group'>
               <Label for='transactionSubCategory'>Sub Category</Label>
                 <Select
                   isClearable
                   options={options}
                   onMenuScrollToBottom={() => {
                     if (hasNextPage && !isFetching) {
                       fetchNextPage();
                     }
                   }}
                   onChange={(selectedOption) => {
                    setSliderEvent('')
                    setSliderSubCategory(selectedOption)
                  }}
                   placeholder='Select Subcategory'
                   value={sliderSubCategory}
                 />
               </FormGroup>
             </Col> : ''}
            {sliderSubCategory ?  
            <Col md={4} xl={4}>
              <FormGroup className='form-group'>
                <Label for='sliderEvent'>Event</Label>
                <Select
                  isClearable
                  options={optionsEvent}
                  onMenuScrollToBottom={() => {
                    if (hasNextPageEvent && !isFetchingEvent) {
                      fetchNextPageEvent();
                    }
                  }}
                  onChange={(selectedOption) => setSliderEvent(selectedOption)}
                  placeholder='Select Event'
                  value={sliderEvent}
                />

              </FormGroup>
            </Col>
            : ''}
          </Row>
          :''}
          <Row>
            { screen === 'CR' && (
            <Col md={12} xl={12}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting'>
                  Sports
                  <RequiredField/>
                </Label>
                <Input
                  className={errSportsType ? 'league-placeholder-error' : 'form-control'}
                  disabled={adminPermission?.BANNER === 'R'}
                  name="sportsType"
                  onChange={event => handleChange(event, 'SportsType')}
                  type="select"
                  value={sportsType}
                >
                  <option value=''>Select Sports</option>
                  {sportsList && sportsList?.length !== 0 && sportsList?.map(sport =>
                    <option key={sport?._id} value={sport?.sName}>{sport?.sName}</option>)}
                </Input>
                <p className="error-text">{errSportsType}</p>
              </FormGroup>
            </Col>
            )}
          </Row>

          <Row>
            {screen === 'CR' && sportsType && (
            <Col md={12} xl={12}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting'>
                  Match List
                  <RequiredField/>
                </Label>
                {MatchList && MatchList?.length === 0
                  ? <InputGroupText>No Upcoming Match Available</InputGroupText>
                  : MatchList && MatchList?.length !== 0
                    ? (
                      <Input
                        className={errMatch ? 'league-placeholder-error' : 'form-control'}
                        disabled={adminPermission?.BANNER === 'R'}
                        name="match"
                        onChange={event => handleChange(event, 'Match')}
                        type="select"
                        value={Match}
                      >
                        <option value=''>Select Match</option>
                        {MatchList?.map(data =>
                          <option key={data?._id} value={data?._id}>{data?.sName}</option>)}
                      </Input>
                      )
                    : ''}
                <p className="error-text">{errMatch}</p>
              </FormGroup>
            </Col>
            )}
          </Row>

          <Row>
            {screen === 'CR' && sportsType && Match && MatchList?.length !== 0 && (
            <Col md={12} xl={12}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting'>Contest List</Label>
                {LeagueList && LeagueList?.length === 0
                  ? <InputGroupText>No Contest Available</InputGroupText>
                  : LeagueList && LeagueList?.length !== 0
                    ? (
                      <Input
                        className="form-control"
                        disabled={adminPermission?.BANNER === 'R'}
                        name="league"
                        onChange={event => handleChange(event, 'League')}
                        type="select"
                        value={League}
                      >
                        <option value=''>Select Contest</option>
                        {LeagueList?.map(league =>
                          <option key={league?._id} value={league?._id}>{league?.sName}</option>)}
                      </Input>
                      )
                    : ''}
              </FormGroup>
            </Col>
            )}
          </Row>

          <Row className='mt-2'>
            <Col md={12} xl={12}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="position">Position</Label>
                <Input
                  disabled={adminPermission?.BANNER === 'R'}
                  name="position"
                  onChange={event => handleChange(event, 'Position')}
                  placeholder="Position"
                  value={position}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row className='mt-3'>
            <Col md={12} xl={12}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="Info">
                  Description
                  {' '}
                  <RequiredField/>
                </Label>
                <div className={errDescription ? 'ck-border' : ''}>
                <CKEditor
                    className={errDescription ? 'league-placeholder-error' : 'form-control'}
                    editor={ClassicEditor}
                    config={{
                      placeholder: 'Enter content',
                      toolbar: {
                        items: [
                          'heading', '|', 'fontSize', 'fontFamily', '|', 'fontColor', 'fontBackgroundColor', '|',
                          'imageUpload', '|',
                          'bold', 'italic', 'underline', 'strikethrough', '|',
                          'alignment', '|', 'numberedList', 'bulletedList', '|', 'blockQuote',
                          'insertTable', 'mediaEmbed', '|', 'undo', 'redo', '|', 'htmlembed'
                        ]
                      },
                      plugins: [
                        Bold, Essentials, Italic, Mention, Paragraph, Undo, Heading, Font, Underline, Strikethrough, Alignment, List, Image, BlockQuote, Table, MediaEmbed, ImageUpload, ImageBlock, HtmlEmbed,Base64UploadAdapter
                      ]
                    }}
                    data={Description ? Description :""}
                    // disabled={adminPermission?.BANNER === 'R'}
                    onChange={onEditorChange}
                    onInit={(editor) => {
                      editor?.ui?.getEditableElement()?.parentElement?.insertBefore(editor?.ui?.view?.toolbar?.element, editor?.ui?.getEditableElement())
                    }}
                  />
                </div>
                <p className="error-text">{errDescription}</p>
              </FormGroup>
            </Col>
          </Row>

          <Row className='p-3 mt-2'>
            <div className='radio-button-div'>
              <Col md={12} xl={12}>
                <FormGroup className="form-group">
                  <Label className='edit-label-setting lable-league'>Status</Label>
                  <div className="d-flex inline-input mt-2">
                    <div className="custom-radio custom-control">
                      <Input
                        checked={bannerStatus === 'Y'}
                        disabled={adminPermission?.BANNER === 'R'}
                        id="bannerRadio1"
                        label="Active"
                        name="bannerRadio"
                        onChange={event => handleChange(event, 'Status')}
                        type="radio"
                        value="Y"
                        className='custom-control-input me-2'
                      />
                      <Label for="contentRadio1">Active</Label>
                    </div>
                    <div className="custom-radio custom-control">
                      <Input
                        checked={bannerStatus !== 'Y'}
                        disabled={adminPermission?.BANNER === 'R'}
                        id="bannerRadio2"
                        label="In Active"
                        name="bannerRadio"
                        onChange={event => handleChange(event, 'Status')}
                        type="radio"
                        value="N"
                        className='custom-control-input me-2'
                      />
                      <Label for="contentRadio2">In Active</Label>
                    </div>
                  </div>
                </FormGroup>
              </Col>
            </div>
          </Row>
        </Form>
      </section>
    </main>
  )
})

// AddSliderForm.defaultProps = {
//   history: {}
// }

AddSliderForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  }),
  match: PropTypes.object,
  isEdit: PropTypes.string,
  setIsEdit: PropTypes.func,
  // isCreate: PropTypes.string,
  // setIsCreate: PropTypes.func,
  adminPermission: PropTypes.object,
  Auth: PropTypes.string,
  setSubmitDisableButton: PropTypes.func
}

AddSliderForm.displayName = AddSliderForm
export default connect(null, null, null, { forwardRef: true })(AddSliderForm)
