import React, { useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Col, Input, Label, Modal, ModalBody, Row } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { io } from 'socket.io-client'
import PropTypes from 'prop-types'

import warningIcon from '../assets/images/error-warning.svg'

import Navbar from './Navbar'
import SocketContextProvider from '../context/SocketContextProvider'

import { declareOutComeEvent } from '../actions/events'

// import { useDispatch } from 'react-redux';
// import { FETCH_SOCKET } from '../actions/constants';
function Layout () {
  const location = useLocation()
  const navigate = useNavigate()
  const token = localStorage.getItem('Token')
  const { id } = useParams()
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const eventId = searchParams.get('eventId')
  const threadId = searchParams.get('threadId')
  const [modalWarning, setModalWarning] = useState(false)
  const [eventIdPopUp, setEventIdPopUp] = useState('')
  const toggleWarning = () => setModalWarning(!modalWarning)
  const [declareOutCome, setDeclareOutCome] = useState('')
  const [isOpenEvent, setIsOpenEvent] = useState(null);  // Ensure this state is defined

  const adminPermission = useSelector(state => state?.auth?.adminPermission)


  const socketQueryFn = () => {
    if (!token) {
      return Promise.reject(new Error("No token available"))
    }
    return io(process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_SOCKET_ENDPOINT_PROD : process.env.REACT_APP_SOCKET_ENDPOINT, {
      transports: ['websocket', 'polling', 'flashsocket'],
      auth: {
        Authorization: token,
      },
    })

  }

  const { data: socket } = useQuery({ queryKey: ['socketConnection', token], queryFn: socketQueryFn })
  useEffect(() => {
    if (token && socket) {
      if (socket.connected) {
        console.log('Socket is connected.');
      } else {
        console.log('Socket is not connected.');
        socket.connect();
      }

      socket.on("connect", () => {
        console.log('Socket connected successfully.');
      });

      socket.on("connect_error", (error) => {
        console.log("Connection Error:", error);
      });
      socket.on('update', (data) => {
        console.log('Received update event:', data);
      });
      socket.on("disconnect", (reason, details) => {
        console.log("%cDisconnected:", 'color: orange', reason, details);
        if (reason === 'io server disconnect' || reason === 'io client disconnect') {
          socket.connect();
        }
      });
      if (id || eventId) {
        socket.emit('message', {
          eType: 'SUBSCRIBE_ADMIN_EVENT_UPDATES',
          oData: { iEventId: id || eventId },
        }, (response) => {
          console.log('---------------- Live Update ----------------', response);
        });
      }
      if (threadId) {
        socket.emit('message', {
          eType: 'SUBSCRIBE_ADMIN_MSG_UPDATES',
          oData: { iThreadId: threadId },
        }, (response) => {
          console.log('---------------- Live Chat ----------------', response);
        });
      }
    }

    return () => {
      // if (socket) {
      //   socket.disconnect();
      // }
    };
  }, [location.pathname, navigate, socket, token]);
  useEffect(() => {
    if (socket) {
      socket.on('ADMIN_UPDATES', (data) => {
        if (data?.eType === 'EVENT_STATUS_UPDATE') {
          if (data?.oData?.eStatus === 'po') {
            setModalWarning(true)
            setEventIdPopUp(data?.oData)
          }
        }
      })
    }
  }, [socket])

  function handleSubmitOutCome () {
    if (declareOutCome) {
      const yesOption = isOpenEvent?.aAnswerOptions?.find(option => option?.sValue === declareOutCome);
      const outComeId = yesOption?._id;
      const eventId = isOpenEvent?._id
      // setDeclareOutComeErr('')
      dispatch(declareOutComeEvent({ eventId, outComeId }))
      setIsOpenEvent('')
      toggleWarning()
      setDeclareOutCome('')
    } else {
      // setDeclareOutComeErr('Required Field')
    }
  }
  return (
    <div className='main d-flex vh-100 overflow-hidden'>
      <SocketContextProvider socket={socket}>
        <div className='navbar-component flex-shrink-0'>
          <Navbar />
        </div>
        <div className='flex-grow-1'>
          <Outlet />
        </div>
        <Modal className="modal-confirm" isOpen={modalWarning} toggle={toggleWarning}>
          <ModalBody className='text-center'>
            <img alt='check' className='info-icon' src={warningIcon} />
            <h2 className='popup-modal-message'>Declare Outcome</h2>
            <div className="d-flex inline-input  text-center mb-2 ms-4">
              <Label className='me-2'>Declare Outcome : </Label>
              <div className='custom-radio custom-control'>
                <Input
                  type="radio"
                  defaultChecked={declareOutCome === 'YES'}
                  className='custom-control-input me-2'
                  disabled={adminPermission?.OT_EVENT === 'R'}
                  id="themeRadio1"
                  label="Yes"
                  name="themeRadio"
                  value={'YES'}
                  onChange={event => setDeclareOutCome(event.target?.value)}
                />
                <Label>Yes</Label>
              </div>
              <div className='custom-radio custom-control'>
                <Input
                  type="radio"
                  defaultChecked={declareOutCome === 'NO'}
                  className='custom-control-input me-2'
                  disabled={adminPermission?.OT_EVENT === 'R'}
                  id="themeRadio2"
                  label="No"
                  name="themeRadio"
                  value={'NO'}
                  onChange={event => setDeclareOutCome(event.target?.value)}
                />
                <Label>No</Label>
              </div>
            </div>
            <Link onClick={() => { setModalWarning(!modalWarning) }} className='total-text-link btn btn-link'
              to={eventIdPopUp?.iEventId ? { pathname: `/event-management/event-details/${eventIdPopUp?.iEventId}` } : { pathname: `/events/event-management` }} >
              {eventIdPopUp?.sName ? eventIdPopUp?.sName : 'Event'}
            </Link>
            <Row className='pt-4'>
              <Col>
                <Button className="theme-btn outline-btn-cancel full-btn-cancel m-0" style={{ display: 'inline' }} onClick={() => setModalWarning(!modalWarning)} type='submit'>Cancel</Button>
              </Col>
              <Col>
                <Button className='theme-btn danger-btn full-btn' onClick={handleSubmitOutCome} type='submit'>{`Save`}</Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </SocketContextProvider>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
