import React, { Fragment, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import MainHeading from '../../../Settings/Component/MainHeading'
import AddSubCategory from './AddSubCategory'

function AddSubCategoryIndex (props) {
  const [submitDisableButton, setSubmitDisableButton] = useState('')
  const [isCreate, setIsCreate] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const Auth = useSelector(
    (state) => state?.auth?.adminData && state?.auth?.adminData?.eType
  )
  const adminPermission = useSelector((state) => state?.auth?.adminPermission)
  const content = useRef()
  const page = JSON.parse(localStorage?.getItem('queryParams'))

  function onSubmit () {
    content?.current?.onSubmit()
  }
  return (
    <Fragment>
      <MainHeading
        EditSubCategory
        Auth={Auth}
        button={isCreate ? 'Add Sub Category' : !isEdit ? 'Save Changes' : 'Edit Sub Category'}
        adminPermission={adminPermission}
        cancelLink ={`/category-management/subcategory${page?.SubCategoryManagement || ''}`}
        heading={isCreate ? 'Add Sub Category' : !isEdit ? 'Edit Sub Category' : 'View Details'}
        onSubmit={onSubmit}
        submitDisableButton={submitDisableButton}
      />
      <div className='without-pagination'>
        <main className='main-content'>
          <section className='management-section'>
            <AddSubCategory
              {...props}
              ref={content}
              Auth={Auth}
              adminPermission={adminPermission}
              setSubmitDisableButton={setSubmitDisableButton}
              isCreate={isCreate}
              isEdit={isEdit}
              setIsCreate={setIsCreate}
              setIsEdit={setIsEdit}
            />
          </section>
        </main>
      </div>
    </Fragment>
  )
}
export default AddSubCategoryIndex
