
import axios from '../axios'
import { catchFunc } from '../helpers/helper'
import {CLEAR_STREAK_MESSAGE,UPDATE_STREAK} from './constants'

export const updateStreakData = (data) => async (dispatch) => {
    const {Title,amount,type,settingStatus,id,day} = data
    dispatch({ type: CLEAR_STREAK_MESSAGE })
    try {
    //   if (bannerImage && bannerImage.file) {
    //     const response = await axios.post('/statics/admin/banner/pre-signed-url/v1', { sFileName: bannerImage.file.name, sContentType: bannerImage.file.type, sportsType })
    //     const url = response.data.data.sUrl
    //     const sImage = response.data.data.sPath
    //     await axios.put(url, bannerImage.file, { headers: { 'Content-Type': bannerImage.file.type, noAuth: true } })
    //     await axios.put(`/statics/admin/banner/${bannerId}/v1`, {
    //       ePlace: place, sLink: Link, eType: bannerType, sDescription: Description, eStatus: bannerStatus, eScreen: screen, sImage, eCategory: sportsType, iMatchId: Match, iMatchLeagueId: League, nPosition: position
    //     }).then((response2) => {
    //       dispatch({
    //         type: UPDATE_BANNER,
    //         payload: {
    //           resMessage: response2.data.message,
    //           resStatus: true
    //         }
    //       })
    //     })
    //   } else {
        await axios.put(`/ot/admin/streak/${id}/v1`, {
            sTitle:Title,
            nAmount:amount,
            eType:type,
            eStatus:settingStatus,
            nDay:day,
          
        }).then((response) => {
          dispatch({
            type: UPDATE_STREAK,
            payload: {
              resMessage: response.data.message,
              resStatus: true
            }
          })
        })
    //   }
    } catch (error) {
      dispatch(catchFunc(UPDATE_STREAK, error))
    }
  }
  