import { useContext, useEffect, useRef, useState } from "react"
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query"
import ChatHeader from "../ChatHeader"
import ChatMessages from "./ChatMessages"
// import { useQueryState } from "react-router-use-location-state"
import getThread from "../../../api/SupportChat/getThread"
import getThreadMessages from "../../../api/SupportChat/getThreadMessages"
import SocketContext from "../../../context/SocketContext"

const ChatBar = (props) => {
  const { selectedThread } = props
  const threadId = selectedThread?._id
  // const [threadMessage, setThreadMessage] = useState()
  const socket = useContext(SocketContext)
  const queryClient = useQueryClient()
  // const messagesEndRef = useRef(null);
  const chatWindowRef = useRef(null);
  // const [messages, setMessages] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  
  const { data: threadHeader } = useQuery({
    queryKey: ['getThread', threadId],
    queryFn: () => getThread(threadId),
    select: (response) => response?.data?.data
  })
  const { 
    data:threadMessages, 
    isFetching:isFetchingNextPage, 
    fetchNextPage, 
    hasNextPage 
  } = useInfiniteQuery({
    queryKey: ['getThreadMessages', threadId],
    queryFn: ({ pageParam = 0 }) => getThreadMessages(threadId, pageParam, 10), 
    getNextPageParam: (lastPage, allPages) => {
      const totalMessages = lastPage?.data?.data?.total; 
      const fetchedMessages = allPages?.flatMap(page=>page?.data?.data?.results || []).length; 
      return fetchedMessages < totalMessages ? fetchedMessages : undefined; 
    },
    select: (data) => {
      if (!data?.pages) return []; 
      return data.pages.flatMap(page => page?.data?.data?.results || []).reverse();
    },
    keepPreviousData: true,
  });
  // useEffect(()=>{
  //   if(threadMessages?.length){
  //     setThreadMessage(threadMessages)
  //   }
  // },[threadMessages])
  // Scroll to bottom when new messages arrive
  // const scrollToBottom = () => {
  //   messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  // };
  // useEffect(() => {
  //   scrollToBottom(); 
  // }, [messages]);
    // Check when user scrolls up to load more messages
    const handleScroll = () => {
      if (chatWindowRef.current.scrollTop === 0 && hasNextPage && !isFetchingNextPage) {
        setIsFetching(true);
        fetchNextPage().finally(() => setIsFetching(false));
      }
    };
    useEffect(() => {
      const chatWindow = chatWindowRef.current;
      if (chatWindow) {
        chatWindow.addEventListener('scroll', handleScroll);
        return () => chatWindow.removeEventListener('scroll', handleScroll);
      }
    }, [hasNextPage, isFetchingNextPage]);


  useEffect(()=>{
    if (socket) {
    socket.on('ADMIN_UPDATES', (data) => {
      if(data?.eType==='MSG_UPDATES'){
        // setThreadMessage((prevMessages) => [...prevMessages, data?.oData]);
        queryClient?.invalidateQueries('getThreadMessages')
      }
    })
  }
  },[socket])
  return <>
    <ChatHeader selectedThread={selectedThread?.oUser?.sUsername}   thread={selectedThread}/>
    <ChatMessages
      threadMessages={threadMessages}
      thread={threadHeader}
      isFetching={isFetching}
      chatWindowRef={chatWindowRef}
    />
  </>
}
export default ChatBar