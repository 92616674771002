import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useQueryState } from 'react-router-use-location-state'
// import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
// import { DndContext, KeyboardSensor, MouseSensor, TouchSensor, closestCorners, useSensor, useSensors } from '@dnd-kit/core'
// import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

// component
// import SortableColumn from '../PassbookManagement/sortableColumn'

// api
// import { getUserList } from '../../../actions/users'
import EventListHeader from './Component/EventListHeader'
import EventListMainHeader from './Component/EventListMainHeader'
import EventList from './EventList'
import { dateFromTOdateTO } from '../../helpers/helper'
import { getEventList } from '../../actions/events'
import getCategoryList from '../../api/category/getCategoryList'
import getSubCategoryList from '../../api/category/getSubCategoryList'

function EventManagement (props) {
  const location = useLocation()
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState([])
  const [initialFlag, setinitialFlag] = useState(false)
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [platform, setPlatform] = useQueryState('ePlatform', '')
  const [utmSource, setUtmSource] = useQueryState('sUtmSource', '')
  // const [columns, setColumns] = useState()
  const [columnNames, setColumnNames] = useState()
  const [eventDropdown, setEventDropdown] = useQueryState('eOutcomeMethod', '')
  const [eventStatus,setEventStatus] = useQueryState('eStatus', '')
  const [categoryDropdown, setCategoryDropdown] = useQueryState('iCategory', '')
  const dateFlag = useRef(false)
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const resStatus = useSelector(state => state.events.resStatus)
  const resMessage = useSelector(state => state.events.resMessage)
  const eventList = useSelector((state) => state.events.eventList)
  const content = useRef()
  const [subCategoryId,setSubCategoryId] =useState()

  const { data: categoryList } = useQuery({
    queryKey: ['getCategoryList', 0, 50, '', 'Y'],
    queryFn: () => getCategoryList(0, 50, '', 'Y'),
    select: (res) => res?.data?.data?.results
  })

  const { 
    data, 
    isFetching, 
    fetchNextPage, 
    hasNextPage 
  } = useInfiniteQuery({
    queryKey: ['getSubCategoryList', 0, 10],
    queryFn: ({ pageParam = 0 }) => getSubCategoryList(pageParam, 10),
    getNextPageParam: (lastPage, allPages) => {
      const total = lastPage?.data?.data?.total;
      const nextPage = allPages.flatMap(page => page.data.data.results).length;
      return nextPage < total ? nextPage : undefined;
    },
    select: (res) => res?.pages?.flatMap(page => page?.data?.data?.results) || [],
    keepPreviousData: true,
  });
    useEffect(() => {
        const obj = qs.parse(location.search)
        setSearch(obj.searchvalue || '')
        setDateRange([obj.dStartDate ? new Date(obj.dStartDate) : null, obj.dEndDate ? new Date(obj.dEndDate) : null])
        setPlatform(obj.ePlatform || '')
        setUtmSource(obj.sUtmSource || '')
        getDefaultColumn()
    }, [location.search])

  // Function to handle search
  function onHandleSearch (e) {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
    setSearch(e.target.value)
    setinitialFlag(true)
  }

  // Function to handle other filters
  function handleOtherFilter (e, type) {
    if (type === 'platForm') {
      setPlatform(e?.target?.value)
    } else {
      setFilter(e || [])
    }
  }
  // Function to fetch list of users
  function getList (start, limit, sort, order, searchvalue, filterBy, startDate, endDate, platform, utmSource, isFullResponse) {
    const dateFrom = startDate ? new Date(moment(startDate).startOf('day').format()) : ''
    const dateTo = endDate ? new Date(moment(endDate).endOf('day').format()) : ''
    const eventData = { start, limit, sort, order, searchvalue: searchvalue.trim(), filterBy, startDate: dateFrom ? new Date(dateFrom).toISOString() : '', endDate: dateTo ? new Date(dateTo).toISOString() : '',  isFullResponse, token,eventDropdown,eventStatus ,categoryDropdown,subCategoryId}
    dispatch(getEventList(eventData))
  }

  // Function to export data
  function onExport () {
    content.current.onExport()
  }

  // Function to refresh data
  function onRefreshFun () {
    content.current.onRefresh()
  }

  // Function to set default columns for active users
  function getDefaultColumn () {
    const data = [
      { sColumnName: 'No.', bShow: true },
      { sColumnName: 'Status', bShow: true },
      { sColumnName: 'Name', bShow: true },
      { sColumnName: 'Category', bShow: true },
      { sColumnName: 'Start Date', bShow: true },
      { sColumnName: 'End Date', bShow: true },
      { sColumnName: 'Declare OutCome', bShow: true },
      { sColumnName: 'Actions', bShow: true },
    ]
    setColumnNames(data)
    // setColumns(data)
  }
  function eventFilter (e) {
    setEventDropdown(e?.target?.value)
  }
  function categoryFilter (e) {
    setCategoryDropdown(e?.target?.value)
  }
  function statusFilter (e) {
    setEventStatus(e?.target?.value)
  }
  return (
    <>
      <main className='main-content'>
        <section className='management-section common-box'>
          <EventListMainHeader 
            heading={'Events'}
            list={eventList}
            onExport={onExport}
            onRefresh={onRefreshFun}
            refresh='Refresh Event Data'
            events
          />
          <div className={eventList?.results?.length === 0 ? 'without-pagination' : 'setting-component'}>
            <EventListHeader
              hasNextPage={hasNextPage}
              isFetching={isFetching}
              fetchNextPage={fetchNextPage}
              subCategoryData={data}
              setSubCategoryId={setSubCategoryId}
              subCategoryId={subCategoryId}
              dateRange={dateRange}
              endDate={endDate}
              filter={filter}
              handleOtherFilter={handleOtherFilter}
              handleSearch={onHandleSearch}
              list={eventList}
              search={search}
              searchBox
              setDateRange={setDateRange}
              startDate={startDate}
              platform={platform}
              utmSource={utmSource}
              buttonText="Add Event"
              setUrl="/event-management/add-event"
              eventFilter={eventFilter}
              statusFilter={statusFilter}
              eventDropdown={eventDropdown}
              eventStatus={eventStatus}
              categoryFilter={categoryFilter}
              categoryDropdown={categoryDropdown}
              categoryList={categoryList}
              dateFlag={dateFlag}
            />
            <EventList
              {...props}
              ref={content}
              List={eventList}
              endDate={endDate}
              filter={filter}
              flag={initialFlag}
              getList={getList}
              resMessage={resMessage}
              resStatus={resStatus}
              search={search}
              setDateRange={setDateRange}
              setFilter={setFilter}
              setSearchProp={setSearch}
              setinitialFlag={setinitialFlag}
              startDate={startDate}
              platform={platform}
              setPlatform={setPlatform}
              dateFromTOdateTO={dateFromTOdateTO(dateRange)}
              utmSource={utmSource}
              setUtmSource={setUtmSource}
              columnNames={columnNames}
              eventFilter={eventFilter}
              eventDropdown={eventDropdown}
              eventStatus={eventStatus}
              statusFilter={statusFilter}
              categoryFilter={categoryFilter}
              categoryDropdown={categoryDropdown}
              subCategoryId={subCategoryId}
            />
          </div>
        </section>
      </main>
    </>

  )
}

EventManagement.propTypes = {
  location: PropTypes.object
}

export default EventManagement
