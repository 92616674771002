import axios from '../axios'
import { CHANGE_CHAT_STATUS, CLEAR_CHAT_MESSAGE, SEND_MESSAGE } from "./constants"
const errMsg = 'Server is unavailable.'

export const sendMessage = (sendMsgData) => async (dispatch) => {
    const { message,threadId } = sendMsgData
    dispatch({ type: CLEAR_CHAT_MESSAGE })
    try {
      await axios.post('/ot/admin/message/add/v1', {
        iThreadId:threadId,
        sMessage:message
      }).then((response) => {
        dispatch({
          type: SEND_MESSAGE,
          payload: {
            resMessage: response.data.message,
            resStatus: true
          }
        })
      })
    } catch (error) {
      dispatch({
        type: SEND_MESSAGE,
        payload: {
          resMessage: error.response ? error.response.data.message : errMsg,
          resStatus: false
        }
      })
    }
  }

export const changeStatus = (status,threadId) => async(dispatch)=>{
  // const { message,threadId } = sendMsgData
  dispatch({ type: CLEAR_CHAT_MESSAGE })
  try {
    await axios.put(`/ot/admin/thread/${threadId}/v1`, {
      eThreadStatus:status
    }).then((response) => {
      dispatch({
        type: CHANGE_CHAT_STATUS,
        payload: {
          resMessage: status==='A' ? 'Thread status updated to Active successfully.' : 'Thread status updated to Close successfully.',
          resStatus: true,
          data:response?.data?.data
        }
      })
    })
  } catch (error) {
    dispatch({
      type: CHANGE_CHAT_STATUS,
      payload: {
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  }
}