import React, { Fragment, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import UserStreakDetails from './UserStreakDetails'
import MainHeading from '../../Settings/Component/MainHeading'

function StreaksIndex (props) {
  const [Key, setKey] = useState('')
  const page = JSON?.parse(localStorage?.getItem('queryParams'))
  const [submitDisableButton, setSubmitDisableButton] = useState('')
  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData.eType)
  const adminPermission = useSelector(state => state?.auth?.adminPermission)
  const content = useRef()

  function onSubmit () {
    content?.current?.onSubmit()
  }
  function updateCurrencyData () {
    content?.current?.updateCurrencyData()
  }
  function imageSubmit (Key) {
    content?.current?.imageSubmit(Key)
  }
  return (
    <Fragment>
      <MainHeading
        AddSetting
        Auth={Auth}
        Key={Key}
        adminPermission={adminPermission}
        cancelLink={`/user-streak${page?.UserStreak || ''}`}
        heading= 'Edit Streak'
        imageSubmit={imageSubmit}
        onSubmit={onSubmit}
        submitDisableButton={submitDisableButton}
        updateCurrencyData={updateCurrencyData}
      />
      <UserStreakDetails
        {...props}
        ref={content}
        Auth={Auth}
        Key={Key}
        adminPermission={adminPermission}
        setKey={setKey}
        setSubmitDisableButton={setSubmitDisableButton}
      />
    </Fragment>
  )
}

export default StreaksIndex
