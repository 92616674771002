import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import pencilIcon from '../../../../assets/images/pencil-line.svg'

import { WithZero, isFloat, verifyLength } from '../../../../helpers/helper'
import { addAdminWithdraw } from '../../../../actions/users'

function AdminWithdraw (props) {
  const { setCancel, setLoading, setEditAdminWithdraw, isEditAdminWithdraw } = props
  const { id } = useParams()

  const [errAmount, setErrAmount] = useState('')
  const [amount, setAmount] = useState(0)
  const [WithDrawPassword, setWithDrawPassword] = useState('')
  const [ErrWithdrawPassword, setErrWithdrawPassword] = useState('')
  const [withdrawType, setWithdrawType] = useState('withdraw')
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const adminPermission = useSelector(state => state?.auth?.adminPermission)
  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)
  const token = useSelector(state => state?.auth?.token)
  const kycDetails = useSelector(state => state?.kyc?.kycDetails)

  // function handle adminWithdraw
  function funcAddAdminWithdraw () {
    if (isFloat(amount)) {
      dispatch(addAdminWithdraw(id, withdrawType, amount, WithDrawPassword, token))
      setWithdrawType('withdraw')
      setAmount(0)
      setWithDrawPassword('')
      setLoading(true)
    } else {
      if (!amount) {
        setErrAmount('Required field')
      }
      if (!verifyLength(WithDrawPassword, 1)) {
        setErrWithdrawPassword('Required field')
      }
    }
  }

  function onEditAdminWithdraw () {
    if (!isEditAdminWithdraw) {
      setEditAdminWithdraw(true)
    }
  }

  function cancelFunc (type) {
    if (type === 'admin_withdraw') {
      setEditAdminWithdraw(false)
      setAmount('')
      setWithDrawPassword('')
      setErrAmount('')
      setErrWithdrawPassword('')
    }
    setCancel(true)
  }

  function handleChange (event, eType) {
    switch (eType) {
      case 'WithdrawType':
        setWithdrawType(event?.target?.value)
        break
      case 'Amount':
        if (!event?.target?.value) {
          setErrAmount('Required field')
        } else if (!isFloat(event?.target?.value)) {
          if (isNaN(parseFloat(event?.target?.value))) {
            setErrAmount('Enter number only')
          } else {
            setErrAmount('Must be 2 floating point value only')
          }
        } else if (isFloat(event?.target?.value)) {
          setErrAmount('')
        } else if (WithZero(event?.target?.value)) {
          setErrAmount('Required field')
        }
        setAmount(event?.target?.value)
        break
      case 'WithdrawPassword':
        if (verifyLength(event?.target?.value, 1)) {
          setErrWithdrawPassword('')
        } else {
          setErrWithdrawPassword('Required field')
        }
        setWithDrawPassword(event?.target?.value)
        break
      default:
        break
    }
  }
  return (
    <>
      <Col className='pr-0 admin-withdraw-div' md={12} sm={12} xl={6}>
        {
                  ((Auth && Auth === 'SUPER') || (adminPermission?.WITHDRAW === 'W')) &&
                  (
                    <Fragment>
                      <div className='common-box-user admin-withdraw'>
                        {
                            ((Auth && Auth === 'SUPER') || (adminPermission?.WITHDRAW !== 'R')) &&
                            (
                              <div className='align-items-start user-heading'>
                                <h3>Admin Withdraw</h3>
                                <div className='d-flex'>
                                  <Button className='cash-bonus-button mx-2'  onClick={() => navigate(`/transaction/withdraw-management?iUserId=${id}&isFromUserDetail=true`)}>Show User Withdraw</Button>
                                  <div className={isEditAdminWithdraw ? 'default-edit  d-flex align-item-center' : 'user-edit-button'} hidden={ ['D'].includes(kycDetails && kycDetails?.eStatus)}>
                                    <div className='d-flex align-items-center'>
                                      {(!isEditAdminWithdraw &&
                                      <img alt="" className='mr-2' src={pencilIcon}/>
                                       )}
                                      {' '}
                                      <Button  onClick={isEditAdminWithdraw ? funcAddAdminWithdraw : onEditAdminWithdraw} className={isEditAdminWithdraw ? 'user-Edit-button' : 'button'} >{isEditAdminWithdraw ? 'Save' : 'Edit'}</Button>
                                    </div>
                                    {isEditAdminWithdraw && <Button style={{ textDecoration: 'none' }}  className='ml-1 user-cancel-button' color='link' onClick={() => cancelFunc('admin_withdraw')}>Cancel</Button>}
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        <Row className='p-4'>
                          <Col className='p-0' md={12} xl={12} xs={12}>
                            <FormGroup className='d-flex user-radio-button align-items-center'>
                              <Label className='edit-label-setting m-0' for='adminWithdraw'>From Balance</Label>
                              <div className='d-flex inline-input'>
                              <div className='custom-radio custom-control'>
                                <Input
                                  checked={withdrawType === 'withdraw'}
                                  disabled={!isEditAdminWithdraw}
                                  id='withdraw123'
                                  label='Deposit'
                                  name='withdrawType'
                                  onChange={event => handleChange(event, 'WithdrawType')}
                                  type='radio'
                                  value='withdraw'
                                   className='custom-control-input me-2'
                                />
                                <Label className='custom-control-label'>Deposit</Label>
                                </div>
                                <div className='custom-radio custom-control'>
                                <Input
                                  checked={withdrawType === 'winning'}
                                  disabled={!isEditAdminWithdraw}
                                  id='winning123'
                                  label='Winning'
                                  name='withdrawType'
                                  onChange={event => handleChange(event, 'WithdrawType')}
                                  type='radio'
                                  value='winning'
                                   className='custom-control-input me-2'
                                />
                                <Label className='custom-control-label'>Winning</Label>
                                </div>
                                <div className='custom-radio custom-control'>
                                <Input
                                  checked={withdrawType === 'bonus'}
                                  disabled={!isEditAdminWithdraw}
                                  id='bonusAmount'
                                  label='Bonus'
                                  name='withdrawType'
                                  onChange={event => handleChange(event, 'WithdrawType')}
                                  type='radio'
                                  value='bonus'
                                   className='custom-control-input me-2'
                                />
                                <Label className='custom-control-label'>Bonus</Label>
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col className=' px-4' md={12} xl={12} xs={12}>
                            <FormGroup className='form-group'>
                              <Label className='edit-label-setting' for='Amount'>Amount</Label>
                              <Input className={errAmount ? 'league-placeholder-error' : 'league-placeholder'} disabled={!isEditAdminWithdraw} id='Amount' onChange={event => handleChange(event, 'Amount')} placeholder='Enter Amount' type='number' value={amount} />
                              <p className='error-text'>{errAmount}</p>
                            </FormGroup>
                          </Col>

                          <Col className='my-3 px-4' md={12} xl={12} xs={12}>
                            <FormGroup className='form-group'>
                              <Label className='edit-label-setting' for='WPassword'>Password</Label>
                              <Input className={ErrWithdrawPassword ? 'league-placeholder-error' : 'league-placeholder'} disabled={!isEditAdminWithdraw} id='WPassword' onChange={event => handleChange(event, 'WithdrawPassword')} placeholder='Enter Password' type='password' value={WithDrawPassword} />
                              <p className='error-text'>{ErrWithdrawPassword}</p>
                            </FormGroup>
                          </Col>

                        </Row>
                      </div>
                    </Fragment>
                  )
                }
      </Col>
    </>
  )
}
AdminWithdraw.propTypes = {
  setLoading: PropTypes.func,
  setCancel: PropTypes.func,
  handleChange: PropTypes.func,
  isEditAdminWithdraw: PropTypes.bool,
  setEditAdminWithdraw: PropTypes.func

}

export default AdminWithdraw
