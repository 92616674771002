
import { Link, useLocation } from "react-router-dom"
import DataNotFound from "../../../components/DataNotFound";
import qs from 'query-string'

const PendingOrders = (props) => {
    const {eventPendingOrders} = props
    const location = useLocation()
    const obj = qs?.parse(location?.search)
    // const [dayFilter, setDayFilter] = useState('month')
    // const [categoryFilter, setCategoryFilter] = useState('C')
    // function onFiltering(event) {
    //     setDayFilter(event.target.value)
    // }
    // function onFilterCategory(event) {
    //     setCategoryFilter(event.target.value)
    // }
    const calculateSum = (orders) => {
        let count = 0
        if(orders?.length) {
            orders?.map((i)=>{
                return count +=i?.aOrdersBySymbol?.reduce((acc, curr) => acc + curr.nQty, 0)
            })
        }
        return count
      };
    return <>
        <div className='revenue-details-heading'>
            <h1 >Pending Orders</h1>
            {/* <FormGroup>
            <Input
                className='day-filter'
                id="dayFilter"
                name="dayFilter"
                onChange={(event) => onFiltering(event)}
                type="select"
                value={dayFilter}
            >
                <option value="today">Today</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
            </Input>
            </FormGroup>
            <FormGroup>
            <Input
                className='day-filter'
                id="dayFilter"
                name="dayFilter"
                onChange={(event) => onFilterCategory(event)}
                type="select"
                value={categoryFilter}
            >
                <option value="C">{CategoryLabels?.C}</option>
                <option value="S">{CategoryLabels?.S}</option>
                <option value="CR">{CategoryLabels?.CR}</option>
                <option value="IPO">{CategoryLabels?.IPO}</option>
                <option value="GT">{CategoryLabels?.GT}</option>
                <option value="N">{CategoryLabels?.N}</option>
            </Input>
            </FormGroup> */}
        </div>
        <div className='table-represent ps-0 pe-0'>
            <div className="table-responsive" style={{height:'500px',background:'transparent'}}>
                <table className="content-table">
                    <thead>
                        <tr>
                            <th>Event</th>
                            <th>Category</th>
                            <th>Orders</th>
                        </tr>
                    </thead>
                    <tbody>
                        {eventPendingOrders?.length>0 ? eventPendingOrders?.map((event)=>{
                        return <tr>
                            <td>
                                <Link 
                                to={`/event-management/event-details/${event?.iEventId}?orderStatus=PENDING`}
                                 >
                                    {event?.sName || '--'}
                                </Link>
                            </td>
                            <td>{event?.eCategoryType === 'IPO' ? 'IPO' : event?.eCategoryType === 'CR' ? 'Crypto' : event?.eCategoryType === 'C' ? 'Cricket' : event?.eCategoryType === 'S' ? 'Stock' : event?.eCategoryType === 'N' ? 'News' : 'General Trading'}</td>
                            <td><span className="red-dot" style={{ backgroundColor: 'red' }}></span>
                            {/* {event?.aPendingOrders?.length ? event.aPendingOrders.length : 0}  */}
                            {calculateSum(event?.aPendingOrders)}</td>
                        </tr>
                        }) :
                            <tr>
                                <td colSpan="3" style={{ textAlign: 'center' }}>
                                    <DataNotFound message="Pending Orders" obj={obj} />
                                </td>
                            </tr>
                        }
                      
                    </tbody>
                </table>
            </div>
        </div>
    </>

}
export default PendingOrders