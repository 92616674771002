import React, { Fragment, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useQueryState } from 'react-router-use-location-state'
import { Modal, ModalBody, Row, Col, Button } from 'reactstrap'
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import qs from 'query-string'
import PropTypes from 'prop-types'

import editButton from '../../assets/images/edit-pen-icon.svg'
import warningIcon from '../../assets/images/error-warning.svg'
import noImage from '../../assets/images/no-image-1.svg'

import Loading from '../../components/Loading'
import DataNotFound from '../../components/DataNotFound'
import AlertMessage from '../../components/AlertMessage'
import SkeletonTable from '../../components/SkeletonTable'
import PaginationComponent from '../../components/PaginationComponent'
import { modalMessageFunc } from '../../helpers/helper'
import { getUrl } from '../../actions/url'
import updateUserXPDetails from '../../api/UserProfileManagement/updateUserXPDetails'
import getUserXPList from '../../api/UserProfileManagement/getUserXPList'

const UserXpManagementList = forwardRef((props, ref) => {
	const { profileLevelList, start, setStart, offset, setOffset, setSearch, isLoading, isFullList, eCategory, setECategory } = props
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const location = useLocation()
	const exporter = useRef(null)
	const dispatch = useDispatch()

	const [total, setTotal] = useState(0)
	const [list, setList] = useState([])
	const [activePageNo, setPageNo] = useQueryState('page', 1)
	const [startingNo, setStartingNo] = useState(0)
	const [endingNo, setEndingNo] = useState(0)
	const [index, setIndex] = useState(1)
	const [message, setMessage] = useState('')
	const [status, setStatus] = useState('')
	const [listLength, setListLength] = useState('10 Rows')
	const [type] = useState('')
	const [selectedData] = useState({})
	const [close, setClose] = useState(false)
	const [modalMessage, setModalMessage] = useState(false)
	const [modalWarning, setModalWarning] = useState(false)
	const [fullList, setFullList] = useState([])
	const [url, setUrl] = useState('')
	const toggleWarning = () => setModalWarning(!modalWarning)

	const token = useSelector(state => state?.auth?.token)
	const resStatus = useSelector((state) => state?.userXp?.resStatus)
	const resMessage = useSelector((state) => state?.userXp?.resMessage)
	const getUrlLink = useSelector(state => state?.url?.getUrl)
	const obj = qs?.parse(location?.search)
	const searchProp = props?.search
	const previousProps = useRef({ resMessage, resStatus, profileLevelList, searchProp, start, offset })?.current
	const paginationFlag = useRef(false)

	const { mutate: updateSettingFun, isLoading: loader } = useMutation(updateUserXPDetails, {
		onSuccess: (res) => {
			setMessage(res?.data?.message)
			setModalMessage(true)
			setStatus(true)
			queryClient?.invalidateQueries('getUserXPList')
		}
	})
	useEffect(() => {
		if (getUrlLink) {
			setUrl(getUrlLink)
		}
	}, [getUrlLink])
	useEffect(() => {
		if (location?.state) {
			if (location?.state?.message) {
				setMessage(location?.state?.message)
				setStatus(true)
				setModalMessage(true)
			}
			navigate(location?.pathname, { replace: true })
		}

		let page = 1
		let limit = offset
		if (obj) {
			if (obj?.page) {
				page = obj?.page
				setPageNo(page)
			}
			if (obj?.pageSize) {
				limit = obj?.pageSize
				setOffset(limit)
				setListLength(`${limit} Rows`)
			}
		}
		const startFrom = (page - 1) * offset
		dispatch(getUrl('media'))
		setStart(startFrom)
	}, [])
	//  set profileLevelList
	useEffect(() => {
		if (profileLevelList?.results && !isFullList?.current) {
			const userArrLength = profileLevelList?.results?.length
			const startFrom = ((activePageNo - 1) * offset) + 1
			const end = startFrom - 1 + userArrLength
			setIndex(activePageNo)
			setStartingNo(startFrom)
			setEndingNo(end)
			setList(profileLevelList?.results)
			setTotal(profileLevelList?.total ? profileLevelList?.total : 0)
		} else if (isFullList.current) {
			setFullList(profileLevelList?.results ? profileLevelList?.results : [])
			setIndex(activePageNo)
			setTotal(profileLevelList?.total ? profileLevelList?.total : 0)
			isFullList.current = false
		}
	}, [profileLevelList?.results, isFullList?.current])

	useEffect(() => {
		modalMessageFunc(modalMessage, setModalMessage, setClose)
	}, [modalMessage])

	// to handle response
	useEffect(() => {
		if (previousProps?.resMessage !== resMessage) {
			if (resMessage) {
				if (resStatus) {
					setMessage(resMessage)
					setStatus(resStatus)
					setModalWarning(false)
					setModalMessage(true)
					setPageNo(activePageNo)
				} else {
					setMessage(resMessage)
					setStatus(resStatus)
					setModalWarning(false)
					setModalMessage(true)
				}
			}
		}
		return () => {
			previousProps.resMessage = resMessage
		}
	}, [resStatus, resMessage])

	// to handle query params
	useEffect(() => {
		let data = localStorage?.getItem('queryParams')
			? JSON?.parse(localStorage?.getItem('queryParams'))
			: {}
		!Object?.keys(data)?.length
			? (data = { UserXPManagement: location?.search })
			: (data.UserXPManagement = location?.search)
		localStorage?.setItem('queryParams', JSON?.stringify(data))
	}, [location?.search])

	// will be called when something searched
	useEffect(() => {
		const callSearchService = () => {
			const startFrom = 0
			setSearch(searchProp?.trim())
			setStart(startFrom)
			setPageNo(1)
		}
		if (previousProps?.searchProp !== searchProp && props?.flag) {
			const deBouncer = setTimeout(() => {
				callSearchService()
			}, 1000)
			return () => {
				clearTimeout(deBouncer)
				previousProps.searchProp = searchProp
			}
		}
		return () => {
			previousProps.searchProp = searchProp
		}
	}, [searchProp])

	useEffect(() => {
		if (previousProps?.eCategory !== eCategory) {
			setECategory(eCategory)
			const startFrom = 0
			setStart(startFrom)
			setPageNo(1)
		}
		return () => {
			previousProps.eCategory = eCategory
		}
	}, [eCategory])

	// function warningWithConfirmMessage(data, eType) {
	//     setType(eType)
	//     setSelectedData(data)
	//     setModalWarning(true)
	// }

	function onCancel () {
		toggleWarning()
	}

	// update status from list
	function onStatusUpdate () {
		const status = selectedData?.eStatus === 'Y' ? 'N' : 'Y'
		const updateSettingData = {
			sName: selectedData?.sName,
			sImage: selectedData?.sImage,
			sHexCode: selectedData?.sHexCode,
			sDescription: selectedData?.sDescription,
			oRules: selectedData?.oRules,
			oCriteria: selectedData?.oCriteria,
			nLevel: selectedData?.nLevel,
			dCreatedAt: selectedData?.dCreatedAt,
			eStatus: status
		}
		const settingId = selectedData?._id
		updateSettingFun({ updateSettingData, settingId, token })
		toggleWarning()
	}
	// Export Excel Report List
	const processExcelExportData = (data) => data?.map((profileLevelList, index) => {
		const srNo = index + 1
		let eStatus = profileLevelList?.eStatus
		eStatus = eStatus === 'Y' ? 'Active' : 'InActive'
		let dCreatedAt = moment(profileLevelList?.dCreatedAt)?.local()?.format('MM/DD/YYYY hh:mm A')
		dCreatedAt = dCreatedAt === 'Invalid date' ? ' - ' : dCreatedAt
		const sDescription = profileLevelList?.sDescription || '--'
		const sName = profileLevelList?.sName || '--'
		const nLevel = profileLevelList?.nLevel || '--'
		const nMinXP = profileLevelList?.oCriteria?.nMinXP || '--'
		const eCommissionFeeType = profileLevelList?.oRules?.eCommissionFeeType || '--'
		const nCommission = profileLevelList?.oRules?.nCommission || '--'
		const nDailyWithdrawCount = profileLevelList?.oRules?.nDailyWithdrawCount || '--'
		const nDailyWithdrawLimit = profileLevelList?.oRules?.nDailyWithdrawLimit || '--'
		return {
			...profileLevelList,
			srNo,
			dCreatedAt,
			sName,
			eStatus,
			sDescription,
			nLevel,
			nMinXP,
			eCommissionFeeType,
			nCommission,
			nDailyWithdrawCount,
			nDailyWithdrawLimit
		}
	})
	// start, offset, sort, 'asc', search, eCategory, isFullList.current
	const exportMutation = useMutation(() => getUserXPList(0, 10, 'sName', '', '', '', true), {
		onSuccess: (data) => {
			if (data?.data?.data[0]?.results) {
				exporter.current.props = {
					...exporter?.current?.props,
					data: processExcelExportData(data?.data?.data[0]?.results || []),
					fileName: 'UserProfile.xlsx'
				}
				exporter?.current?.save()
			}
		}
	})

	async function onExport () {
		exportMutation?.mutate()
	}

	useImperativeHandle(ref, () => ({
		onExport
	}))
	
	return (
		<Fragment>
			<ExcelExport ref={exporter} data={fullList && fullList?.length > 0 ? fullList : list} fileName='UserProfile.xlsx'>
				<ExcelExportColumn field='srNo' title='Sr No' />
				{/* <ExcelExportColumn field='eStatus' title='Status' /> */}
				<ExcelExportColumn field='sName' title='Name' />
				<ExcelExportColumn field='sDescription' title='Description' />
				<ExcelExportColumn field='nLevel' title='Level' />
				<ExcelExportColumn field='nMinXP' title='Minimum' />
				<ExcelExportColumn field='eCommissionFeeType' title='Commission Type' />
				<ExcelExportColumn field='nCommission' title='Commission' />
				<ExcelExportColumn field='nDailyWithdrawCount' title='Daily WIthdrawal Count' />
				<ExcelExportColumn field='nDailyWithdrawLimit' title='Daily WIthdrawal Limit' />
			</ExcelExport>
			{!isLoading && list?.length === 0
				? (<DataNotFound message="Settings" obj={obj} />)
				: (
					<div className='table-represent'>
						<div className='table-responsive'>
							<AlertMessage
								close={close}
								message={message}
								modalMessage={modalMessage}
								status={status}
							/>
							{loader && <Loading />}
							<table className='table'>
								<thead>
									<tr>
										<th>No.</th>
										<th>Image</th>
										<th>HexCode</th>
										<th>Name</th>
										<th>Description</th>
										<th>Level</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{isLoading
										? (<SkeletonTable numberOfColumns={7} />)
										: (
											<Fragment>
												{list && list?.length !== 0 && list?.map((data, i) => (
													<tr key={data?._id}>
														<td>{(((index - 1) * offset) + (i + 1))}</td>
														<td>
															{data?.sImage
																? <img alt="banner" className="theme-image-offer" src={url + data?.sImage} width={56} height={56} />
																: <img alt="NoImg" className='theme-image-offer' src={noImage} />}
														</td>
														<td>{data?.sHexCode || '--'}</td>
														<td>{data?.sName}</td>
														<td>{data?.sDescription || '--'}</td>
														<td>{data?.nLevel || '--'}</td>

														<td>
															<ul className='action-list mb-0 d-flex'>
																<li>
																	<NavLink className='view' color='link' to={'/user-profile-level/add-level/' + data?._id}>
																		<Button className='edit-btn-icon'>
																			<img alt="View" src={editButton} />
																		</Button>
																	</NavLink>
																</li>
															</ul>
														</td>
													</tr>
												))}
											</Fragment>
										)}
								</tbody>
							</table>
						</div>
					</div>
				)
			}

			{list?.length !== 0 && (
				<PaginationComponent
					activePageNo={activePageNo}
					endingNo={endingNo}
					listLength={listLength}
					offset={offset}
					paginationFlag={paginationFlag}
					setListLength={setListLength}
					setOffset={setOffset}
					setPageNo={setPageNo}
					setStart={setStart}
					startingNo={startingNo}
					total={total}
				/>
			)}

			<Modal className="modal-confirm" isOpen={modalWarning} toggle={toggleWarning}>
				<ModalBody className='text-center'>
					<img alt='check' className='info-icon' src={warningIcon} />
					<h2 className='popup-modal-message'>{`Are you sure you want to ${type} it?`}</h2>
					<Row className='row-12'>
						<Col>
							<Button className='theme-btn outline-btn-cancel full-btn-cancel' onClick={onCancel} type='submit'>Cancel</Button>
						</Col>
						<Col>
							<Button className='theme-btn danger-btn full-btn' onClick={onStatusUpdate} type='submit'>{`${type} It`}</Button>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		</Fragment>
	)
})

UserXpManagementList.propTypes = {
	search: PropTypes.string,
	location: PropTypes.object,
	history: PropTypes.object,
	flag: PropTypes.bool,
	getList: PropTypes.func,
	profileLevelList: PropTypes.object,
	start: PropTypes.number,
	setStart: PropTypes.func,
	offset: PropTypes.number,
	setOffset: PropTypes.func,
	setSearch: PropTypes.func,
	isLoading: PropTypes.func,
	sort: PropTypes.string,
	isFullList: PropTypes.string,
	setIsFullResponse: PropTypes.func,
	refetch: PropTypes.func,
	isSuccess: PropTypes.bool,
	eCategory: PropTypes.string,
	setECategory: PropTypes.func
}

UserXpManagementList.displayName = UserXpManagementList
export default connect(null, null, null, { forwardRef: true })(UserXpManagementList)
