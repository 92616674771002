import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import pencilIcon from '../../../../assets/images/pencil-line.svg'

import { WithZero, isFloat, verifyLength } from '../../../../helpers/helper'
import { addAdminDeposit } from '../../../../actions/users'

function AdminDeposit (props) {
  const { setLoading, setCancel, setEditAdminDeposit, isEditAdminDeposit } = props
  const { id } = useParams()

  const [balance, setBalance] = useState('deposit')
  const [balanceType, setBalanceType] = useState('cash')
  const [cash, setCash] = useState(0)
  const [bonus, setBonus] = useState(0)
  const [DepositPassword, setDepositPassword] = useState('')
  const [errCash, setErrCash] = useState('')
  const [errBonus, setErrBonus] = useState('')
  const [ErrDepositPassword, setErrDepositPassword] = useState('')
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const adminPermission = useSelector(state => state?.auth?.adminPermission)
  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)
  const token = useSelector(state => state?.auth?.token)
  const kycDetails = useSelector(state => state.kyc.kycDetails)

  // const previousProps = useRef({ BalanceDetails })?.current

  // function handle adminDeposit
  function addAdminDepo () {
    if (isFloat(cash) && isFloat(bonus) && DepositPassword) {
      dispatch(addAdminDeposit(id, balance, balanceType, cash, bonus, DepositPassword, token))
      setBalance('deposit')
      setBalanceType('cash')
      setCash(0)
      setBonus(0)
      setDepositPassword('')
      setLoading(true)
    } else {
      if (balanceType === 'cash' && !cash) {
        setErrCash('Required field')
      }
      if (balanceType === 'bonus' && !bonus) {
        setErrBonus('Required field')
      }
      if (!verifyLength(DepositPassword, 1)) {
        setErrDepositPassword('Required field')
      }
    }
  }

  function onEditAdminDeposit () {
    if (!isEditAdminDeposit) {
      setEditAdminDeposit(true)
    }
  }

  function cancelFunc (type) {
    if (type === 'admin_deposit') {
      setEditAdminDeposit(false)
      setCash(0)
      setBonus(0)
      setDepositPassword('')
      setErrCash('')
      setErrBonus('')
      setErrDepositPassword('')
    }
    setCancel(true)
  }

  function handleChange (event, eType) {
    switch (eType) {
      case 'Deposit_Type':
        setBalanceType(event?.target?.value)
        break
      case 'Balance':
        setBalance(event?.target?.value)
        break
      case 'Cash':
        if (!event?.target?.value) {
          setErrCash('Required field')
        } else if (!isFloat(event?.target?.value)) {
          if (isNaN(parseFloat(event?.target?.value))) {
            setErrCash('Enter number only')
          } else {
            setErrCash('Must be 2 floating point value only')
          }
        } else if (isFloat(event?.target?.value)) {
          setErrCash('')
        } if (WithZero(event?.target?.value)) {
          setErrCash('Required field')
        }
        setCash(event?.target?.value)
        break
      case 'Bonus':
        if (!event?.target?.value) {
          setErrBonus('Required field')
        } else if (!isFloat(event?.target?.value)) {
          if (isNaN(parseFloat(event?.target?.value))) {
            setErrBonus('Enter number only')
          } else {
            setErrBonus('Must be 2 floating point value only')
          }
        } else if (isFloat(event?.target?.value)) {
          setErrBonus('')
        }
        if (WithZero(event?.target?.value)) {
          setErrBonus('Required field')
        }
        setBonus(event?.target?.value)
        break
      case 'DepositPassword':
        if (verifyLength(event?.target?.value, 1)) {
          setErrDepositPassword('')
        } else {
          setErrDepositPassword('Required field')
        }
        setDepositPassword(event?.target?.value)
        break
      default:
        break
    }
  }
  return (
    <>
      <Col className='pl-0 admin-deposit-div' md={12} sm={12} xl={6}>
        {
          ((Auth && Auth === 'SUPER') || (adminPermission?.DEPOSIT === 'W')) &&
          (
            <Fragment>
              <div className='common-box-user'>
                {
                  ((Auth && Auth === 'SUPER') || (adminPermission?.DEPOSIT === 'W')) &&
                  (
                    <div className='align-items-start user-heading'>
                      <h3>Admin Deposit</h3>
                      <div className='d-flex'>
                        <Button className='cash-bonus-button mx-2' onClick={() => navigate(`/transaction/deposit-management?iUserId=${id}&isFromUserDetail=true`)}>Show User Deposit</Button>
                        <div className={isEditAdminDeposit ? 'default-edit d-flex align-item-center' : 'user-edit-button'} hidden={['D'].includes(kycDetails && kycDetails?.eStatus)}>
                          <div className='d-flex align-items-center' >
                            {(!isEditAdminDeposit &&
                            <img alt="" className='mr-2' src={pencilIcon} />
                          )}
                            {' '}
                            <Button onClick={isEditAdminDeposit ? addAdminDepo : onEditAdminDeposit} className={isEditAdminDeposit ? 'user-Edit-button' : 'button'} >{isEditAdminDeposit ? 'Save' : 'Edit'}</Button>
                          </div>
                          {isEditAdminDeposit && <Button style={{ textDecoration: 'none' }} className='ml-1 user-cancel-button' color='link' onClick={() => cancelFunc('admin_deposit')}>Cancel</Button>}
                        </div>
                      </div>
                    </div>
                  )
                }
                <Row>
                  <Col className='p-4' md={12} xl={12} xs={12}>
                    <FormGroup className='d-flex user-radio-button'>
                      <Label className='edit-label-setting mb-0' for='adminDeposit'>Type</Label>
                      <div className='d-flex inline-input'>
                        <div className='custom-radio custom-control'>
                        <Input className='custom-control-input me-2' checked={balanceType === 'cash'} disabled={!isEditAdminDeposit} id='cash' label='Cash' name='balanceType' onChange={event => handleChange(event, 'Deposit_Type')} type='radio' value='cash' />
                        <Label className='custom-control-label'>Cash</Label>
                        </div>
                        <div className='custom-radio custom-control'>
                        <Input className='custom-control-input me-2' checked={balanceType === 'bonus'} disabled={!isEditAdminDeposit} id='bonus' label='Bonus' name='balanceType' onChange={event => handleChange(event, 'Deposit_Type')} type='radio' value='bonus' />
                        <Label className='custom-control-label'>Bonus</Label>                
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className='px-4' md={12} xl={12} xs={12}>
                    <FormGroup className='form-group'>
                      <Label className='edit-label-setting' for='Type'>To Balance</Label>
                      <Input className='form-control' disabled={!isEditAdminDeposit || balanceType === 'bonus'} id='balance' name='balance' onChange={event => handleChange(event, 'Balance')} type='select' value={balance}>
                        <option value='deposit'>Deposit</option>
                        <option value='winning'>Winning</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  {
                    balanceType === 'cash'
                      ? (
                        <Col className='mt-3 px-4' md={12} xl={12} xs={12}>
                          <FormGroup className='form-group'>
                            <Label className='edit-label-setting' for='Cash'>Cash</Label>
                            <Input className={errCash ? 'league-placeholder-error' : 'league-placeholder'} disabled={!isEditAdminDeposit} id='Cash' onChange={event => handleChange(event, 'Cash')} placeholder='Enter Cash' type='number' value={cash.toString()} />
                            <p className='error-text'>{errCash}</p>
                          </FormGroup>
                        </Col>
                        )
                      : balanceType === 'bonus'
                        ? (
                          <Col className='mt-3 px-4' md={12} xl={12} xs={12}>
                            <FormGroup className='form-group'>
                              <Label className='edit-label-setting' for='Bonus'>Bonus</Label>
                              <Input className={errBonus ? 'league-placeholder-error' : 'league-placeholder'} disabled={!isEditAdminDeposit} id='Bonus' onChange={event => handleChange(event, 'Bonus')} placeholder='Enter Bonus' type='number' value={bonus.toString()} />
                              <p className='error-text'>{errBonus}</p>
                            </FormGroup>
                          </Col>
                          )
                        : ''
                  }
                  <br />
                  <Col className='my-3 px-4' md={12} xl={12} xs={12}>
                    <FormGroup className='form-group'>
                      <Label className='edit-label-setting' for='DPassword'>Password</Label>
                      <Input className={ErrDepositPassword ? 'league-placeholder-error' : 'league-placeholder'} disabled={!isEditAdminDeposit} id='DPassword' onChange={event => handleChange(event, 'DepositPassword')} placeholder='Enter Password' type='password' value={DepositPassword} />
                      <p className='error-text'>{ErrDepositPassword}</p>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Fragment>
          )
        }
      </Col>
    </>
  )
}
AdminDeposit.propTypes = {
  setLoading: PropTypes.func,
  setCancel: PropTypes.func,
  isEditAdminDeposit: PropTypes.bool,
  setEditAdminDeposit: PropTypes.func
}

export default AdminDeposit
