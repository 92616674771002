import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Form, FormGroup, Label, Input, InputGroupText, Row, Col } from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@tanstack/react-query'
import PropTypes from 'prop-types'

import removeImg from '../../../../assets/images/ep-close.svg'
import documentPlaceholder from '../../../../assets/images/upload-icon.svg'

import Loading from '../../../../components/Loading'
import AlertMessage from '../../../../components/AlertMessage'
import RequiredField from '../../../../components/RequiredField'

import { verifyLength, isPositive, isNumber, modalMessageFunc, acceptFormat, typeOptions } from '../../../../helpers/helper'
import { getUrl } from '../../../../actions/url'
import getCurrencyData from '../../../../api/AddSetting/getCurrencyData'
import getSettingDetails from '../../../../api/AddSetting/getSettingDetails'
import updateSetting from '../../../../api/settingManagement/updateSetting'
import getSideBackgroundImage from '../../../../api/AddSetting/getSideBackgroundImage'
import submitSiteSideBackgroundImage from '../../../../api/AddSetting/submitSiteSideBackgroundImage'
import { updateCurrencyDetails } from '../../../../actions/setting'

const AddSetting = forwardRef((props, ref) => {
  const { adminPermission, Auth, setSubmitDisableButton, Key, setKey } = props

  const { id } = useParams()
  const [type, setType] = useState('')
  const [sValue, setValue] = useState('')
  const [valueErr, setValueErr] = useState('')
  const [typeErr, setTypeErr] = useState('')
  const [backgroundImage, setBackgroundImage] = useState('')
  const [sideImage, setSideImage] = useState('')
  const [errImage, setErrImage] = useState('')
  const [url, setUrl] = useState('')
  const [shortName, setShortName] = useState('')
  const [logo, setLogo] = useState('')
  const [description, setDescription] = useState('')
  const [errShortname, setErrShortname] = useState('')
  const [errLogo, setErrLogo] = useState('')
  const [Title, setTitle] = useState('')
  const [Max, setMax] = useState(0)
  const [Min, setMin] = useState(0)
  const [errTitle, setErrTitle] = useState('')
  const [errKey, setErrKey] = useState('')
  const [errMax, setErrMax] = useState('')
  const [errMin, setErrMin] = useState('')
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState(false)
  const [settingId, setSettingId] = useState('')
  const [close, setClose] = useState(false)
  const [settingStatus, setSettingStatus] = useState('N')

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = useSelector(state => state?.auth?.token)

  const resStatus = useSelector(state => state?.setting?.resStatus)
  const resMessage = useSelector(state => state?.setting?.resMessage)
  const getUrlLink = useSelector(state => state?.url?.getUrl)
  const previousProps = useRef({ resStatus, resMessage, Key })?.current
  const page = JSON?.parse(localStorage?.getItem('queryParams'))
  const [modalMessage, setModalMessage] = useState(false)

  //  fetching setting Details by id
  const { data: settingDetails } = useQuery({
    queryKey: ['getSettingDetails', id],
    queryFn: () => getSettingDetails(id),
    select: (res) => res?.data?.data
  })

  const { mutate: updateSettingFun } = useMutation(updateSetting, {
    onSuccess: (res) => {
      navigate(`/settings/setting-management${page?.SettingManagement || ''}`, { state: { message: res?.data?.message } })
    },
    onError: (error) => {
      setMessage(error?.response?.data?.message)
      setModalMessage(true)
      setStatus(false)
      setLoading(false)
    }
  })

  const { mutate: submitSiteSideBackgroundImageFun } = useMutation(submitSiteSideBackgroundImage, {
    onSuccess: (res) => {
      navigate(`/settings/setting-management${page?.SettingManagement || ''}`, { state: { message: res?.data?.message } })
    }
  })
  const { data: sideBgImage } = useQuery({
    queryKey: ['getSideBgImage', Key],
    queryFn: () => getSideBackgroundImage(Key),
    select: (res) => res?.data?.data,
    enabled: !!(Key === 'BG' || Key === 'IMG')
  })

  // through this condition if there is no changes in at update time submit button will remain disable
  const buttonDisable = settingDetails &&
    previousProps?.settingDetails !== settingDetails &&
    settingDetails?.sTitle === Title &&
    ((settingDetails?.nMax || Max) ? settingDetails?.nMax === Max : true) &&
    ((settingDetails?.nMin || Min) ? settingDetails?.nMin === Min : true) &&
    ((settingDetails?.eStatus || settingStatus) ? settingDetails?.eStatus === settingStatus : true) &&
    ((settingDetails?.sShortName || shortName) ? settingDetails?.sShortName === shortName : true) &&
    ((settingDetails?.sLogo || logo) ? settingDetails?.sLogo === logo : true) &&
    ((settingDetails?.eStatus || settingStatus) ? settingDetails?.eStatus === settingStatus : true) &&
    ((settingDetails?.sValue || sValue) ? settingDetails?.sValue === sValue : true) &&
    ((settingDetails?.eValueType || type) ? settingDetails?.eValueType === type : true)

  const imgButtonDisable = ((sideBgImage?.sKey === 'BG' || backgroundImage) ? settingDetails?.sImage === backgroundImage : true) && ((sideBgImage?.sKey === 'IMG' || sideImage) ? settingDetails?.sImage === sideImage : true)

  const { data: currencyDetails } = useQuery({
    queryKey: ['getCurrencyDetails'],
    queryFn: () => getCurrencyData(),
    select: (res) => res?.data?.data,
    enabled: !!(Key === 'CURRENCY')
  })

  useEffect(() => {
    if (id) {
      setSettingId(id)
      setLoading(true)
    }
  }, [])

  useEffect(() => {
    const disable = settingDetails?.sImage ? imgButtonDisable : buttonDisable
    setSubmitDisableButton(disable)
  }, [buttonDisable, imgButtonDisable])

  // set timeout to remove pop up success/error message after given interval
  useEffect(() => {
    modalMessageFunc(modalMessage, setModalMessage, setClose)
  }, [modalMessage])

  // to handle response
  useEffect(() => {
    if (previousProps?.resMessage !== resMessage) {
      if (resMessage) {
        setMessage(resMessage)
        setStatus(resStatus)
        if (resStatus) {
          navigate(`/settings/setting-management${page?.SettingManagement || ''}`, { state: { message: resMessage } })
        }
        setLoading(false)
      }
    }
    return () => {
      previousProps.resMessage = resMessage
    }
  }, [resMessage, resStatus])

  //  set currency details
  useEffect(() => {
    if (currencyDetails) {
      setTitle(currencyDetails?.sTitle)
      setKey(currencyDetails?.sKey)
      setShortName(currencyDetails?.sShortName)
      setLogo(currencyDetails?.sLogo)
      setDescription(currencyDetails?.sDescription || '')
      setLoading(false)
    }
    return () => {
      previousProps.currencyDetails = currencyDetails
    }
  }, [currencyDetails])

  //  set settings details
  useEffect(() => {
    if (settingDetails) {
      setTitle(settingDetails?.sTitle)
      setKey(settingDetails?.sKey)
      setMax(settingDetails?.nMax)
      setMin(settingDetails?.nMin)
      setValue(settingDetails?.sValue)
      setSettingStatus(settingDetails?.eStatus)
      setDescription(settingDetails?.sDescription)
      setType(settingDetails?.eValueType)
      setLoading(false)
    }
    return () => {
      previousProps.settingDetails = settingDetails
    }
  }, [settingDetails])

  //  get image or currency data
  useEffect(() => {
    if (previousProps?.Key !== Key) {
      if (Key === 'BG' || Key === 'IMG') {
        if (!getUrlLink) {
          dispatch(getUrl('media'))
        }
      }
    }
    return () => {
      previousProps.Key = Key
    }
  }, [Key])

  useEffect(() => {
    if (getUrlLink) {
      setUrl(getUrlLink)
    }
  }, [getUrlLink])

  //  set image
  useEffect(() => {
    if (sideBgImage) {
      sideBgImage?.sKey === 'BG' ? setBackgroundImage(sideBgImage?.sImage) : setSideImage(sideBgImage?.sImage)
    }
  }, [sideBgImage])

  // for handle onChange event
  function handleChange (event, type) {
    const value = event?.target?.value
    switch (type) {
      case 'Title':
        setErrTitle(verifyLength(value, 1) ? '' : 'Required field')
        setTitle(value)
        break
      case 'Key':
        setErrKey(verifyLength(value, 1) ? '' : 'Required field')
        setKey(value)
        break
      case 'Value':
        // if (isNumber(value) || !value) {
        if (value) {
          setValueErr('')
        } else {
          setValueErr('Required field')
        }
        setValue(value)
        // }
        break
      case 'Max':
        if (isNumber(value) || !value) {
          if (value) {
            setErrMax('')
          } else {
            setErrMax('Required field')
          }
          setMax(value)
          if (parseInt(Min) && (parseInt(Min) > parseInt(value))) {
            setErrMax('Maximum amount should be greater than Minimum amount!')
          } else {
            setErrMax('')
            setErrMin('')
          }
        }
        break
      case 'Min':
        if (isNumber(value) || !value) {
          if (value) {
            setErrMin('')
          } else {
            setErrMin('Required field')
          }
          setMin(value)
          if (parseInt(Max) && (parseInt(value) > parseInt(Max))) {
            setErrMin('Minimum amount should be less than Maximum amount!')
          } else {
            setErrMin('')
            setErrMax('')
          }
        }
        break
      case 'Status':
        setSettingStatus(value)
        break
      case 'Background':
        if ((event?.target?.files[0]?.size / 1024 / 1024)?.toFixed(2) > 5) {
          setErrImage('Please select a file less than 5MB')
        } else if (event?.target?.files[0] && event?.target?.files[0]?.type?.includes('image')) {
          setBackgroundImage({ imageURL: URL.createObjectURL(event?.target?.files[0]), file: event?.target?.files[0] })
          setErrImage('')
        }
        break
      case 'SideImage':
        if ((event?.target?.files[0]?.size / 1024 / 1024)?.toFixed(2) > 5) {
          setErrImage('Please select a file less than 5MB')
        } else if (event?.target?.files[0] && event?.target?.files[0]?.type?.includes('image')) {
          setSideImage({ imageURL: URL.createObjectURL(event?.target?.files[0]), file: event?.target?.files[0] })
          setErrImage('')
        }
        break
      case 'ShortName':
        setErrShortname(verifyLength(value, 1) ? '' : 'Required field')
        setShortName(value)
        break
      case 'Logo':
        setErrLogo(verifyLength(value, 1) ? '' : 'Required field')
        setLogo(value)
        break
      case 'CreatorBonusType':
        setTypeErr(verifyLength(value, 1) ? '' : 'Required field')
        setValue(value)
        break
      case 'RemoveImage':
        setSideImage('')
        setBackgroundImage('')
        setErrImage('Required field')
        break
      case 'Type':
        setValue(settingDetails?.sValue ? settingDetails?.sValue : '')
        setMin(settingDetails?.nMin ? settingDetails?.nMin : '')
        setMax(settingDetails?.nMax ? settingDetails?.nMax : '')
        setType(value)
        break
      default:
        break
    }
  }

  // to handle image error occurred during add time
  function onImageError (e) {
    e.target.src = documentPlaceholder
  }

  // for validate the field and dispatch action
  function onSubmit (e) {
    const validation = (sValue ? isPositive(sValue) : true) && (Max ? isPositive(Max) : true) && (Min ? isNumber(Min) : true) && ((Max || Min) ? (parseInt(Min) <= parseInt(Max)) : true) && !errTitle && !errKey && !errMax && !errMin

    if (validation) {
      const updateSettingData = {
        sTitle: Title, sKey: Key, nMax: Max === null ? undefined : Max, nMin: Min === null ? undefined : Min, eStatus: settingStatus, sValue: sValue === null ? undefined : sValue, eValueType: type
      }
      updateSettingFun({ updateSettingData, settingId, token })
      setLoading(true)
    } else {
      if (Key === 'CREATOR_BONUS' && verifyLength(sValue, 1)) {
        setTypeErr('Required field')
      }
      setErrTitle(!verifyLength(Title, 1) ? 'Required field' : '')
      setErrKey(!verifyLength(Key, 1) ? 'Required field' : '')
      if (parseInt(Max) < parseInt(Min)) {
        setErrMax('Maximum amount should be greater than Minimum Amount')
      } else if (!isPositive(Max)) {
        setErrMax('Required field')
      } else {
        setErrMax('')
      }
      setErrMin(Min < 0 ? 'Required field' : '')
    }
  }

  // for add image
  function imageSubmit (type) {
    if (backgroundImage && type === 'BG') {
      const bgData = { backgroundImage, type }
      submitSiteSideBackgroundImageFun(bgData)
    } else if (sideImage && type === 'IMG') {
      submitSiteSideBackgroundImageFun(sideImage, type)
    }
    setLoading(true)
  }

  // for update currendy data
  function updateCurrencyData () {
    if (shortName && logo && !errShortname && !errLogo) {
      const data = { Title, Key, shortName, logo, token }
      dispatch(updateCurrencyDetails(data))
    } else {
      if (!shortName) {
        setErrShortname('Required field')
      }
      if (!logo) {
        setErrLogo('Required field')
      }
    }
  }

  useImperativeHandle(ref, () => ({
    updateCurrencyData, imageSubmit, onSubmit
  }))

  // Function to check if the key matches any condition in the array
  const checkConditions = (key, conditions) => {
    return conditions?.includes(key)
  }

  // Key options based on SettingKeys array
  const keyOptions = () => {
    let optionType = []
    typeOptions?.forEach((item) => {
      if (checkConditions(Key, item?.settingsKeys)) {
        optionType = item?.options
      }
    })
    return optionType?.map((option) => (
      <option key={option?.value} value={option?.value}>
        {option?.label}
      </option>
    ))
  }

  return (
    <main className="main-content">
      <AlertMessage
        close={close}
        message={message}
        modalMessage={modalMessage}
        status={status}
      />
      {loading && <Loading />}
      <section className="common-form-block">
        <Form>
          <Row>
            <Col md={12} xl={12}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="Title">Title </Label>
                <InputGroupText>{Title}</InputGroupText>
                <p className="error-text">{errTitle}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col md={12} xl={12}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="Key">Key</Label>
                <InputGroupText>{Key}</InputGroupText>
              </FormGroup>
            </Col>
          </Row>

          {Key === 'CREATOR_BONUS' && (
          <Row className='mt-3'>
            <Col md={12} xl={12}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for='CreatorBonusType'>
                  Type
                  <RequiredField/>
                </Label>
                <Input
                  className="form-control"
                  disabled={adminPermission?.SETTING === 'R'}
                  name="CreatorBonusType"
                  onChange={event => handleChange(event, 'CreatorBonusType')}
                  type="select"
                  value={sValue}
                >
                  <option value=''>Select type</option>
                  <option value="DEPOSIT">Deposit</option>
                  <option value="WIN">Win</option>
                  <option value="BONUS">Bonus</option>
                </Input>
                <p className="error-text">{typeErr}</p>
              </FormGroup>
            </Col>
          </Row>
          )}

          {/* //type of select */}
          {(Key !== 'CURRENCY' && Key !== 'BG' && Key !== 'IMG') && (
            <Row className='mt-3'>
              <Col md={12} xl={12}>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for="Type">Type</Label>
                  <Input
                    className="form-control"
                    disabled={adminPermission?.SETTING === 'R'}
                    name="Type"
                    onChange={event => handleChange(event, 'Type')}
                    type="select"
                    value={type}
                  >
                    {keyOptions()}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          )}

          {type && (
          <Row>
            {type === 'R'
              ? (
                <>
                  <Col md={12} xl={6} className='mt-3'>
                    <FormGroup className='form-group'>
                      <Label className='edit-label-setting' for="Min">
                        Min
                        {' '}
                        <RequiredField/>
                      </Label>
                      <Input
                        disabled={adminPermission?.SETTING === 'R'}
                        name="Min"
                        onChange={event => handleChange(event, 'Min')}
                        placeholder="Enter Min"
                        type='number'
                        value={Min}
                      />
                      <p className="error-text">{errMin}</p>
                    </FormGroup>
                  </Col>
                  <Col md={12} xl={6} className='mt-3'>
                    <FormGroup className='form-group'>
                      <Label className='edit-label-setting' for="Max">
                        Max
                        {' '}
                        <RequiredField/>
                      </Label>
                      <Input
                        disabled={adminPermission?.SETTING === 'R'}
                        name="Max"
                        onChange={event => handleChange(event, 'Max')}
                        placeholder="Enter Max"
                        type='number'
                        value={Max}
                      />
                      <p className="error-text">{errMax}</p>
                    </FormGroup>
                  </Col>
                </>
                )
              : type === 'F'
                ? (
                  <Col md={12} xl={12} className='mt-3'>
                    <FormGroup className='form-group'>
                      <Label className='edit-label-setting' for="Value">
                        Value
                        {' '}
                        <RequiredField/>
                      </Label>
                      <Input
                        disabled={adminPermission?.SETTING === 'R'}
                        name="Value"
                        onChange={event => handleChange(event, 'Value')}
                        placeholder="Enter Value"
                        type={!(Key === 'CREATOR_BONUS' || Key === 'APPLICATIONS' || Key === 'APPLICATIONS_POPUP' || Key === 'WITHDRAW_REJECT') ? 'number' : 'text'}
                        value={sValue}
                      />
                      <p className="error-text">{valueErr}</p>
                    </FormGroup>
                  </Col>
                  )
                : null}
          </Row>
          )}

          {Key === 'CURRENCY' && (
          <Row className='mt-3'>
            <Col md={12} xl={6}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="Title">
                  Shortname
                  <RequiredField/>
                </Label>
                <Input
                  disabled={adminPermission?.SETTING === 'R'}
                  name="Shortname"
                  onChange={event => handleChange(event, 'ShortName')}
                  placeholder="Enter Shortname"
                  value={shortName}
                />
                <p className="error-text">{errShortname}</p>
              </FormGroup>
            </Col>
            <Col md={12} xl={6}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="Title">
                  Logo
                  <RequiredField/>
                </Label>
                <Input
                  disabled={adminPermission?.SETTING === 'R'}
                  name="Logo"
                  onChange={event => handleChange(event, 'Logo')}
                  placeholder="Enter Logo"
                  value={logo}
                />
                <p className="error-text">{errLogo}</p>
              </FormGroup>
            </Col>
          </Row>
          )}

          {Key === 'BG' && (
          <Row className='mt-3'>
            <Col md={12} xl={12}>
              <FormGroup className='form-group'>
                <div className="theme-image text-center">
                  <div className="d-flex theme-photo">
                    <div className={backgroundImage ? 'theme-img' : 'theme-img-default'}>
                      <img alt="themeImage" className={backgroundImage ? 'custom-img' : 'custom-img-default'} onError={onImageError} src={backgroundImage ? backgroundImage.imageURL ? backgroundImage.imageURL : url + backgroundImage : documentPlaceholder} />
                      {backgroundImage && ((Auth && Auth === 'SUPER') || (adminPermission?.TEAM === 'W')) && <div className='remove-img-label'><img onClick={event => handleChange(event, 'RemoveImage')} src={removeImg} alt='removeImage' /></div>}
                      {((Auth && Auth === 'SUPER') || (adminPermission?.SETTING === 'W')) && (
                      <Input
                        accept={acceptFormat}
                        id="exampleCustomFileBrowser"
                        name="customFile"
                        onChange={event => handleChange(event, 'Background')}
                        type="file"
                      />
                      )}
                    </div>
                  </div>
                </div>
                <p className="error-text">{errImage}</p>
              </FormGroup>
            </Col>
          </Row>
          )}

          {Key === 'IMG' && (
          <Row className='mt-3'>
            <Col md={12} xl={12}>
              <FormGroup className='form-group'>
                <div className="theme-image text-center">
                  <div className="d-flex theme-photo">
                    <div className={sideImage ? 'theme-img' : 'theme-img-default'}>
                      <img alt="themeImage" className={sideImage ? 'custom-img' : 'custom-img-default'} onError={onImageError} src={sideImage ? sideImage.imageURL ? sideImage.imageURL : url + sideImage : documentPlaceholder} />
                      {sideImage && ((Auth && Auth === 'SUPER') || (adminPermission?.TEAM === 'W')) && <div className='remove-img-label'><img onClick={event => handleChange(event, 'RemoveImage')} src={removeImg} alt='removeImage' /></div>}
                      {((Auth && Auth === 'SUPER') || (adminPermission?.SETTING === 'W')) && (
                      <Input
                        accept={acceptFormat}
                        id="exampleCustomFileBrowser"
                        name="customFile"
                        onChange={event => handleChange(event, 'SideImage')}
                        type="file"
                      />
                      )}
                    </div>
                  </div>
                </div>
                <p className="error-text">{errImage}</p>
              </FormGroup>
            </Col>
          </Row>
          )}

          {description && (
          <Row className='mt-3'>
            <Col md={12} xl={12}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="Description">Description</Label>
                <Input className='read-only-class' readOnly type='textarea' value={description} />
              </FormGroup>
            </Col>
          </Row>
          )}

          {(Key !== 'BG' && Key !== 'IMG' && Key !== 'CURRENCY') && (
          <Row className='p-3 mt-3' >
            <div className='radio-button-div'>
                <Col md={12} xl={12}>
                  <FormGroup className='form-group'>
                    <Label className='edit-label-setting lable-league' for="ActiveOffer">Status</Label>
                    <div className="d-flex inline-input mt-2">
                      <div className='custom-radio custom-control'>
                        <Input
                          checked={settingStatus === 'Y'}
                          disabled={(adminPermission?.SETTING === 'R') || (Key === 'PUBC' || Key === 'PCS' || Key === 'PCF')}
                          id="bannerRadio1"
                          label="Active"
                          name="bannerRadio"
                          onChange={event => handleChange(event, 'Status')}
                          type="radio"
                          value="Y"
                          className='custom-control-input me-2'
                        />
                        <Label>Active</Label>
                      </div>
                      <div className='custom-radio custom-control'>
                        <Input
                          checked={settingStatus !== 'Y'}
                          disabled={(adminPermission?.SETTING === 'R') || (Key === 'PUBC' || Key === 'PCS' || Key === 'PCF')}
                          id="bannerRadio2"
                          label="In Active"
                          name="bannerRadio"
                          onClick={event => handleChange(event, 'Status')}
                          type="radio"
                          value="N" 
                          className='custom-control-input me-2'
                        />
                        <Label>In Active</Label>
                      </div>
                    </div>
                  </FormGroup>
                </Col>
            </div>
          </Row>
          )}
        </Form>
      </section>
    </main>
  )
})

AddSetting.defaultProps = {
  history: {}
}

AddSetting.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  }),
  match: PropTypes.object,
  adminPermission: PropTypes.object,
  setSubmitDisableButton: PropTypes.func,
  Auth: PropTypes.string,
  Key: PropTypes.string,
  setKey: PropTypes.func,
  navigate: PropTypes.object

}
AddSetting.displayName = AddSetting
export default connect(null, null, null, { forwardRef: true })(AddSetting)
