import React, { Fragment } from 'react'
import { Button, Col, Collapse,  Row } from 'reactstrap'
import PropTypes from 'prop-types'

import caretBottom from '../../../../assets/images/caret-top.svg'
import caretIcon from '../../../../assets/images/caret-bottom.svg'
import rightIcon from '../../../../assets/images/verify.svg'
import viewIcon from '../../../../assets/images/view-eye.svg'

function UserDocuments (props) {
  const {
    Auth, adminPermission, toggleDocument, openDocuments, panDetails, kycDetails,
     warningWithConfirmMessage,  aadhaarDetails, 
    errAadhaarImage
  } = props

  return (
    <>
      {((Auth && Auth === 'SUPER') || (adminPermission?.KYC !== 'N')) && (
        <Fragment>
          <div className='common-box-user'>
            <h3 className='user-heading' onClick={toggleDocument}>
              Documents
              <span>
                <img alt="" src={openDocuments ? caretBottom : caretIcon} />
              </span>
            </h3>
            <Collapse isOpen={openDocuments}>
              <Row >
                <Col lg={6} md={12} xl={6}>
                  {((Auth && Auth === 'SUPER') || (adminPermission?.KYC !== 'N')) && (
                    <div className='document-header  align-items-start p-4'>
                      <h3>
                        PAN
                        {panDetails && panDetails?.eStatus === 'A'
                          ? (
                            <span className='ml-3 verify-text'>
                              <img alt="" src={rightIcon} />
                              {' '}
                              (Verified)
                            </span>
                            )
                          : panDetails?.eStatus === 'R' ? <span className='danger-text ml-2'>(Rejected)</span> : ''
                        }
                      </h3>
                      {((Auth && Auth === 'SUPER') || (adminPermission?.KYC !== 'R')) && (
                        <div
                          className={kycDetails?.eStatus === 'D' ? 'hide-Edit-button' : 'd-flex align-items-center'}
                          hidden={['P', 'A']?.includes(kycDetails && kycDetails?.oPan && kycDetails?.oPan?.eStatus)}
                        >
                          {/* <div
                            className={isEditPanDetails ? 'default-edit' : 'user-edit-button'}
                            hidden={['P', 'A']?.includes(kycDetails && kycDetails?.oPan && kycDetails?.oPan?.eStatus)}
                            onClick={onEditPanDetails}
                          >
                          

                            <img alt="" className='pr-2 image-user-edit' src={isEditPanDetails ? '' : pencilIcon} />
                            <Button
                              className={isEditPanDetails ? 'user-Edit-button' : 'button'}
                              hidden={['P', 'A']?.includes(kycDetails && kycDetails?.oPan && kycDetails?.oPan?.eStatus)}
                            >
                              {isEditPanDetails ? 'Save' : 'Edit'}
                            </Button>
                          </div>
                          {isEditPanDetails && <Button className='ml-1 user-cancel-button' color='link' onClick={() => cancelFunc('kyc_pan')}>Cancel</Button>} */}
                        </div>
                      )}
                    </div>
                  )}

                  <div className='document-list'>
                    <div className='item'>
                      <Row >
                        {kycDetails?.oPan?.sImage ?
                          (
                            <>
                              <iframe src={kycDetails?.oPan?.sImage} height="400" width="300" title="Iframe Example" ></iframe>
                              <Button className='view-btn-user ml-3' color='link' onClick={() => window.open(kycDetails?.oPan?.sImage, '_blank')}>
                                <img alt='View' src={viewIcon} />
                                View
                              </Button>
                            </>
                          ) :
                          <div className='text-center mt-5 mb-5 '>No Details</div>
                          }

                       
                        {/* <Col className='mb-4' lg={12} md={6} xl={6}>
                          <FormGroup >
                            <Label className='edit-label-setting' for='PANName'>Name as per PAN</Label>
                            <Input className={errPanName ? 'league-placeholder-error' : 'league-placeholder'} disabled={!isEditPanDetails} id='PANName' onChange={event => handleChange(event, 'KYC_Pan_Name')} placeholder='Name as per PAN' type='text' value={panDetails?.sName || ''} />
                            {errPanName && <p className='error-text'>{errPanName}</p>}
                          </FormGroup>
                        </Col>
                        <Col className='mb-4' lg={12} md={6} xl={6}>
                          <FormGroup>
                            <Label className='edit-label-setting' for='document1No'>PAN</Label>
                            <Input className={errPanNo ? 'league-placeholder-error' : 'league-placeholder'} disabled={!isEditPanDetails} id='document1No' onChange={event => handleChange(event, 'KYC_Pan_DocNo')} placeholder='Enter PAN' type='text' value={panDetails && panDetails?.sNo ? panDetails?.sNo : ''} />
                            {errPanNo && <p className='error-text'>{errPanNo}</p>}
                          </FormGroup>
                        </Col>
                        <Col className='d-flex align-self-center mb-2' lg={12} md={6} xl={6}>
                          {panDetails && panDetails?.eStatus === 'A' && ((Auth && Auth === 'SUPER') || (adminPermission?.KYC === 'W')) && (
                            <Button className='danger-btn' color='link' onClick={() => warningWithConfirmMessage('Reject', 'PAN')}>
                              <img alt='Reject' src={wrongIcon} />
                              <span>Reject</span>
                            </Button>
                          )}
                        </Col>
                        <Col className='mb-3' lg={12} md={12} xl={12}>
                          <div className='theme-image'>
                            <div className='theme-photo text-center'>
                              <div className={panDetails?.sImage ? 'theme-img' : 'theme-img-default'}>
                                {panDetails && panDetails?.sImage ? <img alt='pancard' className='custom-img' onError={ev => onImageError(ev, 'document')} src={panDetails?.sImage?.imageUrl ? panDetails?.sImage?.imageUrl : panDetails?.sImage} /> : <img alt='pancard' className={panDetails?.sImage ? 'custom-img' : 'custom-img-default'} onError={ev => onImageError(ev, 'document')} src={documentPlaceholder} />}
                                {isEditPanDetails && panDetails?.sImage && ((Auth && Auth === 'SUPER') || (adminPermission?.KYC === 'W')) && <div className='remove-img-label'><img onClick={event => handleChange(event, 'RemovePANImage')} src={removeImg} alt='removeImg' /></div>}
                                <div className='lable-Upload-Image' hidden={panDetails?.sImage}>Upload Image</div>
                              </div>
                              {(isEditPanDetails && !panDetails?.sImage) ? <Input className='custom-file-input' accept={acceptFormat()} disabled={!isEditPanDetails} hidden={panDetails && (panDetails.eStatus === 'A' || panDetails.eStatus === 'P')} id='exampleCustomFileBrowser' name='customFile' onChange={event => handleChange(event, 'KYC_Pan')} type='file' /> : ''}
                              <Button className='view-btn-user ml-3' color='link' hidden={!(panDetails && panDetails?.sImage)} onClick={() => setModalOpen(true)}>
                                <img alt='View' src={viewIcon} />
                                View
                              </Button>
                            </div>
                          </div>
                          {errPanImage && <p className='error-text'>{errPanImage}</p>}

                          {panDetails && panDetails?.eStatus === 'R'
                            ? (
                              <p className={`danger-text ml-2' ${panDetails?.sRejectReason ? 'mt-3' : ' '}`}>
                                Reason:
                                {' '}
                                {panDetails && panDetails?.sRejectReason}
                              </p>
                              )
                            : ''}
                          {((Auth && Auth === 'SUPER') || (adminPermission?.KYC === 'W')) && (
                            <Row className='py-4 kyc-button'>
                              <Col className='pl-0 approve' md={6} xl={6}>
                                {panDetails && panDetails?.eStatus === 'P' && (
                                  <Button
                                    className='success-btn-user w-100'
                                    color='link'
                                    onClick={() => warningWithConfirmMessage('Verify', 'PAN')}
                                  >
                                    <span>Approve</span>
                                  </Button>
                                )}
                              </Col>
                              <Col className='pr-0 reject' md={6} xl={6}>
                                {panDetails && panDetails?.eStatus === 'P' && (
                                  <Button
                                    className='danger-btn-user w-100'
                                    color='link'
                                    onClick={() => warningWithConfirmMessage('Reject', 'PAN')}
                                  >
                                    <span>Reject</span>
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          )}
                        </Col> */}
                      </Row>
                    </div>
                  </div>
                </Col>

                <Col lg={6} md={12} xl={6}>
                  <Row>
                    {((Auth && Auth === 'SUPER') || (adminPermission?.KYC !== 'N')) && (
                      <Fragment>
                        <div className='document-header w-100 p-4'>
                          <h3>
                            Aadhaar
                            {aadhaarDetails && aadhaarDetails?.eStatus === 'A'
                              ? (
                                <span className='ml-2 verify-text'>
                                  <img alt="" src={rightIcon} />
                                  {' '}
                                  (Verified)
                                </span>
                                )
                              : aadhaarDetails?.eStatus === 'R' ? <span className='danger-text ml-2'>(Rejected)</span> : ''}
                          </h3>
                          {/* {((Auth && Auth === 'SUPER') || (adminPermission?.KYC !== 'R')) && (
                            <div className='d-flex align-items-center'>
                              <div
                                className={isEditAadhaarDetails ? 'default-edit' : 'user-edit-button'}
                                hidden={['D']?.includes(kycDetails && kycDetails?.eStatus) ? ['D']?.includes(kycDetails && kycDetails?.eStatus) : ['P', 'A']?.includes(kycDetails && kycDetails?.oAadhaar && kycDetails?.oAadhaar?.eStatus)}
                                onClick={onEditAadhaarDetails}
                              >
                                <img className='pr-2 image-user-edit' alt="" src={isEditAadhaarDetails ? '' : pencilIcon} />
                                <Button
                                  className={isEditAadhaarDetails ? 'user-Edit-button' : 'button'}
                                  hidden={['P', 'A']?.includes(kycDetails && kycDetails?.oAadhaar && kycDetails?.oAadhaar?.eStatus)}
                                >
                                  {isEditAadhaarDetails ? 'Save' : 'Edit'}
                                </Button>
                              </div>
                              {isEditAadhaarDetails && <Button className='ml-2 user-cancel-button' onClick={() => cancelFunc('kyc_aadhaar')} >Cancel</Button>}
                            </div>
                          )} */}
                        </div>
                      </Fragment>
                    )
                    }
                    {/* <Col className='pb-4' lg={12} md={12} xl={12}>
                      <FormGroup>
                        <Label className='edit-label-setting' for='document2No'>Aadhaar Number</Label>
                        <div className='d-flex justify-content-between '>
                          <Input
                            className={errAadhaarNo ? 'league-placeholder-error' : 'league-placeholder'}
                            disabled={!isEditAadhaarDetails}
                            id='document2No'
                            onChange={event => handleChange(event, 'KYC_AADHAAR_NO')}
                            placeholder='Enter Aadhaar Number'
                            type='text'
                            value={aadhaarDetails && aadhaarDetails?.nNo ? aadhaarDetails?.nNo : ''}
                          />
                        </div>
                        {errAadhaarNo && <p className='error-text mb-0'>{errAadhaarNo}</p>}
                      </FormGroup>
                    </Col> */}

                    {/* <div className='document-list'>
                      <div className='item'>
                        {((Auth && Auth === 'SUPER') || (adminPermission?.KYC === 'W')) && (
                          <Col className='d-flex align-self-center' lg={12} md={6} xl={6}>
                            {aadhaarDetails && aadhaarDetails?.eStatus === 'A' && (
                              <Button className='danger-btn-rej' color='link' onClick={() => warningWithConfirmMessage('Reject', 'AADHAAR')}>
                                <img alt='Reject' src={wrongIcon} />
                                <span>Reject</span>
                              </Button>
                            )}
                          </Col>
                        )}
                      </div>
                    </div> */}
                    {aadhaarDetails && aadhaarDetails?.sFrontImage ?
                      (
                        <>
                          <iframe src={aadhaarDetails && aadhaarDetails?.sFrontImage} height="400" width="300" title="Iframe Example" ></iframe>
                          <Button className='view-btn-user ml-3' color='link' onClick={() => window.open(aadhaarDetails && aadhaarDetails?.sFrontImage, '_blank')}>
                            <img alt='View' src={viewIcon} />
                            View
                          </Button>
                        </>
                      ) :
                      <div className='text-center mt-5 mb-5 '>No Details</div>
                    }
                  </Row>

                  {/* <div className='document-list'>
                    <Row>
                      <Col className='mb-3' md={12} xl={6}>
                        <div className='adhar-Img'>
                          <div className='theme-image-2' >
                            <div className='theme-photo text-center'>
                              <div className={aadhaarDetails?.sFrontImage ? 'theme-img' : 'theme-img-default'}>
                                {aadhaarDetails && aadhaarDetails?.sFrontImage
                                  ? (
                                    <img
                                      alt='aadhaarfront'
                                      className='custom-img'
                                      onError={ev => onImageError(ev, 'document')}
                                      src={aadhaarDetails?.sFrontImage?.imageUrl ? aadhaarDetails?.sFrontImage?.imageUrl : aadhaarDetails?.sFrontImage}
                                    />
                                    )
                                  : (
                                    <img
                                      alt='aadhaarcardFront'
                                      className={aadhaarDetails?.sFrontImage ? 'custom-img' : 'custom-img-default'}
                                      onError={ev => onImageError(ev, 'document')}
                                      src={documentPlaceholder}
                                    />
                                    )}
                                {isEditAadhaarDetails && aadhaarDetails?.sFrontImage && ((Auth && Auth === 'SUPER') || (adminPermission?.KYC === 'W')) && (
                                  <div className='remove-img-label'>
                                    <img onClick={event => handleChange(event, 'RemoveAadhaarFront')} src={removeImg} alt='removeAadhar' />
                                  </div>
                                )}
                                <div className='side-label'>Upload Front Image</div>
                                {isEditAadhaarDetails
                                  ? (
                                    <div className='custom-file'>
                                    <Input accept={acceptFormat()}
                                    className='custom-file-input' 
                                      disabled={!isEditAadhaarDetails}
                                      hidden={aadhaarDetails && (aadhaarDetails?.eStatus === 'A' || aadhaarDetails?.eStatus === 'P')}
                                      id='exampleCustomFileBrowser1'
                                      name='customFile1'
                                      onChange={event => handleChange(event, 'KYC_IDProof_front')}
                                      type='file'
                                    />
                                    </div>
                                    )
                                  : ''}
                              </div>
                              <Button
                                className='view-btn-user'
                                color='link'
                                hidden={!(aadhaarDetails && aadhaarDetails?.sFrontImage)}
                                onClick={() => setModalAADHAARF(true)}
                              >
                                <img alt='View' src={viewIcon} />
                                View
                              </Button>
                            </div>
                          </div>
                        </div>

                      </Col>
                      <Col className='pb-3' md={12} xl={6}>
                        <div className='adhar-Img'>
                          <div className='theme-image-2'>
                            <div className='theme-photo text-center'>
                              <div className={aadhaarDetails?.sBackImage ? 'theme-img' : 'theme-img-default'}>
                                {aadhaarDetails && aadhaarDetails?.sBackImage
                                  ? (
                                    <img alt='aadhaarback'
                                      className='custom-img'
                                      onError={ev => onImageError(ev, 'document')}
                                      src={aadhaarDetails?.sBackImage?.imageUrl ? aadhaarDetails?.sBackImage?.imageUrl : aadhaarDetails?.sBackImage}
                                    />
                                    )
                                  : (
                                    <img
                                      alt='aadhaarcardFront'
                                      className={aadhaarDetails?.sBackImage ? 'custom-img' : 'custom-img-default'}
                                      onError={ev => onImageError(ev, 'document')}
                                      src={documentPlaceholder}
                                    />
                                    )}
                                {isEditAadhaarDetails && aadhaarDetails?.sBackImage && ((Auth && Auth === 'SUPER') || (adminPermission?.KYC === 'W')) && (
                                  <div className='remove-img-label'>
                                    <img onClick={event => handleChange(event, 'RemoveAadhaarBack')} src={removeImg} alt='removeImg' />
                                  </div>
                                )}
                                <div className='side-label'>Upload Back Image</div>
                                {isEditAadhaarDetails
                                  ? (
                                    <div className='custom-file'>
                                    <Input accept={acceptFormat()}
                                    className='custom-file-input' 
                                      disabled={!isEditAadhaarDetails}
                                      hidden={aadhaarDetails && (aadhaarDetails?.eStatus === 'A' || aadhaarDetails?.eStatus === 'P')}
                                      id='exampleCustomFileBrowser2'
                                      label='Edit'
                                      name='customFile2'
                                      onChange={event => handleChange(event, 'KYC_IDProof_Back')}
                                      type='file'
                                    />
                                    </div>
                                    )
                                  : ''}
                              </div>
                            </div>
                            {aadhaarDetails?.sBackImage && (
                              <Button
                                className='view-btn-user d-flex align-items-center justify-content-center w-100'
                                color='link'
                                hidden={!(aadhaarDetails && aadhaarDetails?.sBackImage)}
                                onClick={() => setModalAADHAARB(true)}
                              >
                                <img alt='View' src={viewIcon} />
                                View
                              </Button>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div> */}

                  <div className='item mt-1 mb-2'>
                    {errAadhaarImage &&
                      <p className='error-text mb mt-0 d-flex justify-content-center'>{errAadhaarImage}</p>
                    }
                    {aadhaarDetails && aadhaarDetails?.eStatus === 'R'
                      ? (
                        <p className='danger-text ml-2'>
                          Reason:
                          {' '}
                          {kycDetails && kycDetails?.oAadhaar && kycDetails?.oAadhaar?.sRejectReason}
                        </p>
                        )
                      : ''}
                    {((Auth && Auth === 'SUPER') || (adminPermission?.KYC === 'W')) && (
                      <Fragment>
                        <Row className='px-4'>
                          <Col className='pl-0' md={6} xl={6}>
                            {aadhaarDetails && aadhaarDetails?.eStatus === 'P' && (
                              <Button
                                className='success-btn-user aadhaar-approve w-100'
                                color='link'
                                onClick={() => warningWithConfirmMessage('Verify', 'AADHAAR')}
                              >
                                <span>Approve </span>
                              </Button>
                            )}
                          </Col>
                          <Col className='pr-0' md={6} xl={6}>
                            {aadhaarDetails && aadhaarDetails?.eStatus === 'P' && (
                              <Button
                                className='danger-btn-user aadhaar-approve w-100'
                                color='link'
                                onClick={() => warningWithConfirmMessage('Reject', 'AADHAAR')}
                              >
                                <span>Reject</span>
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Fragment>
                    )}
                  </div>

                </Col>
              </Row>
            </Collapse>
          </div>
        </Fragment>
      )
      }
    </>
  )
}

UserDocuments.propTypes = {
  Auth: PropTypes.string,
  adminPermission: PropTypes.bool,
  toggleDocument: PropTypes.bool,
  openDocuments: PropTypes.bool,
  panDetails: PropTypes.object,
  kycDetails: PropTypes.object,
  isEditPanDetails: PropTypes.bool,
  onEditPanDetails: PropTypes.func,
  cancelFunc: PropTypes.func,
  errPanName: PropTypes.string,
  errPanNo: PropTypes.string,
  warningWithConfirmMessage: PropTypes.func,
  handleChange: PropTypes.func,
  onImageError: PropTypes.func,
  errPanImage: PropTypes.string,
  setModalOpen: PropTypes.bool,
  aadhaarDetails: PropTypes.object,
  isEditAadhaarDetails: PropTypes.bool,
  errAadhaarNo: PropTypes.string,
  onEditAadhaarDetails: PropTypes.func,
  setModalAADHAARF: PropTypes.bool,
  errAadhaarImage: PropTypes.string,
  setModalAADHAARB: PropTypes.bool
}

export default UserDocuments
