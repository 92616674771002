import React, { Fragment, forwardRef, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Collapse, FormGroup, Input, InputGroupText, Label, Row } from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
// import Select from 'react-select'
import moment from 'moment'
// import Select from 'react-select'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'

import pencilIcon from '../../../../assets/images/pencil-line.svg'
import removeImg from '../../../../assets/images/ep-close.svg'
import documentPlaceholder from '../../../../assets/images/upload-icon.svg'
import verifyIcon from '../../../../assets/images/verify.svg'
import closeIcon from '../../../../assets/images/red-close-icon.svg'
import caretBottom from '../../../../assets/images/caret-top.svg'
import caretIcon from '../../../../assets/images/caret-bottom.svg'
import calenderIcon from '../../../../assets/images/calendar.svg'
import list from '../../../../assets/images/list-view.svg'

import { acceptFormat, isNumber, isPincode, verifyLength, verifyMobileNumber, verifySpecialCharacter, withoutSpace } from '../../../../helpers/helper'
import { updateUserDetails } from '../../../../actions/users'
import { cities, states } from '../../../../helpers/country'

function UserInformation (props) {
  const { setLoading, setEditUserDetails, isEditUserDetails } = props
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // const [isEditUserDetails, setEditUserDetails] = useState(false)
  const [MobNum, setMobNum] = useState(0)
  const [birthDateErr, setBirthDateErr] = useState('')
  const [gender, setGender] = useState('')
  const [propic, setproPic] = useState('')
  const [email, setEmail] = useState('')
  const [State, setState] = useState('')
  const [errEmail, setErrEmail] = useState('')
  const [errMobNum, setErrMobNum] = useState('')
  const [userName, setUsername] = useState('')
  const [userAccount, setUserAccount] = useState('N')
  const [fullname, setFullname] = useState('')
  const [birthdate, setBirthdate] = useState('')
  const [address, setAddress] = useState('')
  const [pincode, setPincode] = useState('')
  // const [pincodeErr, setPincodeErr] = useState('')
  const [city, setCity] = useState('')
  const [userStatus, setUserStatus] = useState('')
  // const [ErrFullName, setErrFullName] = useState('')
  const [errImage, setErrImage] = useState('')
  const [url, setUrl] = useState('')
  // const [stateOptions, setStateOptions] = useState([])
  // const [cityOptions, setCityOptions] = useState([])
  const [MobNumVerified, setMobNumVerified] = useState(false)
  const [emailVerified, setemailVerified] = useState(false)
  const [errUsername, setErrUserName] = useState('')
  const [Cancel, setCancel] = useState(false)
  const [copied, setCopied] = useState(false)
  const [referralCode, setRefferalCode] = useState('')
  const [referrals, setReferrals] = useState(0)
  const [referralModal, setReferralModal] = useState(false)
  const toggleReferralModal = () => setReferralModal(!referralModal)

  const adminPermission = useSelector(state => state?.auth?.adminPermission)
  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)
  const kycDetails = useSelector(state => state?.kyc?.kycDetails)
  const token = useSelector(state => state?.auth?.token)
  const usersDetails = useSelector(state => state?.users?.usersDetails)
  const getUrlLink = useSelector(state => state?.url?.getUrl)
  const resStatus = useSelector(state => state?.users?.resStatus)
  const resMessage = useSelector(state => state?.users?.resMessage)
  const previousProps = useRef({ usersDetails, State })?.current

  useEffect(() => {
    if (getUrlLink && (!url)) {
      setUrl(getUrlLink)
    }
  }, [getUrlLink])
  // set states list
  // useEffect(() => {
  //   if (states) {
  //     const arr = []
  //     states?.forEach(data => {
  //       const obj = {
  //         label: data?.state_name,
  //         value: data?.id
  //       }
  //       arr?.push(obj)
  //     })
  //     setStateOptions(arr)
  //   }
  // }, [states])

  // handle to set State List
  // useEffect(() => {
  //   if (previousProps?.State !== State) {
  //     const arr = cities?.filter(data => State?.value === data?.state_id)
  //     const cityOps = []
  //     arr?.forEach(data => {
  //       const obj = {
  //         label: data?.city_name,
  //         value: data?.id
  //       }
  //       cityOps?.push(obj)
  //     })
  //     setCityOptions(cityOps)
  //   }
  //   return () => {
  //     previousProps.State = State
  //   }
  // }, [State])

  // handle to set userDetails
  useEffect(() => {
    if (previousProps?.usersDetails !== usersDetails || (!resStatus && resMessage) || Cancel) {
      if (usersDetails) {
        setUserAccount(usersDetails?.bIsInternalAccount ? 'Y' : 'N')
        setEmail(usersDetails?.sEmail)
        setUsername(usersDetails?.sUsername)
        setFullname(usersDetails?.sName)
        setMobNum(usersDetails?.sMobNum)
        setMobNumVerified(usersDetails?.bIsMobVerified)
        setemailVerified(usersDetails?.bIsEmailVerified)
        setRefferalCode(usersDetails?.sReferCode)
        setproPic(usersDetails?.sProPic)
        setGender(usersDetails?.eGender)
        setBirthdate(moment(usersDetails?.dDob).format('MM/DD/YYYY'))
        setAddress(usersDetails?.sAddress ? usersDetails?.sAddress : '')
        setPincode(usersDetails?.nPinCode ? usersDetails?.nPinCode : '')
        setUserStatus(usersDetails?.eStatus)
        setReferrals(usersDetails?.nReferrals ? usersDetails?.nReferrals : 0)
        setCancel(false)
        const state = states?.find(data => data?.id === parseInt(usersDetails?.iStateId))
        setState(((usersDetails?.iStateId && state)) ? { label: state?.state_name || '', value: state?.id || '' } : '')
        const city = cities?.find(data => data?.id === parseInt(usersDetails?.iCityId))
        setCity((usersDetails?.iCityId && city) ? { label: city?.city_name || '', value: city?.id || '' } : '')
      }
    }
    return () => {
      previousProps.usersDetails = usersDetails
    }
  }, [usersDetails, resMessage, resStatus, Cancel])

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    }
  }, [copied])

  function copyToClipboard () {
    if (usersDetails && usersDetails?.sReferCode) {
      if (navigator?.clipboard && window?.isSecureContext) {
        navigator?.clipboard?.writeText(usersDetails?.sReferCode)?.then(() => {
          setCopied(true)
          setTimeout(() => setCopied(false), 2000)
        })
          .catch(() => console?.log('error'))
      } else {
        const textArea = document?.createElement('textarea')
        textArea.value = usersDetails?.sReferCode
        textArea.style.position = 'fixed'
        textArea.style.left = '-999999px'
        textArea.style.top = '-999999px'
        document?.body?.appendChild(textArea)
        textArea?.focus()
        textArea?.select()
        return new Promise(function () {
          if (document?.execCommand('copy')) {
            setCopied(true)
            setTimeout(() => setCopied(false), 2000)
          }
          textArea.remove()
        })
      }
    }
  }

  function onEditUserDetails () {
    if (!isEditUserDetails) {
      setEditUserDetails(true)
    }
  }

  function onImageError () {
  }

  function cancelFunc (type) {
    if (type === 'profile') {
      setEditUserDetails(false)
      setErrEmail('')
      setErrMobNum('')
      // setErrFullName('')
      setErrImage('')
      setErrUserName('')
      // setPincodeErr('')
    }
    setCancel(true)
  }

  // function handle changeProfileData
  function changeProfileData () {
    if (isNumber(MobNum) && !birthDateErr && !errEmail && !errMobNum) {
      const updateUserData = {
        userName, userAccount, fullname, ID: id, propic, email, MobNum, gender, birthdate: moment(birthdate)?.format('YYYY-MM-DD'), address, city: city?.value, pincode, State: State?.value, userStatus, mobileVerify: MobNumVerified, emailVerify: emailVerified, token
      }
      dispatch(updateUserDetails(updateUserData))
      setErrImage('')
      setLoading(true)
    } else {
      // if (!email) {
      //   setErrEmail('Required field')
      // } else if (!verifyEmail(email)) {
      //   setErrEmail('Enter Proper Email')
      // }
      if (MobNum?.length !== 10) {
        setErrMobNum('Number must be 10 digits')
      }
      if (!MobNum) {
        setErrMobNum('Required field')
      } else if (!isNumber(MobNum)) {
        setErrMobNum('Enter only numbers')
      }
      if (moment(birthdate)?.isAfter(moment())) {
        setBirthDateErr('Date should not be future date')
      }
    }
  }

  function handleChange (event, eType) {
    const value = event?.target?.value
    switch (eType) {
      case 'RemoveImage':
        setproPic('')
        break
      case 'UserAccount':
        setUserAccount(value)
        break
      case 'ProPic':
        if (event?.target?.files[0]?.type?.includes('image/gif')) {
          setErrImage('Gif not allowed!')
        } else if ((event?.target?.files[0]?.size / 1024 / 1024)?.toFixed(2) > 5) {
          setErrImage('Please select a file less than 5MB')
        } else if (event?.target?.files[0]) {
          setproPic({ imageUrl: URL?.createObjectURL(event?.target?.files[0]), file: event?.target?.files[0] })
          setErrImage('')
        }
        break
      case 'Name':
        if (verifyLength(value, 1)) {
          // setErrFullName('')
        } else if (!verifyLength(value, 1)) {
          // setErrFullName('Required field')
        }
        setFullname(value)
        break
      case 'Email':
        // if (verifyLength(value, 1) && verifyEmail(value) && verifyEmail(value)) {
        //   setErrEmail('')
        // } else if (!verifyEmail(value)) {
        //   setErrEmail('Enter Proper Email')
        // }
        setEmail(event?.target?.value)
        break
      case 'userName':
        if (verifyLength(value, 5) && value?.length <= 16 && !withoutSpace(value) && verifySpecialCharacter(value)) {
          setErrUserName('')
        } else {
          if (withoutSpace(value)) {
            setErrUserName('Username must be alpha-numeric.')
          } else if (!verifyLength(value, 5)) {
            setErrUserName('Username must of minimum of 5 character')
          } else if (!verifySpecialCharacter(value)) {
            setErrUserName('Username must be alpha-numeric.')
          } else if (value?.length > 16) {
            setErrUserName('Username must be maximum of 15 characters')
          }
        }
        setUsername(value)
        break
      case 'MobNum':
        if (!value) {
          setErrMobNum('Required field')
        } else if (verifyMobileNumber(value)) {
          setErrMobNum('')
        } else if (!isNumber(value)) {
          setErrMobNum('Must be numbers')
        } else if (!verifyMobileNumber(value)) {
          setErrMobNum('Must be 10 digits')
        }
        setMobNum(value)
        break
      case 'Address':
        setAddress(value)
        break
      case 'Birthdate':
        if (moment(event)?.isAfter(moment())) {
          setBirthDateErr('Date should not be future date')
        } else if (moment()?.subtract('years', 18)?.isBefore(event)) {
          setBirthDateErr('User must be greater or equal to 18 years old')
        } else if (!verifyLength(moment(event)?.format('MM/DD/YYYY hh:mm:ss A'), 1)) {
          setBirthDateErr('Required field')
        } else {
          setBirthDateErr('')
        }
        setBirthdate(moment(event)?.format('MM/DD/YYYY'))
        break
      case 'Pincode':
        if (!isNaN(value) || (!value)) {
          if (isPincode(value)) {
            // setPincodeErr('Please enter proper Pincode!')
          } else {
            // setPincodeErr('')
          }
          setPincode(value && parseInt(value))
        }
        break
      case 'City':
        setCity(event)
        break
      case 'State':
        setState(event)
        setCity('')
        break
      case 'Gender':
        setGender(value)
        break
      case 'UserStatus':
        setUserStatus(value)
        break
      default:
        break
    }
  }

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className={birthDateErr ? 'form-control date-range-notify league-placeholder-error' : 'form-control date-range-notify'} onClick={onClick}>
      <img alt="calender" src={calenderIcon} />
      <Input ref={ref} className='date-input range' placeholder='MM/DD/YYYY' readOnly value={value} />
    </div>
  ))
  ExampleCustomInput.displayName = ExampleCustomInput

  function verifyFunction (verifyType) {
    if (verifyType === 'email') {
      setemailVerified((prev) => !prev)
    } else if (verifyType === 'MobNum') {
      setMobNumVerified((prev) => !prev)
    }
  }

  const buttonText = (verifyType) => {
    return verifyType === 'email' ? (emailVerified ? 'Revoke' : 'Verify Now') : (MobNumVerified ? 'Revoke' : 'Verify Now')
  }

  const buttonColor = (verifyType) => {
    return verifyType === 'email' ? (emailVerified ? 'revoke' : 'verify') : (MobNumVerified ? 'revoke' : 'verify')
  }

  return (
    <>
      {((Auth && Auth === 'SUPER') || (adminPermission?.USERS !== 'N')) &&
        (
          <Fragment>
            <div className='common-box-user p-4'>
              {((Auth && Auth === 'SUPER') || (adminPermission?.USERS !== 'R')) &&
                (
                  <Fragment>
                    <div className='d-flex justify-content-between'>
                      <h2 className='user-heading-name'>User Information</h2>
                      <div className='edit-collapse d-flex align-items-center'>
                        <div className={isEditUserDetails ? 'default-edit' : 'user-edit-button'} hidden={['D'].includes(kycDetails && kycDetails?.eStatus)} onClick={isEditUserDetails ? changeProfileData : onEditUserDetails}>
                          <img alt="" className='mr-2' src={isEditUserDetails ? '' : pencilIcon} />
                          <Button className={isEditUserDetails ? 'user-Edit-button' : 'button'}>{isEditUserDetails ? 'Save' : 'Edit'}</Button>
                        </div>
                        {isEditUserDetails && <Button style={{ textDecoration: 'none' }} className='ml-1 user-cancel-button' color='link' onClick={() => cancelFunc('profile')}>{isEditUserDetails ? 'Cancel' : ''}</Button>}
                      </div>
                    </div>
                  </Fragment>
                )
              }
              <div className='profile-block text-center'>
                <div className='profile-image'>
                  <img alt='userPic' className={propic ? 'system-user-profile-pic' : 'profile-pic'} onError={ev => onImageError(ev, 'propic')} src={(propic && url) ? propic?.imageUrl || url + propic : documentPlaceholder} />
                  {!propic && <div className='file-btn'><Input accept={acceptFormat()} disabled={!isEditUserDetails} onChange={event => handleChange(event, 'ProPic')} type='file'/></div>}
                  {propic && ((Auth && Auth === 'SUPER') || (adminPermission?.USERS === 'W')) && <div className='remove-img-label-user' hidden={!isEditUserDetails} onClick={event => handleChange(event, 'RemoveImage')}><img src={removeImg} alt='removeImg' /></div>}
                </div>
                <p className='error-text mr-4'>{errImage}</p>
                {errImage && <p className='error-text mr-4'>{errImage}</p>}
                <h3 className='mt-2'>{fullname}</h3>
              </div>
              {/* <FormGroup className='userSwitch' switch>
                <Label className='edit-label-setting m-0' for='account'>Internal Account</Label>
                <Input checked={userAccount === 'Y'} disabled={!isEditUserDetails} id='accountRadio1' name='account' onChange={event => handleChange(event, 'UserAccount')} type='switch' value={userAccount === 'N' ? 'Y' : 'N'} />
              </FormGroup> */}
              {/* <FormGroup className='mt-3'>
                <Label className='edit-label-setting' for='fullName'>Full Name</Label>
                <Input className={ErrFullName ? 'league-placeholder-error' : 'league-placeholder'} disabled={!isEditUserDetails} id='fullName' onChange={event => handleChange(event, 'Name')} placeholder='Enter Full Name' type='text' value={fullname} />
                <p className='error-text'>{ErrFullName}</p>
              </FormGroup> */}
              <FormGroup className='mt-3 form-group'>
                <Label className='edit-label-setting' for='userName'>Username</Label>
                <Input className={errUsername ? 'league-placeholder-error' : 'league-placeholder'} disabled={!isEditUserDetails} id='userName' onChange={event => handleChange(event, 'userName')} placeholder='Enter Username' type='text' value={userName} />
                <p className='error-text'>{errUsername}</p>
              </FormGroup>

              {/* {isEditUserDetails
                ? (
                  <>
                    <FormGroup className='custom-form-group-input mt-3'>
                      <Label className='edit-label-setting' for='email'>Email</Label>
                      <div className='email'>
                        <div className='email-inside'>
                          <Input className={errEmail ? 'league-placeholder-error' : 'league-placeholder'} disabled={!isEditUserDetails} id='email' onChange={event => handleChange(event, 'Email')} placeholder='Enter Email' type='text' value={email} />
                          {email && (emailVerified ? <img alt='Approve' className='custom-form-group-input-img' src={verifyIcon} /> : <img alt='Reject' className='custom-form-group-input-img' src={closeIcon} />)}
                        </div>
                        <div className='revoke-btn'>
                          <button className={buttonColor('email')} onClick={() => verifyFunction('email')}>{buttonText('email')}</button>
                        </div>
                      </div>
                      {errEmail && <p className='error-text'>{errEmail}</p>}
                    </FormGroup>
                  </>
                  )
                : (
                  <FormGroup className='custom-form-group-input mt-3'>
                    <Label className='edit-label-setting' for='email'>Email</Label>
                    <Input className={errEmail ? 'league-placeholder-error' : 'league-placeholder'} disabled={!isEditUserDetails} id='email' onChange={event => handleChange(event, 'Email')} placeholder='Enter Email' type='text' value={email} />
                    {email && (emailVerified ? <img alt='Approve' className='custom-form-group-input-img' src={verifyIcon} /> : <img alt='Reject' className='custom-form-group-input-img' src={closeIcon} />)}
                    <p className='error-text'>{errEmail}</p>
                  </FormGroup>
                  )
              } */}
              {isEditUserDetails
                ? (
                  <>
                    <FormGroup className='custom-form-group-input form-group mt-3'>
                      <Label className='edit-label-setting' for='phoneNumber'>Mobile Number</Label>
                      <div className='email'>
                        <div className='email-inside'>
                          <Input className={errMobNum ? 'league-placeholder-error' : 'league-placeholder'} disabled={!isEditUserDetails} id='mobnum' onChange={event => handleChange(event, 'MobNum')} placeholder='Enter Mobile Number' type='text' value={MobNum} />
                          {MobNum && (MobNumVerified ? <img alt='Approve' className='custom-form-group-input-img' src={verifyIcon} /> : <img alt='Reject' className='custom-form-group-input-img' src={closeIcon} />)}
                        </div>
                        <div className='revoke-btn'>
                          <button className={buttonColor('MobNum')} onClick={() => verifyFunction('MobNum')}>{buttonText('MobNum')}</button>
                        </div>
                      </div>
                      {errMobNum && <p className='error-text'>{errMobNum}</p>}
                    </FormGroup>
                  </>
                  )
                : (
                  <FormGroup className='custom-form-group-input mt-3'>
                    <Label className='edit-label-setting' for='phoneNumber'>Mobile Number</Label>
                    <Input className={errMobNum ? 'league-placeholder-error' : 'league-placeholder'} disabled={!isEditUserDetails} id='mobnum' onChange={event => handleChange(event, 'MobNum')} placeholder='Enter Mobile Number' type='text' value={MobNum} />
                    {MobNum && (MobNumVerified ? <img alt='Approve' className='custom-form-group-input-img' src={verifyIcon} /> : <img alt='Reject' className='custom-form-group-input-img' src={closeIcon} />)}
                    <p className='error-text'>{errMobNum}</p>
                  </FormGroup>
                  )
              }
              <FormGroup className='mt-3 form-group'>
                <Label className='edit-label-setting' for='birthdate'>Birthdate</Label>
                <DatePicker
                  customInput={<ExampleCustomInput />}
                  dateFormat="MM/dd/yyyy"
                  dropdownMode="select"
                  disabled={!isEditUserDetails}
                  onChange={e => handleChange(e, 'Birthdate')}
                  peekNextMonth
                  placeholderText='MM/DD/YYYY'
                  showMonthDropdown
                  showYearDropdown
                  value={birthdate}
                />
                <p className='error-text'>{birthDateErr}</p>
              </FormGroup>
              <FormGroup className='mt-3 form-group'>
                <Label className='edit-label-setting' for='platform'>Platform</Label>
                <InputGroupText disabled id='platform' type='text'>{usersDetails?.ePlatform === 'W' ? 'Web' : usersDetails?.ePlatform === 'A' ? 'Android' : usersDetails?.ePlatform === 'I' ? 'iOS' : 'Not Available'}</InputGroupText>
              </FormGroup>
              {/* <FormGroup className='mt-3 user-radio-div form-group'>
                <Label className='edit-label-setting' for='phoneNumber'>Gender</Label>
                <div className='d-flex inline-input mt-2 '>
                  <div className='custom-radio custom-control'>
                  <Input checked={gender === 'M'} disabled={!isEditUserDetails} id='genderRadio1' label='Male' name='genderRadio' onChange={event => handleChange(event, 'Gender')} type='radio' value='M' /><Label for="genderRadio1" className='custom-control-label ms-2'>Male</Label>
                  </div>
                  <div className='custom-radio custom-control'>
                  <Input checked={gender === 'F'} disabled={!isEditUserDetails} id='genderRadio2' label='Female' name='genderRadio' onChange={event => handleChange(event, 'Gender')} type='radio' value='F' /><Label for="genderRadio2" className='custom-control-label ms-2'>Female</Label>
                  </div>
                  <div className='custom-radio custom-control'>
                  <Input checked={gender === 'O'} disabled={!isEditUserDetails} id='genderRadio3' label='Other' name='genderRadio' onChange={event => handleChange(event, 'Gender')} type='radio' value='O' /><Label for="genderRadio3" className='custom-control-label ms-2'>Other</Label>
                  </div>
                </div>
              </FormGroup> */}
              <FormGroup className='mt-3 user-radio-div form-group'>
                <Label className='edit-label-setting' for='status'>User Status</Label>
                <div className='d-flex inline-input mt-2'>
                <div className='custom-radio custom-control'>
                  <Input checked={userStatus === 'Y'} disabled={!isEditUserDetails} id='statusRadio1' label='Active' name='statusRadio' onChange={event => handleChange(event, 'UserStatus')} type='radio' value='Y' /><Label for="genderRadio1" className='custom-control-label ms-2'>Active</Label>
                  </div>
                  <div className='custom-radio custom-control'>
                  <Input checked={userStatus === 'N'} disabled={!isEditUserDetails} id='statusRadio2' label='Block' name='statusRadio' onChange={event => handleChange(event, 'UserStatus')} type='radio' value='N' /><Label for="statusRadio2" className='custom-control-label ms-2'>Block</Label>
                  </div>
                </div>
              </FormGroup>
              {/* <FormGroup className='mt-3'>
                <Label className='edit-label-setting' for='state'>State</Label>
                <Select
                  id='state'
                  isDisabled={!isEditUserDetails}
                  name='state'
                  onChange={selectedOption => handleChange(selectedOption, 'State')}
                  options={stateOptions}
                  placeholder='Select State'
                  value={State}
                />
              </FormGroup> */}
              {/* <FormGroup className='mt-3'>
                <Label className='edit-label-setting' for='city'>City</Label>
                <Select
                  controlShouldRenderValue={cityOptions}
                  id='city'
                  isDisabled={!isEditUserDetails || !State}
                  name='city'
                  onChange={selectedOption => handleChange(selectedOption, 'City')}
                  options={cityOptions}
                  placeholder='Select City'
                  value={city}
                />
              </FormGroup> */}
              {/* <FormGroup className='mt-3'>
                <Label className='edit-label-setting' for='address'>Address</Label>
                <Input disabled={!isEditUserDetails} id='address' onChange={event => handleChange(event, 'Address')} placeholder='Enter Address' type='textarea' value={address} />
              </FormGroup> */}

              {/* <FormGroup className='mt-3'>
                <Label className='edit-label-setting' for='pincode'>Pincode</Label>
                <Input className={pincodeErr ? 'league-placeholder-error' : 'league-placeholder'} disabled={!isEditUserDetails} id='pincode' onChange={event => handleChange(event, 'Pincode')} placeholder='Enter Pincode' type='text' value={pincode} />
                <p className='error-text'>{pincodeErr}</p>
              </FormGroup> */}
            </div>
          </Fragment>
        )
      }

      <div className='common-box-user'>
        <div className='d-flex justify-content-between  user-referral-div user-heading' onClick={toggleReferralModal} >
          <h3 className='cash-bonus-header user-referral'>Referral Information</h3>
          <div className='cash-bonus-header'>
            <img className='toggle-open' alt="" src={referralModal ? caretBottom : caretIcon} />
          </div>
        </div>
        <Collapse isOpen={referralModal}>
          <Row className='d-flex justify-content-center align-items-end p-4'>
            <Col className='pl-0' md={9} xl={9} xs={9}>
              <div className='w-100 d-flex justify-content-between align-items-center'>
                <Label className='edit-label-setting' for='referralCode'>Referral Code</Label>
              </div>
              <FormGroup className='form-group'>
                <InputGroupText>{referralCode || 'NA'}</InputGroupText>
              </FormGroup>
            </Col>
            <Col className='p-0' md={3} xl={3} xs={3}>
              <Button className='user-copy-button w-100' onClick={copyToClipboard}>Copy</Button>
            </Col>
          </Row>

          <Row className='d-flex'  style={{'marginRight':'50px'}}>
            <div className='refer'>
              <div className='refer-total d-flex'>
                Total Refer -
                {' '}
                {referrals}
              </div>
              <div className='refer-list d-flex'>
                {referrals
                  ? (
                    <Button className='refer-code' onClick={() => navigate(`/users/user-referred-list/${id}`)}>
                      <span> See All List </span>
                      <img alt='list' src={list} />
                    </Button>
                    )
                  : ('')
                }
              </div>
            </div>
          </Row>
        </Collapse>
      </div>
    </>
  )
}

UserInformation.propTypes = {
  setLoading: PropTypes.func,
  isEditUserDetails: PropTypes.bool,
  setEditUserDetails: PropTypes.func,
  onClick: PropTypes?.func,
  value: PropTypes?.string
}

export default UserInformation
